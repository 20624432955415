import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addCommune,
  editCommune,
  searchParentOfficeById,
} from "actions/admin/communeAction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export const useFormCommune = (officeId) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues: {
      name: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dataCommune, setDataCommune] = useState(null);

  const { communeData } = useSelector((state) => state.communes);

  const onSubmit = async (data, e) => {
    setIsLoading(true);

    let response;

    const body = {
      name: data?.name?.toUpperCase(),
      office_id: officeId,
    };

    if (dataCommune !== null) {
      response = await dispatch(editCommune(body, dataCommune?.id));
    } else {
      response = await dispatch(addCommune(body));
    }

    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      window.scrollTo(0, 0);
      setIsLoading(false);
      history.push(`/admin/cities/${officeId}/communes`);
    } else {
      setIsLoading(false);
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
  };

  const getCommuneById = async (communeId) => {
    let data = communeData;

    if (communeId !== null) {
      if (communeData === null) {
        const result = await dispatch(searchParentOfficeById(communeId));
        data = result?.data;
      }
    }
    reset({
      name: data?.name ?? "",
    });

    setDataCommune(communeId !== null ? data ?? null : null);
  };

  const formElement = {
    isLoading,
    register,
    handleSubmit,
    errors,
    onSubmit,
  };

  const actions = {
    getCommuneById,
  };

  return {
    formElement,
    actions,
  };
};
