import { useState } from "react";
import {
  searchListInvoices,
  exportFileExcel,
  resetListInvoices,
} from "actions/facturacionAction";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

export const useListInvoicePayment = () => {
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [enabledDate, setEnabledDate] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [loadingPDF, setLoadingPDF] = useState(false);
  const [isViewDetail, setIsViewDetail] = useState(false);
  const [estadoText, setEstadoText] = useState("");
  const [pageOffset, setPageOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const { register, handleSubmit, getValues, setValue } = useForm();
  const { resultListInvoice, isLoadingSearch, dataSearch } = useSelector(
    (state) => state.facturacion
  );

  const onSubmit = async (data) => {
    getListOrdenByFilter(data, 0);
  };

  const getDataInitial = () => {
    /**setIsViewDetail(false)
		dispatch(resetListInvoices(false))
		setPageCount(0)
		setPageOffset(0) */
    const result = dispatch(resetListInvoices(false));

    if (typeof result?.data?.from_date === "string") {
      let fechaFrom = new Date(result?.data?.from_date);
      fechaFrom.setMinutes(
        fechaFrom.getMinutes() + fechaFrom.getTimezoneOffset()
      );
      setStartDate(fechaFrom);

      let fechaTo = new Date(result?.data?.to_date);
      fechaTo.setMinutes(fechaTo.getMinutes() + fechaTo.getTimezoneOffset());
      setEndDate(fechaTo);

      setValue("estatus_id", result?.data?.estatus_id);

      const isVisible =
        parseInt(result?.data?.estatus_id) === parseInt(22) ? false : true;
      setIsViewDetail((isViewDetail) => isVisible);
      const estatus = document.getElementById("estatus_id");
      if (estatus) {
        let text = estatus.options[estatus.selectedIndex].text;
        setEstadoText((estadoText) => text);
      }
    } else {
      setValue("estatus_id", result?.data?.estatus_id);
    }
  };

  const getListOrdenByFilter = async (data, page) => {
    setLoading(true);
    const desde = document
      .querySelector(".fecha_orden_from")
      ?.value?.split("/");
    const hasta = document.querySelector(".fecha_orden_to")?.value?.split("/");
    let body = {
      estatus_id: data?.estatus_id,
      from_date: !enabledDate
        ? dayjs(`${desde[2]}-${desde[1]}-${desde[0]}`).format("YYYY-MM-DD")
        : null,
      to_date: !enabledDate
        ? dayjs(`${hasta[2]}-${hasta[1]}-${hasta[0]}`).format("YYYY-MM-DD")
        : null,
    };
    const response = await dispatch(searchListInvoices(body, page + 1));
    setLoading(false);
    if (response?.status) {
      setPageCount(response?.countPaginate ?? 0);
      setPageOffset(page);
      setIsViewDetail(
        parseInt(data?.estatus_id) === parseInt(22) ? false : true
      );
    }

    const estatus = document.getElementById("estatus_id");
    if (estatus) {
      let text = estatus.options[estatus.selectedIndex].text;
      setEstadoText((estadoText) => text);
    }
  };

  const changeRangeDate = (event) => {
    setEnabledDate(!event.target.checked);
  };

  const downloadFile = async (event, type) => {
    event.preventDefault();
    if (type === "excel") {
      setLoadingExcel(true);
      setLoadingPDF(false);
    }
    if (type === "pdf") {
      setLoadingExcel(false);
      setLoadingPDF(true);
    }
    let estatus_id = document.querySelector("#estatus_id");
    let slug = "";

    if (estatus_id.value === "22") {
      slug = "pendiente";
    } else if (estatus_id.value === "23") {
      slug = "pagada";
    } else if (estatus_id.value === "24") {
      slug = "abonada";
    }

    let nameFile = `listado-de-facturas-${slug}.${
      type === "excel" ? ".xlsx" : "pdf"
    }`;
    await dispatch(
      exportFileExcel(
        nameFile,
        type,
        isViewDetail,
        estatus_id?.options[estatus_id.selectedIndex]?.text
      )
    );

    if (type === "excel") {
      setLoadingExcel(false);
      setLoadingPDF(false);
    }

    if (type === "pdf") {
      setLoadingExcel(false);
      setLoadingPDF(false);
    }
  };

  const onChangeViewColumn = (event) => {
    /**setIsViewDetail(
      parseInt(event.target.value) === parseInt(22) ? false : true
    );
    dispatch(resetListInvoices(false));
    setPageCount(0);
    setPageOffset(0);**/
  };

  const handlePageChange = async (event) => {
    // when its content is loaded in useEffect.
    setPageOffset(event.selected);

    const data = getValues();
    await getListOrdenByFilter(data, event.selected);
  };

  const state = {
    startDate,
    endDate,
    loading,
    enabledDate,
    resultListInvoice,
    isLoadingSearch,
    loadingExcel,
    loadingPDF,
    isViewDetail,
    estadoText,
    pageCount,
    pageOffset,
    dataSearch,
  };

  const actions = {
    setStartDate,
    setEndDate,
    changeRangeDate,
    downloadFile,
    onChangeViewColumn,
    handlePageChange,
    getDataInitial,
  };

  const formElement = {
    register,
    handleSubmit,
    onSubmit,
  };

  return {
    state,
    actions,
    formElement,
  };
};
