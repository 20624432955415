import API from "../config/api";
import {
  LISTAR_DATA_INICIO,
  DATA_SEARCH_ORDEN,
  CLEAN_FORM_ORDER,
  TOTALES_UPDATE,
  SAVE_DETALLE_CARGA,
  FILTERED_ORDERS,
  LOADING_SEARCH_ORDERS_NOM,
  CLEAN_NAME_DESTINO_NOM,
  CANCEL_ORDEN,
  SEARCH_CLIENTE_ORDENES,
  UPDATE_INPUT_DOCUMENTO,
  DATA_ADD_ORDEN,
  PREVIEW_PDF_ORDENES,
  BLOQUEAR_CLIENTE,
  LIST_ORDER_INCOMPLETE,
  SELECTED_ORDEN_INCOMPLETE,
  UPDATE_ORDEN_INCOMPLETE,
  EDIT_CARGA_WITH_PRICE_ORDEN,
} from "../types/ordersType";
import { formatFloat } from "../config/formatInput";
import { validarPermiso } from "../utils/Permission/checkPermission";
import {
  formatCreateDataOrdenFlete,
  //formatEditDataOrdenFlete,
} from "../utils/OrdenFlete/DataCreated";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getHome = () => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await API.get("orders");

      dispatch(requestSuccess(LISTAR_DATA_INICIO, response));

      resolve(response);
    } catch (error) {
      resolve({ status: false, message: error });
    }
  });
};

/*********************Modulo Orden de Flete*********************/
export const createdOrden = (body) => async (dispatch, getState) => {
  try {
    const dataOrden = {
      ...body,
      valor_declarado_carga: formatFloat(body.valor_declarado_carga),
      valor_iva: formatFloat(body.valor_iva),
      valor_neto: formatFloat(body.valor_neto),
      total: formatFloat(body.total),
      subtotal_orden: formatFloat(body.subtotal_orden),
      numero_orden: body.numero_orden.toString().replaceAll(" ", "").trim(),
    };

    let infoOrden = formatCreateDataOrdenFlete(dataOrden);

    let response = await API.post("orders", infoOrden);

    let { status, message } = response;
    if (response.status) {
      const payload = {
        dataOrders: {
          ...body,
          messageSuccess: {
            status: true,
            message,
          },
        },
      };

      dispatch(requestSuccess(DATA_ADD_ORDEN, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const searchOrden = (body) => async (dispatch, getState) => {
  try {
    let dataOrden = {};

    let numero_orden_search = "";

    let response = await API.get(`orders/${body.numero_orden}`);

    if (response.status) {
      const data = response.orden;

      const { configuracion } = getState().config;

      let disabledBtnCrear = !validarPermiso(
        configuracion,
        "editar_orden_flete"
      );

      let disabledBtnAnular = !validarPermiso(
        configuracion,
        "anular_orden_flete"
      );
      let disabledImprimir = false;
      let disabledTracking = false;
      let visibleCarga = true;

      if (data.info_estado?.descripcion === "Nulas") {
        disabledBtnAnular = true;
        disabledBtnCrear = true;
        disabledImprimir = false;
        disabledTracking = false;
        visibleCarga = false;
      } else if (data.info_estado?.descripcion === "Pendiente tarifario") {
        visibleCarga = false;
        disabledBtnCrear = true;
      }

      let isGenerateDocument = false;

      let numero_documento = "";

      if (typeof data.entrega_orden_flete?.nro_documento !== "undefined") {
        if (
          data.entrega_orden_flete?.nro_documento ||
          data.entrega_orden_flete?.nro_documento !== null
        ) {
          numero_documento = data.entrega_orden_flete?.nro_documento;
        }
      }
      //let numero_documento = (typeof data.entrega_orden_flete?.nro_documento!=="undefined" && (data.entrega_orden_flete?.nro_documento || data.entrega_orden_flete?.nro_documento!==null))
      ///? data.entrega_orden_flete?.nro_documento : ''
      let list_nota_credito = data.nota_creditos || [];

      if (list_nota_credito?.length > 0 && numero_documento === "") {
        isGenerateDocument = true;
      }

      let fecha_entrega_orden = "";
      if (
        data?.info_estado?.fecha_hora !== "" &&
        data.info_estado?.descripcion === "Entregada"
      ) {
        fecha_entrega_orden = data?.info_estado?.fecha_hora.substr(0, 10);
      }

      const clientMain = data?.clientes?.find(
        (item) => item?.tipo_cliente === "cliente_principal"
      );

      const someType = data?.clientes?.find(
        (item) =>
          item?.tipo_cliente !== "cliente_principal" &&
          item?.rut === clientMain?.rut
      );

      numero_orden_search = data.numero_orden;

      dataOrden = {
        sucursal_origen_id: data.sucursal_origen_id,
        numero_orden: data.numero_orden,
        oficina_origen_id: data.oficina_origen_id,
        sucursal_destino_id: data.sucursal_destino_id,
        oficina_destino_id: data.oficina_destino_id,
        fecha_orden: data.fecha_orden,
        tipo_envio_id: data.tipo_envio_id,
        cliente_rut: clientMain?.rut,
        razon_social: clientMain?.nombre_completo?.toUpperCase(),
        rut_remitente: data.rut_remitente,
        razon_social_remitente: data.razon_social_remitente?.toUpperCase(),
        rut_destinatario: data.rut_destinatario,
        razon_social_destinatario:
          data.razon_social_destinatario?.toUpperCase(),
        direccion_destinatario: data.direccion_destinatario,
        telefono_avisas: data.telefono_avisas,
        codigo_interno_nro: clientMain?.codigo_interno ?? "",
        codigo_interno:
          clientMain?.codigo_interno !== "" &&
          clientMain?.codigo_interno !== null
            ? "si"
            : "no",
        comentarios: data.comentarios,
        documentacion_id: data.documentacion_id,
        cargas: data.detalle_cargas.map((item) => {
          return { ...item, visible: false };
        }),
        numero_documento_carga: data.numero_documento_carga,
        valor_declarado_carga: data.valor_declarado_carga,
        forma_pago_id: data.forma_pago_id,
        documentacion_id_pago: data.entrega_orden_flete?.documentacion_id || "",
        numero_documento,
        url_cedible: data.entrega_orden_flete?.url_cedible || "",
        rut_factura: data.rut_factura,
        razon_social_factura: data.razon_social_factura?.toUpperCase(),
        total_bultos: data.total_bultos,
        total_kilos: data.total_kilos,
        total_metro_cubico: data.total_metro_cubico,
        subtotal_orden: data.subtotal_orden,
        descuento: data.descuento,
        valor_neto: data.valor_neto,
        valor_iva: data.valor_iva,
        total: data.total,
        user_name: data.info_estado.user_name,
        estado_orden: data.info_estado.descripcion,
        listEstado: data.listEstado,
        nro_nomina: data.nomina,
        rendicion: data.rendicion,
        list_nota_credito,
        fecha_orden_format: data.fecha_orden_format,
        usuario_entrego_app_mobile: data.usuario_entrego_app_mobile,
        camion_app_mobile: data.camion_app_mobile,
        email: data.email,
        isGenerateDocument,
        fecha_entrega_orden,
        ordenFleteId: data.id,
        tipo_cliente: someType?.tipo_cliente ?? "remitente",
      };

      const payload = {
        orden: dataOrden,
        disabledBtnAnular,
        disabledBtnCrear,
        disabledImprimir,
        disabledTracking,
        visibleCarga,
        isBloqueCta: data.forma_pago_id === 3 ? true : false,
      };

      dispatch(requestSuccess(DATA_SEARCH_ORDEN, payload));
    }
    return {
      status: response.status,
      message: response.message,
      numero: numero_orden_search,
      data: dataOrden,
    };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const searchOrdenByNro =
  (numero_orden) => async (dispatch, getState) => {
    try {
      let response = await API.get(`orders/${numero_orden}`);
      return {
        status: response.status,
        message: response.message,
      };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const updateBtnOrdenes = (carga) => (dispatch, getState) => {
  const payload = {
    disabledBtnAnular: false,
    disabledBtnCrear: false,
  };
  dispatch(requestSuccess(CANCEL_ORDEN, payload));
};

export const clienteCtaCorriente = (isBloquear) => (dispatch, getState) => {
  dispatch(requestSuccess(BLOQUEAR_CLIENTE, isBloquear));
};

export const resultTotales = (descuento) => (dispatch, getState) => {
  const data = getState().orders.dataOrders.cargas;

  if (data && data.length > 0) {
    let totalBulto = data.reduce(
      (acumulado, bultos) => acumulado + parseFloat(bultos.bulto),
      0
    );
    let totalKilo = data.reduce(
      (acumulado, kilos) => acumulado + parseFloat(kilos.peso),
      0
    );
    let totalM3 = data.reduce(
      (acumulado, metros) => acumulado + parseFloat(metros.metraje_cubico),
      0
    );
    let subTotal = data.reduce(
      (acumulado, valor) => acumulado + parseFloat(valor.valor_neto),
      0
    );

    let totals = 0;
    let applyDscto = 0;
    let totaliva = 0;

    if (descuento === "Si") {
      applyDscto = Math.round(subTotal * (10 / 100));
      totals = subTotal - applyDscto;
      let montoDiferencia = Math.round(totals / 1.19);
      totaliva = totals - montoDiferencia;
      subTotal = totals - totaliva;
    } else {
      totals = subTotal;
      let montoDiferencia = Math.round(totals / 1.19);
      totaliva = totals - montoDiferencia;
      subTotal = totals - totaliva;
    }

    let result = {
      total_bultos: totalBulto,
      total_kilos: totalKilo,
      total_metro_cubico: totalM3,
      subtotal_orden: subTotal,
      valor_neto: subTotal,
      valor_iva: totaliva,
      total: totals,
    };

    dispatch(requestSuccess(TOTALES_UPDATE, result));
  }
};

export const saveDetalleCarga = (carga) => (dispatch, getState) => {
  const detCarga = getState().orders.dataOrders.cargas;
  let detCargaItem = detCarga;

  const payload = [
    ...detCargaItem,
    { ...carga, visible: false, total_temporal: carga.valor_neto },
  ];

  dispatch(requestSuccess(SAVE_DETALLE_CARGA, payload));
};

export const editDetalleCargaWithPrice =
  (withPrice) => (dispatch, getState) => {
    const detCarga = getState().orders.dataOrders.cargas;
    let detCargaItem = detCarga.map((item) => {
      return {
        ...item,
        valor_neto: !withPrice ? item?.total_temporal : "",
      };
    });
    const payload = {
      cargas: detCargaItem,
    };

    dispatch(requestSuccess(EDIT_CARGA_WITH_PRICE_ORDEN, payload));
  };

export const deleteDetalleCarga = (index) => (dispatch, getState) => {
  const detCarga = getState().orders.dataOrders.cargas;

  const payload = detCarga.filter((carga, i) => i !== index);

  dispatch(requestSuccess(SAVE_DETALLE_CARGA, payload));
};

export const habEditCarga = (index) => (dispatch, getState) => {
  let detCarga = getState().orders.dataOrders.cargas;
  const payload = detCarga.map((carga, i) => {
    carga.visible = i === index ? true : false;
    return carga;
  });
  dispatch(requestSuccess(SAVE_DETALLE_CARGA, payload));
};

export const saveEditDetalleCarga = (carga, index) => (dispatch, getState) => {
  let detCarga = getState().orders.dataOrders.cargas;

  const payload = detCarga.map((item, i) => {
    if (i === index) {
      return { ...carga, visible: false };
    }
    return item;
  });

  dispatch(requestSuccess(SAVE_DETALLE_CARGA, payload));
};

/*********************Modulo Nominas*********************/
export const filteredOrdenes = (busqueda, isModulo) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    dispatch(requestSuccess(LOADING_SEARCH_ORDERS_NOM, []));

    const { name_pendiente_sucursal } = getState().orders.title_destino;
    let estado_id = 0;
    try {
      let response = await API.post(`orders/filter?page=1&limit=500`, busqueda);
      if (response.status) {
        let nameDestino = "";
        if (isModulo === "nomina") {
          nameDestino =
            name_pendiente_sucursal.length === 0
              ? response.destino_nomina
              : name_pendiente_sucursal;
        }
        const payload = {
          orders: response.ordens,
          destino_nomina: nameDestino,
          name_pdf: response.name_pdf,
          dataSearch: busqueda,
          disabledSearch: response.ordens.length === 0 ? true : false,
          name_excel: response.name_excel,
        };
        dispatch(requestSuccess(FILTERED_ORDERS, payload));
        estado_id = response.ordens[0].estado_id;
      } else {
        const title = getState().orders.title_destino;
        const payload = {
          orders: [],
          destino_nomina: title,
          name_pdf: "",
          dataSearch: busqueda,
          disabledSearch: true,
          name_excel: "",
        };
        dispatch(requestSuccess(FILTERED_ORDERS, payload));
      }
      resolve({
        status: response.status,
        message: response.message,
        estado_id,
      });
    } catch (error) {
      resolve({ status: false, message: error });
    }
  });
};

export const clearTitleEstado = (name) => (dispatch, getState) => {
  const destino = getState().orders.title_destino;

  const destino_nomina = {
    ...destino,
    name_pendiente_sucursal: name,
  };

  dispatch(requestSuccess(CLEAN_NAME_DESTINO_NOM, destino_nomina));
};

/*********************Modulo Entregar / Devolver*********************/

export const searchClienteOrdenes = (rut) => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      let response = await API.get(`clientes/${rut}/ordenes`);

      if (response.status) {
        const payload = {
          listOrdenEntregar: response.data.ordenes,
        };

        dispatch(requestSuccess(SEARCH_CLIENTE_ORDENES, payload));
      }
      resolve({
        status: response.status,
        message: response.message,
        nombre_destinatario: response?.data?.nombre_destinatario ?? "",
        id: response?.data?.id ?? null,
        cantidad: response?.data?.ordenes?.length ?? 0,
      });
    } catch (error) {
      resolve({ status: false, message: error });
    }
  });
};

export const repetirSeleccion = () => (dispatch, getState) => {
  const { listOrdenEntregar } = getState().orders;

  const check = listOrdenEntregar.find(
    (orden) => orden.forma_pago === "Por pagar"
  );

  const updateOrden = listOrdenEntregar.map((orden) => {
    if (orden.forma_pago === "Por pagar") {
      return {
        ...orden,
        documentacion_id: check.documentacion_id,
        nro_documento: check.nro_documento,
      };
    }
    return orden;
  });
  dispatch(requestSuccess(UPDATE_INPUT_DOCUMENTO, updateOrden));
};

export const repetirMotivo = () => (dispatch, getState) => {
  const { listOrdenEntregar } = getState().orders;

  const texto = listOrdenEntregar.find(
    (orden) => orden.delivery_failure_detail !== ""
  );

  const updateOrden = listOrdenEntregar.map((orden) => {
    return {
      ...orden,
      delivery_failure_detail: texto.delivery_failure_detail,
    };
  });
  dispatch(requestSuccess(UPDATE_INPUT_DOCUMENTO, updateOrden));
};

export const noAplicaSearchCliente = () => (dispatch, getState) => {
  dispatch(requestSuccess(SEARCH_CLIENTE_ORDENES, []));
};

export const exportPdf = () => async (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    try {
      const { listOrden } = getState().orders;
      if (listOrden.length > 0) {
        let data = listOrden.map((item) => {
          return {
            numero_orden: item.numero_orden,
            estado: item.estado,
            fecha_orden: item.created_at,
            cliente_remitente: item.cliente_remitente,
            sucursal_origen: item.sucursal_origen,
            sucursal_destino: item.sucursal_destino,
            cliente_destinatario: item.cliente_destinatario,
            direccion_destinatario: item.direccion_destinatario,
            total_bultos: item.total_bultos,
            total_kilos: item.total_kilos,
            tipo_envio: item.tipo_envio,
            forma_pago: item.forma_pago,
            total: item.total,
            usuario: item.usuario,
          };
        });

        let response = await API.post(`orders/pdf/export`, {
          list_ordenes: data,
        });

        const payload = { pdf_name: response.archivo };
        dispatch(requestSuccess(PREVIEW_PDF_ORDENES, payload));
        resolve(response);
      }
    } catch (error) {
      resolve({ status: false, message: error });
    }
  });
};

export const cleanOrden = () => (dispatch, getState) => {
  const payload = {
    sucursal_origen_id: "",
    numero_orden: "",
    oficina_origen_id: "",
    sucursal_destino_id: "",
    oficina_destino_id: "",
    fecha_orden: "",
    tipo_envio_id: "",
    cliente_rut: "",
    razon_social: "",
    rut_remitente: "",
    razon_social_remitente: "",
    rut_destinatario: "",
    razon_social_destinatario: "",
    direccion_destinatario: "",
    telefono_avisas: "",
    tipo_cliente: "",
    codigo_interno_nro: "",
    codigo_interno: "",
    comentarios: "",
    documentacion_id: "",
    cargas: "",
    numero_documento_carga: "",
    valor_declarado_carga: "",
    forma_pago_id: "",
    documentacion_id_pago: "",
    numero_documento: "",
    rut_factura: "",
    razon_social_factura: "",
    total_bultos: "",
    total_kilos: "",
    total_metro_cubico: "",
    subtotal_orden: "",
    descuento: "",
    valor_neto: "",
    valor_iva: "",
    total: "",
    user_name: "",
    estado_orden: "",
  };
  dispatch(requestSuccess(CLEAN_FORM_ORDER, payload));
};

//Búsqueda por parámetros
export const getOrdenListIncomplete =
  (body, page) => async (dispatch, getState) => {
    try {
      dispatch(
        requestSuccess(LIST_ORDER_INCOMPLETE, {
          listOrderIncomplete: [],
          isLoadingOrderIncomplete: false,
        })
      );

      let response = await API.post(
        `orders/incomplete?page=${page}&limit=500`,
        body
      );

      let { message, status, data } = response;
      let infoList = [];

      for (let index in data) {
        let { clientes, oficinas, cargas } = data[index];
        infoList.push(data[index]);
        delete infoList[index].clientes;
        delete infoList[index].oficinas;
        infoList[index].remitente = clientes.find(
          (item) => item.tipo_cliente === "remitente"
        );
        infoList[index].destinatario = clientes.find(
          (item) => item.tipo_cliente === "destinatario"
        );
        infoList[index].origen = oficinas.find(
          (item) => item.tipo_oficina === "origen"
        );
        infoList[index].destino = oficinas.find(
          (item) => item.tipo_oficina === "destino"
        );
        infoList[index].cant_bulto = cargas.reduce(
          (acumulado, item) => acumulado + parseFloat(formatFloat(item.bulto)),
          0
        );
      }

      const payload = {
        listOrderIncomplete: infoList,
        isLoadingOrderIncomplete: false,
      };
      dispatch(requestSuccess(LIST_ORDER_INCOMPLETE, payload));

      return { status, message };
    } catch (error) {
      dispatch(
        requestSuccess(LIST_ORDER_INCOMPLETE, {
          listOrderIncomplete: [],
          isLoadingOrderIncomplete: false,
        })
      );
      return { status: false, message: error };
    }
  };

export const getOrdenListIncompleteInitial = () => (dispatch, getState) => {
  dispatch(
    requestSuccess(LIST_ORDER_INCOMPLETE, {
      listOrderIncomplete: [],
      isLoadingOrderIncomplete: false,
    })
  );
};

export const getOrdenSelectedIncomplete =
  (orden) => async (dispatch, getState) => {
    try {
      let payload = {
        orderSelected: orden,
      };

      dispatch(requestSuccess(SELECTED_ORDEN_INCOMPLETE, payload));
    } catch (error) {
      return { status: false, message: error };
    }
  };

//Resultados
export const resultTotalesIncomplete =
  (carga, descuento) => (dispatch, getState) => {
    let result = {
      total_bultos: 0,
      total_kilos: 0,
      total_metro_cubico: 0,
      subtotal_orden: 0,
      valor_neto: 0,
      valor_iva: 0,
      total: 0,
      montoDiferencia: 0,
    };

    try {
      const { orderSelectedIncomplete } = getState().orders;

      for (let key in orderSelectedIncomplete.cargas) {
        let cargaOrden = orderSelectedIncomplete.cargas[key];
        if (parseInt(cargaOrden.id) === parseInt(carga.id)) {
          cargaOrden.valor_neto = carga.valor_neto;
        }
      }
      let dataCargaOrden = orderSelectedIncomplete.cargas;

      if (dataCargaOrden && dataCargaOrden.length > 0) {
        let totalBulto = dataCargaOrden.reduce(
          (acumulado, bultos) => acumulado + parseFloat(bultos.bulto),
          0
        );
        let totalKilo = dataCargaOrden.reduce(
          (acumulado, kilos) => acumulado + parseFloat(kilos.peso),
          0
        );
        let totalM3 = dataCargaOrden.reduce(
          (acumulado, metros) => acumulado + parseFloat(metros.metraje_cubico),
          0
        );
        let subTotal = dataCargaOrden.reduce(
          (acumulado, valor) => acumulado + parseFloat(valor.valor_neto),
          0
        );

        let totals = 0;
        let applyDscto = 0;
        let totaliva = 0;
        let montoDiferencia = 0;
        if (descuento === "Si") {
          applyDscto = Math.round(subTotal * (10 / 100));
          totals = subTotal - applyDscto;
          montoDiferencia = Math.round(totals / 1.19);
          totaliva = totals - montoDiferencia;
          subTotal = totals - totaliva;
        } else {
          totals = subTotal;
          montoDiferencia = Math.round(totals / 1.19);
          totaliva = totals - montoDiferencia;
          subTotal = totals - totaliva;
        }

        result = {
          total_bultos: totalBulto,
          total_kilos: totalKilo,
          total_metro_cubico: totalM3,
          subtotal_orden: subTotal,
          valor_neto: subTotal,
          valor_iva: totaliva,
          total: totals,
          montoDiferencia,
        };
      }
      return result;
    } catch (error) {
      return result;
    }
  };

export const updateOrdenIncomplete =
  (body, numero_orden) => async (dispatch, getState) => {
    try {
      const { listOrderIncomplete } = getState().orders;

      let information = {
        ...body,
        valor_declarado_carga: formatFloat(body.valor_declarado_carga),
        valor_iva: formatFloat(body.valor_iva),
        valor_neto: formatFloat(body.valor_neto),
        total: formatFloat(body.total),
        subtotal_orden: formatFloat(body.subtotal_orden),
      };

      let response = await API.post(
        `orders/updated/${numero_orden}/incomplete`,
        information
      );

      let { message, status } = response;

      if (status) {
        let listOrdens = listOrderIncomplete;

        if (information?.total !== 0) {
          listOrdens = listOrderIncomplete.filter(
            (item) => item.numero_orden !== numero_orden
          );
        }

        const payload = { listOrdens };

        dispatch(requestSuccess(UPDATE_ORDEN_INCOMPLETE, payload));
      }

      return { status, message };
    } catch (error) {
      dispatch(
        requestSuccess(LIST_ORDER_INCOMPLETE, {
          listOrderIncomplete: [],
          isLoadingOrderIncomplete: false,
        })
      );
      return { status: false, message: error };
    }
  };

export const getStatusOrdenReceiveOtherOffice =
  (body) => async (dispatch, getState) => {
    try {
      let information = {
        numero_package: body?.numero_package,
      };

      let response = await API.post(`orders/search/package`, information);

      let { message, status, data } = response.data;

      return { status, message, data };
    } catch (error) {
      dispatch(
        requestSuccess(LIST_ORDER_INCOMPLETE, {
          listOrderIncomplete: [],
          isLoadingOrderIncomplete: false,
        })
      );
      return { status: false, message: error };
    }
  };

export const receiveOrdenOtherOffice = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(`orders/receive/package`, body);

    let { message, status, data } = response.data;

    return { status, message, data };
  } catch (error) {
    dispatch(
      requestSuccess(LIST_ORDER_INCOMPLETE, {
        listOrderIncomplete: [],
        isLoadingOrderIncomplete: false,
      })
    );
    return { status: false, message: error };
  }
};
