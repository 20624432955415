import { useEffect } from "react";
import {
  Row,
  Form,
  Col,
  Button,
  Card,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useAssignVehicle } from "hooks/Admin/Users/useAssignVehicle";
import Eliminar from "assets/images/configurar/delete.png";
import { Link } from "react-router-dom";

const FormAssignVehicle = ({ userId }) => {
  const { state, actions, formElement } = useAssignVehicle();
  const { listTrucks, isLoading, dataListTrucks } = state;

  const { onSubmit, addVehicleInput, removeVehicle, setDataInicial } = actions;

  const { register, handleSubmit, control } = formElement;

  useEffect(() => {
    setDataInicial(userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  return (
    <Form id="frmClave" onSubmit={handleSubmit(onSubmit)}>
      <Card border="Light">
        <Card.Header as="h6" className="bg-warn-blue text-dark">
          ASIGNAR VEHÍCULOS
        </Card.Header>
        <Card.Body className="bg-soft-blue padding-user">
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="fullName">
                <Form.Label>Nombre y Apellido</Form.Label>
                <Form.Control
                  type="text"
                  name="fullName"
                  //defaultValue={datos?.fullName || ""}
                  disabled
                  ref={register}
                />
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="email">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  //defaultValue={datos?.email || ""}
                  disabled
                  ref={register}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="fullName">
                <Form.Label>Asigne el vehículo al usuario:</Form.Label>
                <select
                  className="form-control"
                  id="listPlaca"
                  name="listPlaca"
                  ref={register}
                >
                  <option value="-Seleccione-">-Seleccione-</option>
                  {listTrucks.length > 0 &&
                    listTrucks.map((item, key) => (
                      <option key={key} value={item.plate_number}>
                        {item.plate_number}
                      </option>
                    ))}
                </select>
              </Form.Group>
            </Col>
            <Col lg={6} md={6} sm={12}>
              <Form.Group controlId="email">
                <Button
                  variant="warning"
                  type="button"
                  className="mt-4 btn-sm"
                  onClick={() => addVehicleInput()}
                >
                  Asignar
                </Button>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={8} sm={12}>
              <Form.Group controlId="fullName">
                <Form.Label>
                  Listado de Vehículos asignados al usuario:
                </Form.Label>
                <table className="table table-bordered table-sm bg-white">
                  <thead>
                    <tr>
                      <th scope="col">Placa</th>
                      <th scope="col" style={{ width: "10px" }}></th>
                    </tr>
                  </thead>
                  <tbody>
                    {dataListTrucks.length > 0 ? (
                      dataListTrucks.map((item, index) => {
                        return (
                          <tr key={item.id}>
                            <td>
                              {item.plate_number}
                              <input
                                type="hidden"
                                control={control}
                                name={`trucks[${index}].plate_number`}
                                id={`trucks[${index}].plate_number`}
                                defaultValue={item.plate_number}
                                ref={register}
                              />
                              <input
                                type="hidden"
                                control={control}
                                name={`trucks[${index}].id`}
                                id={`trucks[${index}].id`}
                                defaultValue={item.id}
                                ref={register}
                              />
                            </td>
                            <td>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-eliminar">
                                    Eliminar
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="danger"
                                  type="button"
                                  onClick={() => removeVehicle(index, item)}
                                >
                                  <img
                                    src={Eliminar}
                                    alt="Eliminar"
                                    className="img-conf"
                                  />
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan={2}>No hay vehículo asignado</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <Row>
        <Col xs={12} className="text-right mt-3">
          <Form.Group>
            <Link to="/admin/users">
              <Button
                variant="outline-warning"
                className="btn-orden mr-3"
                disabled={isLoading}
              >
                Regresar
              </Button>
            </Link>
            <Button
              variant="warning"
              type="submit"
              disabled={isLoading}
              className="btn-orden"
            >
              {isLoading ? "Procesando..." : "Actualizar"}
            </Button>
          </Form.Group>
        </Col>
      </Row>
    </Form>
  );
};

export default FormAssignVehicle;
