import React from "react";

const TbodyOrden = ({
  index,
  numero_orden,
  fecha_orden,
  sucursal_origen,
  oficina_origen,
  sucursal_destino,
  oficina_destino,
  register,
  type_office,
  sucursal_id_now,
  oficina_id_now,
  cargarOficinas,
  listOffices,
  oficinaOrden,
}) => {
  return (
    <tr key={index}>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].numero_orden`}
          value={numero_orden}
          ref={register}
        />
        {numero_orden}
      </td>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].fecha_orden`}
          value={fecha_orden}
          ref={register}
        />
        {fecha_orden}
      </td>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].sucursal_origen`}
          value={sucursal_origen}
          ref={register}
        />
        {sucursal_origen}
      </td>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].oficina_origen`}
          value={oficina_origen}
          ref={register}
        />
        {oficina_origen}
      </td>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].sucursal_destino`}
          value={sucursal_destino}
          ref={register}
        />
        {sucursal_destino}
      </td>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].oficina_destino`}
          value={oficina_destino}
          ref={register}
        />
        {oficina_destino}
      </td>
      <td>
        <select
          name={`ordenes[${index}].type_office`}
          id={`ordenes[${index}].type_office`}
          ref={register}
          className="form-control"
        >
          <option value="origen">Origen</option>
          <option value="destino">Destino</option>
        </select>
      </td>
      <td>
        <select
          name={`ordenes[${index}].sucursal_id_now`}
          id={`ordenes[${index}].sucursal_id_now`}
          ref={register}
          className="form-control"
          onChange={(event) => cargarOficinas(event, "")}
        >
          <option value="">-Seleccione-</option>
          {listOffices.length > 0 &&
            listOffices.map((item, key) => (
              <option value={item.id} key={key}>
                {item.name}
              </option>
            ))}
        </select>
      </td>
      <td>
        <select
          name={`ordenes[${index}].oficina_id_now`}
          id={`ordenes[${index}].oficina_id_now`}
          ref={register}
          className="form-control"
        >
          <option value="">-Seleccione-</option>
          {oficinaOrden.length > 0 &&
            oficinaOrden.map((item, key) => (
              <option value={item.id} ky={key}>
                {item.name}
              </option>
            ))}
        </select>
      </td>
    </tr>
  );
};

export default TbodyOrden;
