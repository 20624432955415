import API from "config/config-api";
import {
  LISTADO_DATA_PARENT_OFFICE,
  SELECTED_ROWS_PARENT_OFFICE,
  LOADING_PARENT_OFFICE,
  ACTIVE_PARENT_OFFICE,
} from "types/admin/parentOfficeType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getLoading = (value) => async (dispatch, getState) => {
  const payload = {
    data: null,
    loading: value,
  };
  dispatch(requestSuccess(LOADING_PARENT_OFFICE, payload));
};

export const getListParentOfficeAll =
  (parentCityId) => async (dispatch, getState) => {
    try {
      let response = await API.get(endPoints.parentOffice.list(parentCityId));
      const { status, message, data } = response.data;
      dispatch(requestSuccess(LISTADO_DATA_PARENT_OFFICE, data));
      return { status, message, data };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const getSelectedRows = (rows) => (dispatch, getState) => {
  const payload = {
    data: rows !== null ? rows : null,
  };
  dispatch(requestSuccess(SELECTED_ROWS_PARENT_OFFICE, payload));
};

export const addParentOffice = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.parentOffice.add, body);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const editParentOffice =
  (body, parentOffice) => async (dispatch, getState) => {
    try {
      let response = await API.put(
        endPoints.parentOffice.edit(parentOffice),
        body
      );
      const { status, message } = response.data;

      return { status, message };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const changeStatus = (id) => async (dispatch, getState) => {
  try {
    const { listParentOffice, parentOfficeData } = getState().parentOffices;

    const body = {
      type: "status",
      option: parentOfficeData?.active ? "desactivado" : "activado",
    };

    let response = await API.put(endPoints.parentOffice.edit(id), body);
    const { status, message } = response.data;
    if (status) {
      const listAll = [...listParentOffice].map((parentOffice) => {
        if (parentOffice.id === id) {
          return { ...parentOffice, active: !parentOfficeData?.active };
        }
        return parentOffice;
      });

      const payload = {
        data: {
          ...parentOfficeData,
          active: !parentOfficeData.active,
        },
        listAll,
      };

      dispatch(requestSuccess(ACTIVE_PARENT_OFFICE, payload));
    }

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const deleteParentCity = (id) => async (dispatch, getState) => {
  try {
    const { listParentOffice } = getState().parentOffices;

    let response = await API.delete(endPoints.parentOffice.delete(id));
    const { status, message } = response.data;
    if (status) {
      const payload = {
        listAll: listParentOffice.filter(
          (parentOffice) => parentOffice.id !== id
        ),
        data: null,
      };

      dispatch(requestSuccess(ACTIVE_PARENT_OFFICE, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const searchParentOfficeById =
  (parentCityId) => async (dispatch, getState) => {
    try {
      let response = await API.get(endPoints.parentOffice.getOne(parentCityId));

      const { status, message, data } = response.data;

      return { status, message, data };
    } catch (error) {
      return { status: false, message: error };
    }
  };
