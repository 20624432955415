import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addCustomer,
  editCustomer,
  searchCustomerById,
  searchCustomerByRUT,
  deleteAccountNumberByCustomer,
  serarchAccountNumberByCustomer,
  updateStatusAccountByCustomer,
  sendInvitationByRUT,
} from "actions/admin/customerAction";
import { validarRut } from "utils/rut";
import Swal from "sweetalert2";
import { getPhone } from "utils/globals";
import { useHistory } from "react-router-dom";
import { phone } from "phone";

export const useFormCustomer = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isAccountNumber, setIsAccountNumberIsCodigo] = useState("no");
  const [isReadOnly, setIsReadOnly] = useState("no");
  const [listAccountNumber, setListAccountNumber] = useState([]);
  const [formCustomer, setFormCustomer] = useState(null);
  const [checked, setChecked] = useState(false);
  const {
    register,
    handleSubmit,
    errors,
    reset,
    getValues,
    control,
    setValue,
  } = useForm({
    defaultValues: {
      rut: "",
      phone_number: "",
      address: "",
      email: "",
      full_name: "",
      account_number: [],
      tax_draft: "",
    },
  });

  const { customers, companies } = useSelector((state) => {
    return {
      customers: state.customers,
      companies: state.companies,
    };
  });

  const { customerData } = customers;
  const { companyDataBasic } = companies;

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    let response;

    const isValid = validarRut(data.rut);
    if (!isValid.status) {
      toast.error(isValid.message, { position: toast.POSITION.TOP_RIGHT });
      setIsLoading(false);
      return false;
    }

    if (isAccountNumber === "si") {
      if (listAccountNumber.length === 0) {
        toast.error("Debe ingresar los códigos internos del cliente", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setIsLoading(false);
        return false;
      }
    }

    const body = {
      customer: {
        rut: data?.rut,
        full_name: data?.full_name?.toUpperCase(),
        phone_number: `${companyDataBasic?.country?.code}${data?.phone_number}`,
        email: data?.email?.toLowerCase(),
        address: data?.address,
        tax_draft: data?.tax_draft,
      },
      account_numbers: listAccountNumber
        ?.filter((item) => item?.isNew)
        .map((account) => account.internal_number),
    };
    if (formCustomer !== null) {
      response = await dispatch(editCustomer(body, formCustomer?.id));
      if (
        body?.customer?.email !== formCustomer?.customer?.email &&
        !formCustomer?.verified_web &&
        response?.status
      ) {
        await dispatch(sendInvitationByRUT(body?.customer));
      }
    } else {
      response = await dispatch(addCustomer(body));
      if (body?.customer?.email !== "" && response?.status) {
        await dispatch(sendInvitationByRUT(body?.customer));
      }
    }

    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      window.scrollTo(0, 0);
      setIsLoading(false);
      setTimeout(() => {
        history.push("/admin/customers");
      }, 1000);
    } else {
      setIsLoading(false);
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
  };

  const setDataInicial = async (customerId) => {
    let data = customerData;

    if (customerId !== null) {
      setIsReadOnly(false);
      if (customerData === null) {
        const result = await dispatch(searchCustomerById(customerId));

        data = result?.data;
      }
    }
    setListAccountNumber([]);
    setIsAccountNumberIsCodigo("no");

    if (data !== null) {
      setValue("rut", data?.customer?.rut ?? "");
      setValue("full_name", data?.customer?.full_name ?? "");
      setValue(
        "phone_number",
        getPhone(data?.customer?.phone_number, companyDataBasic) ?? ""
      );
      setValue("email", data?.customer?.email ?? "");
      setValue("tax_draft", data?.customer?.tax_draft ?? "");
      setValue("address", data?.customer?.address ?? "");
      setListAccountNumber(
        data?.accountNumbers?.map((account) => {
          return {
            internal_number: account?.internal_number,
            id: account?.id,
            isNew: false,
            active: account?.active,
            tag_status: account?.tag_status,
          };
        })
      );
      setIsAccountNumberIsCodigo(
        data?.accountNumbers?.length > 0 ? "si" : "no"
      );
    }
    setFormCustomer(customerId !== null ? data ?? null : null);
  };

  const handleBlurSearchRut = async (value) => {
    await searchRut(value);
  };

  const handleDownSearchRut = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();

      await searchRut(event.target.value);
    } else {
      setIsReadOnly(true);
    }
  };

  const searchRut = async (rut) => {
    if (rut !== "") {
      const { message, data } = await dispatch(searchCustomerByRUT(rut));

      if (
        message === "El cliente ya se encuentra registrado" &&
        formCustomer === null
      ) {
        toast.error(message, { position: toast.POSITION.TOP_RIGHT });
        setIsReadOnly(true);
        return false;
      }
      setValue("full_name", data?.full_name ?? "");
      if (data !== null && data?.phone_number !== null) {
        setValue(
          "phone_number",
          getPhone(data?.phone_number, companyDataBasic) ?? ""
        );
      } else {
        setValue("phone_number", "");
      }

      setValue("email", data?.email ?? "");
      setValue("tax_draft", data?.tax_draft ?? "");
      setValue("address", data?.address ?? "");
      setIsReadOnly(false);
    }
  };

  const addAccountNumber = async () => {
    const data = getValues();
    if (data?.account_number_customer === "") {
      toast.error("Debe ingresar el código interno", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    const verifiedAccountNumber = listAccountNumber?.filter(
      (account) =>
        Number(account?.internal_number) ===
        Number(data?.account_number_customer)
    );
    if (verifiedAccountNumber?.length > 0) {
      toast.error("El código interno ingresado ya existe en la lista", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    const result = await dispatch(
      serarchAccountNumberByCustomer(data?.account_number_customer)
    );
    if (result?.status) {
      toast.error("El código interno esta asignado a otro cliente", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    setListAccountNumber([
      ...listAccountNumber,
      {
        internal_number: data?.account_number_customer,
        id: 0,
        isNew: true,
      },
    ]);
    setValue("account_number_customer", "");
  };

  const deleteAccountNumber = async (account) => {
    Swal.fire({
      title: "¿Seguro desea eliminar el código interno del cliente?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let listAccountUpdate = listAccountNumber;
        if (account.isNew) {
          listAccountUpdate = listAccountNumber?.filter(
            (item) => item.internal_number !== account.internal_number
          );
          setListAccountNumber([...listAccountUpdate]);
          toast.success(result?.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          const result = await dispatch(
            deleteAccountNumberByCustomer(account?.id)
          );
          if (result?.status) {
            listAccountUpdate = listAccountNumber?.filter(
              (item) => item.internal_number !== account.internal_number
            );
            setListAccountNumber([...listAccountUpdate]);

            toast.success(result?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(result?.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      }
    });
  };

  const handleActiveAccount = (account) => {
    //const nombre = account.nameBtn.toLowerCase();
    //const status = account.active ? 0 : 1;
    const tag = account.active ? "desactivar" : "activar";
    Swal.fire({
      title: `¿Desea ${tag} el código interno ${account?.internal_number}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const body = {
          type: "status",
          option: account.active ? "desactivado" : "activado",
        };
        const response = await dispatch(
          updateStatusAccountByCustomer(account?.id, body)
        );
        if (response.status) {
          const listAccountUpdate = listAccountNumber?.map((item) => {
            if (item.internal_number === account.internal_number) {
              item.active = !item?.active;
            }

            return {
              ...item,
            };
          });
          setListAccountNumber([...listAccountUpdate]);
          toast.success(response.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error(response.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    });
  };

  const setResetPhone = (data, companyBasic) => {
    setValue(
      "phone_number",
      getPhone(data?.customer?.phone_number, companyBasic) ?? ""
    );
  };

  const validatePhone = (value) => {
    if (
      !phone(`${companyDataBasic?.country?.code}${value}`, {
        country: companyDataBasic?.country?.iso,
        validateMobilePrefix: false,
      }).isValid
    ) {
      return "El número ingresado no es válido";
    }
  };

  const formElement = {
    register,
    handleSubmit,
    errors,
    onSubmit,
    control,
  };

  const state = {
    isLoading,
    isAccountNumber,
    listAccountNumber,
    companyDataBasic,
    isReadOnly,
    checked,
    formCustomer,
  };

  const actions = {
    validatePhone,
    setValue,
    reset,
    getValues,
    setIsAccountNumberIsCodigo,
    setDataInicial,
    handleBlurSearchRut,
    handleDownSearchRut,
    addAccountNumber,
    deleteAccountNumber,
    handleActiveAccount,
    setChecked,
    setResetPhone,
  };

  return {
    formElement,
    actions,
    state,
  };
};
