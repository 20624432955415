import React, { Fragment } from "react";
import { Form, Col, Table } from "react-bootstrap";
import BodyEntrega from "./BodyEntrega";
import { useSelector } from "react-redux";

const TableEntrega = ({ register, errors, toast, setValue }) => {
  const { documentacionsPago, listOrdenEntregar } = useSelector(
    (state) => state.transportOrders
  );

  return (
    <Fragment>
      <Form.Row>
        <Col xs={12}>
          <Form.Group as={Col}>
            <Table bordered size="sm" responsive className="table-global mb-0">
              <thead className="bg-warn-blue">
                <tr>
                  <th>N° de Orden</th>
                  <th>Forma de Pago</th>
                  <th>Documento</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {listOrdenEntregar?.length > 0 ? (
                  listOrdenEntregar?.map((orden, index) => {
                    return (
                      <BodyEntrega
                        key={index}
                        index={index}
                        {...orden}
                        register={register}
                        documentacions={documentacionsPago?.filter(
                          (item) => item?.description !== "Boleta-1"
                        )}
                        toast={toast}
                        setValue={setValue}
                      />
                    );
                  })
                ) : (
                  <tr className="tr-vacia">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Form.Group>
        </Col>
      </Form.Row>
    </Fragment>
  );
};

export default TableEntrega;
