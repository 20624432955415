import React, { useEffect, useState } from "react";
import { Col, Row, Button, Form } from "react-bootstrap";
import Layout from "components/Layouts/Layout";
import { useDispatch, useSelector } from "react-redux";
import { getListOfficeAll } from "actions/admin/officeAction";
import {
  getStatusOrdenReceiveOtherOffice,
  receiveOrdenOtherOffice,
} from "actions/transportOrdersAction";

import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import TitlePrincipal from "components/Layouts/TitlePrincipal";
import { usePermission } from "hooks/Permission/usePermission";
import { onPaste, validateOnlyOrdenPackage } from "utils/validationInput";

const ReceiveTicket = () => {
  const dispatch = useDispatch();
  const { validarPermiso } = usePermission();
  const { register, handleSubmit, errors, reset, setValue } = useForm();

  const { city_id } = useSelector((state) => state.users);
  const { listOffices } = useSelector((state) => state.offices);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getListOfficeAll("active"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data, e) => {
    setLoading(true);

    if (!data?.numero_package.includes("-")) {
      toast.error("Debe ingresar el correlativo de la orden", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return false;
    }

    const response = await dispatch(getStatusOrdenReceiveOtherOffice(data));

    if (!response?.status) {
      toast.error(response?.message, { position: toast.POSITION.TOP_RIGHT });
      setLoading(false);
      return false;
    } else {
      let information = {
        sucursal_id: data?.sucursal_receive_id,
        ordenes: [
          {
            id_bulto: parseInt(response?.data?.id_paquete),
            codigo_bulto: data?.numero_package,
          },
        ],
      };

      const result = await dispatch(receiveOrdenOtherOffice(information));

      setLoading(false);
      if (result?.status) {
        toast.success(result.message, { position: toast.POSITION.TOP_RIGHT });
        reset({
          numero_package: "",
          sucursal_receive_id: city_id,
        });
      } else {
        toast.error(result?.message, { position: toast.POSITION.TOP_RIGHT });
      }
    }
    setLoading(false);
  };

  const validateBulto = (event) => {
    if (
      event.target.value.toString() === "0" ||
      event.target.value.toString() === "00"
    ) {
      toast.error("Debe ingresar el correlativo de la orden", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValue("numero_package", "");
      return false;
    }
  };

  return (
    <Layout titlePanel="Recibir bultos" modulo="receive_ticket">
      <TitlePrincipal title="RECIBIR CORRELATIVO DE LA ORDEN" />
      <Row className="bg-gray panel-contenedor--info">
        <Col xs={12} className="mt-1">
          <Form
            onSubmit={handleSubmit(onSubmit)}
            id="frmBusquedaGlobal"
            role="form"
          >
            <Form.Row>
              <Col lg={2} md={4} sm={6} xs={4}>
                <Form.Group as={Col} controlId="fecha_desde" className="pr-0">
                  <Form.Label>Correlativo de la orden</Form.Label>
                  <Form.Control
                    type="text"
                    name="numero_package"
                    placeholder="00000-000"
                    ref={register({
                      required: { value: true, message: "Requerido" },
                    })}
                    maxLength={15}
                    onKeyPress={(event) => validateOnlyOrdenPackage(event)}
                    onPaste={(event) => onPaste(event)}
                    onBlur={(event) => validateBulto(event)}
                  />
                  {errors.numero_package && (
                    <Form.Text className="text-danger">
                      {errors?.numero_package?.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col lg={2} md={4} sm={6} xs={4}>
                <Form.Group
                  as={Col}
                  controlId="sucursal_receive_id"
                  className="pr-1 pl-0"
                >
                  <Form.Label>Sucursal</Form.Label>
                  <Form.Control
                    as="select"
                    name="sucursal_receive_id"
                    ref={register({
                      required: {
                        value: true,
                        message: "Requerido",
                      },
                    })}
                    disabled={!validarPermiso("cambiar_sucursal")}
                  >
                    <option value="">Seleccione</option>
                    {listOffices.length > 0 &&
                      listOffices.map((sucursal) => (
                        <option key={sucursal.id} value={sucursal.id}>
                          {sucursal.name}
                        </option>
                      ))}
                  </Form.Control>
                  {errors.sucursal_receive_id && (
                    <Form.Text className="text-danger">
                      {errors?.sucursal_receive_id?.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col lg={2}>
                <Form.Group as={Col} className="mt-3 pl-0">
                  <Button
                    variant="warning"
                    type="submit"
                    block
                    disabled={loading}
                  >
                    {loading ? "Procesando..." : "Recibir"}
                  </Button>
                </Form.Group>
              </Col>
            </Form.Row>
          </Form>
        </Col>
      </Row>
      <ToastContainer />
    </Layout>
  );
};

export default ReceiveTicket;
