import { useEffect, forwardRef } from "react";
import {
  Row,
  Form,
  Col,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import { Controller } from "react-hook-form";
import Dropzone from "components/Admin/Dropzone";
import { toast } from "react-toastify";
import { getDateYMD } from "utils/formatFecha";
import { BiCalendar } from "react-icons/bi";
import { validateOnlyNumber, onPaste } from "utils/validationInput";
import MaskedInput from "react-text-mask";
import RutTextMask from "rut-text-mask";

registerLocale("es", es);

const FormAddConductor = ({
  register,
  datos,
  errors,
  control,
  setValue,
  setFile,
  file,
  coIsEdit,
  isVisiblePanel,
  isChangePhoto,
  handleFile,
  validPicture,
}) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
    onFileAdded,
    onDeleteFile,
    isValidLogo,
    dateStart,
    setDateStart,
  } = validPicture;

  useEffect(() => {
    if (datos !== null) {
      setValue("rut", datos?.rut);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datos]);

  const validateStartDateBlur = () => {
    const dateStart = getDateYMD(
      document.querySelector("#license_expiration_date").value
    );

    if (dateStart.error) {
      toast.error(dateStart.message, { position: toast.POSITION.TOP_RIGHT });
      setDateStart(new Date());
      return false;
    }
  };

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <InputGroup className="mb-3">
      <FormControl
        name="fecha_orden_c"
        className="fecha_venc"
        value={value}
        readOnly
      />
      <InputGroup.Append>
        <Button variant="warning" size="sm" onClick={() => onClick()} ref={ref}>
          <BiCalendar />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  ));

  return (
    <>
      <Row>
        <Col sm={12} className="mt-4">
          <h5>Datos del conductor</h5>
          <hr />
        </Col>
        <Col lg={6} md={6} sm={12}>
          <Form.Group controlId="rut">
            <Form.Label>RUT</Form.Label>
            <Controller
              autoComplete="off"
              render={(props) => (
                <MaskedInput
                  mask={RutTextMask}
                  className="form-control text-uppercase"
                  id="rut"
                  name="rut"
                  value={props.value}
                  maxLength={12}
                  defaultValue={datos?.rut || ""}
                  onChange={(value) => {
                    props.onChange(value);
                  }}
                  //onBlur={(event) => handleBlurRut(event, setError)}
                />
              )}
              control={control}
              name="rut"
              rules={{ required: { value: true, message: "Requerido" } }}
            />
            {errors.rut && (
              <Form.Text className="text-danger">
                {errors?.rut?.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
        <Col lg={6} md={6} sm={12}>
          <Form.Group controlId="driver_license">
            <Form.Label>Licencia conducir</Form.Label>
            <Form.Control
              type="text"
              name="driver_license"
              maxLength="12"
              defaultValue={datos?.driver_license || ""}
              ref={register({
                required: { value: true, message: "Requerido" },
              })}
              onKeyPress={(event) => validateOnlyNumber(event)}
              onPaste={(event) => onPaste(event)}
            />
            {errors.driver_license && (
              <Form.Text className="text-danger">
                {errors?.driver_license?.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col lg={6} md={6} sm={12}>
          <Form.Group controlId="license_expiration_date">
            <Form.Label>Fecha vencimiento</Form.Label>
            <Controller
              control={control}
              name="license_expiration_date"
              render={(props) => (
                <DatePicker
                  dateFormat="dd/MM/yyyy"
                  placeholderText="dd/MM/yyyy"
                  onChange={(e) => {
                    props.onChange(e);
                    setDateStart(e);
                  }}
                  selected={dateStart}
                  className="form-control"
                  id={props.name}
                  name={props.name}
                  autoComplete="off"
                  locale="es"
                  onCalendarClose={() => validateStartDateBlur()}
                  customInput={<ExampleCustomInput />}
                />
              )}
              //rules={{required: {value: true, message: 'Requerido'}}}
            />
            {errors.license_expiration_date && (
              <Form.Text className="text-danger">
                {errors?.license_expiration_date?.message}
              </Form.Text>
            )}
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col lg={6} md={6} sm={12}>
          <Form.Group controlId="documento">
            <Form.Label>Foto del documento</Form.Label>
            {isValidLogo && (
              <>
                <Dropzone
                  getRootProps={getRootProps}
                  getInputProps={getInputProps}
                  isDragActive={isDragActive}
                  isFocused={isFocused}
                  isDragAccept={isDragAccept}
                  isDragReject={isDragReject}
                  acceptedFiles={file}
                  onFileAdded={onFileAdded}
                  onDeleteFile={onDeleteFile}
                />
                {errors.documento && (
                  <Form.Text className="text-danger">
                    {errors?.documento?.message}
                  </Form.Text>
                )}
              </>
            )}
            {!isValidLogo && (
              <div className="mt-2 alert alert-secondary p-2 d-flex bg-photo-success">
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "scale-down",
                    cursor: "pointer",
                  }}
                  src={file[0]?.path}
                  alt="Logo"
                />
                <p className="bg-pointer" onClick={() => onDeleteFile(null)}>
                  Haga click <br />
                  para cambiar el logo
                </p>
              </div>
            )}
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};

export default FormAddConductor;
