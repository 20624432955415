import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { resetLitRetiro } from "actions/retiroAction";
import { usePermission } from "hooks/Permission/usePermission";
import { useForm } from "react-hook-form";
import { getOrdenSelectedIncomplete } from "actions/orderAction";
import {
  getDataInitialOrder,
  getOrdenListIncomplete,
  getOrdenListIncompleteInitial,
} from "actions/transportOrdersAction";
import { getListOfficeAll } from "actions/admin/officeAction";

export const useOrderIncomplete = () => {
  const dispatch = useDispatch();
  const {
    listOrderIncomplete,
    isLoadingOrderIncomplete,
    orderSelectedIncomplete,
  } = useSelector((state) => state.orders);

  const {
    documentacionsCarga,
    documentacionsPago,
    documentacionsPagoSinB1,
    tipoEnvios,
    formaPagos,
  } = useSelector((state) => state.transportOrders);

  const { listOffices } = useSelector((state) => state.offices);
  const { city_id, office_id } = useSelector((state) => state.users);

  const [completedModal, setCompletedModal] = useState(false);
  const { register, handleSubmit, setValue, getValues, control } = useForm();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [loading, setLoading] = useState(false);
  const [enabledDate, setEnabledDate] = useState(false);
  const { validarPermiso } = usePermission();
  const [pageOffset, setPageOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  const changeRangeDate = (event) => {
    setEnabledDate(!event.target.checked);
  };

  const onSubmit = async (data, e) => {
    await getListOrdenByFilter(data, 0);
  };

  const getListOrdenByFilter = async (data, page) => {
    setLoading(true);

    dispatch(resetLitRetiro());
    let sucursal_origen = document.getElementById("sucursal_origen_id").value;
    let sucursal_destino = document.getElementById("sucursal_destino_id").value;

    const information = {
      type_assign: "all",
      from_date: !enabledDate
        ? document.getElementById("from_date").value
        : null,
      to_date: !enabledDate ? document.getElementById("to_date").value : null,
      sucursal_origen_id: sucursal_origen === "" ? null : sucursal_origen,
      sucursal_destino_id: sucursal_destino === "" ? null : sucursal_destino,
      numero_orden: data.numero_orden === "" ? null : data.numero_orden,
    };

    const response = await dispatch(
      getOrdenListIncomplete(information, page + 1)
    );

    setLoading(false);

    if (response?.status) {
      setPageCount(response?.countPaginate ?? 0);
      setPageOffset(page);
    } else {
      toast.error("No hay registros disponibles", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const openCloseModalCompleted = () => {
    setCompletedModal(!completedModal);
  };

  const handleSelectedOrden = (orden) => {
    dispatch(getOrdenSelectedIncomplete(orden));
    setCompletedModal(!completedModal);
  };

  const getDataInitial = async () => {
    dispatch(getOrdenListIncompleteInitial(false));
    if (
      documentacionsCarga.length === 0 ||
      documentacionsPago.length === 0 ||
      documentacionsPagoSinB1.length === 0 ||
      tipoEnvios.length === 0 ||
      formaPagos.length === 0
    ) {
      await dispatch(getDataInitialOrder());
    }

    if (listOffices.length === 0) {
      await dispatch(getListOfficeAll("active"));
    }
  };

  const handlePageChange = async (event) => {
    // when its content is loaded in useEffect.
    setPageOffset(event.selected);

    const data = getValues();
    await getListOrdenByFilter(data, event.selected);
  };

  const state = {
    listOffices,
    documentacionsCarga,
    documentacionsPago,
    documentacionsPagoSinB1,
    tipoEnvios,
    formaPagos,
    listOrderIncomplete,
    isLoadingOrderIncomplete,
    orderSelectedIncomplete,
    city_id,
    office_id,
    startDate,
    endDate,
    loading,
    pageOffset,
    pageCount,
    enabledDate,
    completedModal,
  };

  const formElement = {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    onSubmit,
  };

  const actions = {
    getDataInitial,
    handleSelectedOrden,
    openCloseModalCompleted,
    changeRangeDate,
    setStartDate,
    setEndDate,
    validarPermiso,
    setPageOffset,
    handlePageChange,
    setCompletedModal,
  };

  return {
    state,
    formElement,
    actions,
  };
};
