import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addVehicleUser,
  searchUserById,
  removeVehicleUser,
} from "actions/admin/userAction";
import { getListTruck } from "actions/admin/truckAction";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

export const useAssignVehicle = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, getValues, control, reset, setValue } =
    useForm();
  const [dataListTrucks, setDataListTrucks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { listTrucks } = useSelector((state) => state.trucks);
  const { userDatos } = useSelector((state) => state.users);
  const [dataUser, setDataUser] = useState(null);

  const addVehicleInput = () => {
    if (getValues("listPlaca") !== "-Seleccione-") {
      const vehicleId = getValues("listPlaca");

      const isExist = dataListTrucks.find(
        (item) => item.plate_number === vehicleId
      );
      if (!isExist) {
        let truck = listTrucks?.find(
          (item) => item?.plate_number === vehicleId
        );

        setDataListTrucks([
          ...dataListTrucks,
          {
            plate_number: truck?.plate_number,
            internal_number: truck?.internal_number,
            truck_id: truck?.id,
            isNew: true,
          },
        ]);
        setValue("listPlaca", "-Seleccione-");
      } else {
        toast.error("Ya existe en la lista", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      toast.error("Debe seleccionar un vehículo de la lista", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    if (dataListTrucks.length > 0) {
      const body = {
        trucks: dataListTrucks
          ?.filter((item) => item.isNew)
          .map((item) => item.truck_id),
      };

      if (body?.trucks?.length === 0) {
        setIsLoading(false);
        toast.error("No hubos cambios", { position: toast.POSITION.TOP_RIGHT });
        return false;
      }
      const response = await dispatch(addVehicleUser(body, dataUser?.id));

      if (response.status) {
        toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        setIsLoading(false);
      } else {
        setIsLoading(false);
        toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } else {
      toast.error("Debe asignar vehículo al usuario", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const setDataInicial = async (userId) => {
    let data = userDatos;

    if (userId !== null) {
      if (userDatos === null) {
        const result = await dispatch(searchUserById(userId));
        data = result?.data;
      }
    }

    if (data !== null) {
      reset({
        fullName: data.full_name,
        email: data.email,
      });
      setDataListTrucks(
        data.trucks?.map((item) => {
          return {
            plate_number: item?.plate_number,
            internal_number: item?.internal_number,
            truck_id: item?.pivot.truck_id,
            user_id: item?.pivot.user_id,
            isNew: false,
          };
        })
      );
    }
    setDataUser(userId !== null ? data ?? null : null);
    dispatch(getListTruck("active"));
  };

  const removeVehicle = async (index, item) => {
    Swal.fire({
      title: "¿Seguro desea eliminar el vehículo del usuario?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        if (item?.isNew) {
          setDataListTrucks([
            ...dataListTrucks.filter(
              (truck) => truck.plate_number !== item?.plate_number
            ),
          ]);
          toast.success("Se ha eliminado con éxito", {
            position: toast.POSITION.TOP_RIGHT,
          });
          return false;
        } else {
          const body = {
            trucks: [item.truck_id],
          };
          const result = await dispatch(removeVehicleUser(body, dataUser?.id));

          if (result?.status) {
            setDataListTrucks([
              ...dataListTrucks.filter(
                (truck) => truck.plate_number !== item?.plate_number
              ),
            ]);
            toast.success("Se ha eliminado con éxito", {
              position: toast.POSITION.TOP_RIGHT,
            });
            return false;
          } else {
            toast.error(result.message, { position: toast.POSITION.TOP_RIGHT });
            return false;
          }
        }
      }
    });
  };

  const state = {
    listTrucks,
    isLoading,
    dataUser,
    dataListTrucks,
  };
  const actions = {
    onSubmit,
    addVehicleInput,
    removeVehicle,
    setDataInicial,
  };

  const formElement = {
    register,
    handleSubmit,
    getValues,
    control,
  };

  return {
    state,
    actions,
    formElement,
  };
};
