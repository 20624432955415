import { useEffect } from "react";
import { Card, Form, Row, Col } from "react-bootstrap";
import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import ModalDestino from "components/Retiros/Nuevo/Destino/ModalDestino";
import ClienteRemitente from "components/Retiros/Nuevo/ClienteRemitente";
import TablaDestion from "components/Retiros/Nuevo/Destino/TablaDestion";
import { ToastContainer, toast } from "react-toastify";
import { useDestino } from "hooks/Retiro/useDestino";
import { useCreated } from "hooks/Retiro/useCreated";

const Created = () => {
  const {
    state: stateDestino,
    actions: actionsDestino,
    formElement: formElementDestino,
  } = useDestino(toast);

  const { modalShow } = stateDestino;

  const { handleModal, handleOpenModalDestino, editDestino, deleteDestino } =
    actionsDestino;

  const { state, actions, formElement } = useCreated();

  const {
    listDestino,
    listOffices,
    isLoading,
    city_id,
    office_id,
    companyDataBasic,
  } = state;

  const { getDataInitial } = actions;

  const { onSubmit, handleSubmit, setValue, getValues } = formElement;

  useEffect(() => {
    getDataInitial();
    if (document.getElementById("frmRetiro")) {
      document.getElementById("frmRetiro").reset();
      document.getElementById("razon_social_remitente").readOnly = true;
      //document.getElementById("razon_social_destinatario").readOnly=true
    }
    setValue("sucursal_origen_id", city_id);
    setValue("comuna_origen_id", office_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOffices?.length]);

  return (
    <Layout titlePanel="Retiro" modulo="create-retiro">
      <Row>
        <Col xs={12}>
          <CardRetiro title="CREAR UN NUEVO RETIRO">
            <Form id="frmRetiro" onSubmit={handleSubmit(onSubmit)}>
              <Card border="Light">
                <Card.Header as="h6" className="bg-warn-blue text-dark">
                  RETIRO
                </Card.Header>
                <Card.Body className="bg-soft-blue">
                  <Row>
                    <Col xs={12}>
                      <ClienteRemitente
                        formElement={formElement}
                        actions={actions}
                        state={state}
                        companyDataBasic={companyDataBasic}
                      />
                      <hr />
                    </Col>
                    <Col xs={12} className="mt-4">
                      <div className="panel-destino">
                        <h6 className="mb-0">Detalle destinatario(s)</h6>
                        <button
                          type="button"
                          className="btn btn-warning btn-sm"
                          onClick={() => handleOpenModalDestino(getValues())}
                        >
                          Agregar destinatario
                        </button>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <TablaDestion
                        listDestino={listDestino}
                        editDestino={editDestino}
                        deleteDestino={deleteDestino}
                      />
                    </Col>
                    <Col xs={12} className="text-right">
                      <Form.Group as={Col} className="mt-3 pr-0">
                        <button
                          type="submit"
                          className="btn btn-warning pl-5 pr-5"
                          disabled={isLoading}
                        >
                          {isLoading ? "Procesando..." : "Guardar"}
                        </button>
                      </Form.Group>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Form>
          </CardRetiro>
        </Col>
      </Row>
      {modalShow && (
        <ModalDestino
          show={modalShow}
          onHide={() => handleModal(false)}
          state={stateDestino}
          actions={actionsDestino}
          formElement={formElementDestino}
        />
      )}
      <ToastContainer />
    </Layout>
  );
};

export default Created;
