import { useEffect } from "react";
import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import DataTableCustomer from "components/Admin/Columnas/DataTableCustomer";
import Filter from "components/Admin/Communes/Filter";
import commune from "components/Admin/Columnas/Commune";
import { useParams } from "react-router-dom";
import { useCommune } from "hooks/Admin/Communes/useCommune";
import { useFormOffice } from "hooks/Admin/Offices/useFormOffice";

const ListParentOffice = () => {
  const { officeId } = useParams();

  const { state, actions } = useCommune();
  const { communeIsLoading } = state;
  const { getAll, filteredItems, handleRowClicked } = actions;

  const { actions: actionsOffice, state: stateOffice } = useFormOffice();
  const { dataOffice } = stateOffice;
  const { getOfficeOnly } = actionsOffice;

  useEffect(() => {
    getAll(officeId);
    getOfficeOnly(officeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [officeId]);

  return (
    <Layout titlePanel="Administrar" modulo="administrar_countries">
      <div className="pl-3 pr-3">
        <CardRetiro
          title={`Sucursal ${
            dataOffice !== null ? dataOffice?.name : ""
          } - Listado de comunas`}
        >
          <DataTableCustomer
            columns={commune.columnas}
            filteredItems={filteredItems}
            handleRowClicked={handleRowClicked}
            isCargando={communeIsLoading}
            FilterComponent={Filter}
          />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default ListParentOffice;
