import { useEffect } from "react";
import { Col, Form, Button } from "react-bootstrap";
import ModalFactura from "components/Factura/ModalFactura";
import FormOrden from "components/Encomienda/Credito/Formulario";
import TablaOrden from "components/Encomienda/Credito/TableOrden";
import PanelDetalle from "components/Layouts/PanelDetalle";
import TableSuccess from "components/Encomienda/TableSuccess";
import { useOrderInvoiceCredit } from "hooks/Orders/useOrderInvoiceCredit";

const FacturaCredito = ({ toast }) => {
  const { state, actions, formElement } = useOrderInvoiceCredit();

  const { hidden, listEntregada } = state;

  const { onSubmit, register, handleSubmit, errors } = formElement;
  const { getDatInitial } = actions;

  useEffect(() => {
    getDatInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <PanelDetalle
        title="DETALLE DE LA ORDENES"
        subtitle="INGRESO DE DATOS"
        check={true}
      >
        <FormOrden toast={toast} />
        <Form id="frmFacturaCredito" onSubmit={handleSubmit(onSubmit)}>
          <TablaOrden register={register} errors={errors} />

          <Form.Row className="m-3">
            <Col className="text-right">
              <Button variant="warning" type="submit">
                FACTURAR
              </Button>
            </Col>
          </Form.Row>
        </Form>
        {listEntregada.length > 0 && <TableSuccess />}
      </PanelDetalle>
      {hidden && (
        <ModalFactura
          title={"FACTURA A NOMBRE DE:"}
          nameModulo="entrega"
          stateOrder={state}
          actionsOrder={actions}
          /**setHidden={setHidden}
          hidden={hidden}
          data={infoData}
          toast={toast}
          funcFactura={funcFacturar}
          
          isVisibleFactura={true}**/
        />
      )}
    </>
  );
};

export default FacturaCredito;
