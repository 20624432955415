import API from "config/config-api";
import {
  LISTAR_DATA_GUIA,
  ADD_ORDENES_GUIA,
  ADD_DESCUENTO_RECARGA,
  LISTADO_GUIA_DESPACHO,
  CARGANDO_DATA,
  UPDATE_ORDENES_GUIA,
} from "types/guiaDespachoType";
import { formatFloat } from "config/formatInput";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getListado = (body) => async (dispatch, getState) => {
  try {
    let response = await API.get(endPoints.guide.initial);
    const { status, message, data } = response.data;
    dispatch(requestSuccess(LISTAR_DATA_GUIA, data));
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const addOrdenes = (data) => (dispatch, getState) => {
  const { listado_totales, descuento_global, recargo_global } =
    getState().guiaDespacho;

  const isExiste = listado_totales.ordenes.find(
    (orden) => orden.numero_orden === data.numero_orden
  );

  if (isExiste === undefined) {
    let listado = [...listado_totales.ordenes, data];
    let subtotal = listado.reduce(
      (acumulado, orden) => acumulado + parseFloat(orden.total),
      0
    );

    let total_neto = subtotal - descuento_global + recargo_global;
    //let total_iva = total - total_neto
    let iva = Math.round(total_neto * 1.19);
    let total_iva = iva - total_neto;

    let totals = total_neto + total_iva;

    const payload = {
      ordenes: listado,
      subtotal,
      total_neto,
      total_iva,
      totals,
    };

    dispatch(requestSuccess(ADD_ORDENES_GUIA, payload));
    return true;
  } else {
    return false;
  }
};

export const updateOrdenes = () => (dispatch, getState) => {
  const { listado_totales } = getState().guiaDespacho;

  const listado = listado_totales.ordenes;

  let subtotal = listado.reduce(
    (acumulado, orden) => acumulado + parseFloat(orden.total),
    0
  );
  let total_neto = subtotal;
  let iva = Math.round(total_neto * 1.19);
  let total_iva = iva - total_neto;
  //let total_iva = total - total_neto

  let totals = total_neto + total_iva;

  const payload = {
    ordenes: listado,
    subtotal,
    total_neto,
    total_iva,
    totals,
    descuento_global: 0,
    recargo_global: 0,
    data_recargo: {},
    data_descuento: {},
  };

  dispatch(requestSuccess(UPDATE_ORDENES_GUIA, payload));
};

export const resetDataOrdenes = () => (dispatch, getState) => {
  const payload = {
    ordenes: [],
    subtotal: 0,
    total_neto: 0,
    total_iva: 0,
    totals: 0,
    descuento_global: 0,
    recargo_global: 0,
    data_recargo: {},
    data_descuento: {},
  };

  dispatch(requestSuccess(UPDATE_ORDENES_GUIA, payload));
};

export const deleteOrdenes = (numero_orden) => (dispatch, getState) => {
  const { listado_totales, descuento_global, recargo_global } =
    getState().guiaDespacho;

  const listado = listado_totales.ordenes.filter(
    (orden) => orden.numero_orden !== numero_orden
  );

  let subtotal = listado.reduce(
    (acumulado, orden) => acumulado + parseFloat(orden.total),
    0
  );

  let recargoInitial = listado.length === 0 ? 0 : recargo_global;
  let dsctoInitial = listado.length === 0 ? 0 : descuento_global;

  let total_neto = subtotal - dsctoInitial + recargoInitial;
  let iva = Math.round(total_neto * 1.19);
  let total_iva = iva - total_neto;
  //let total_iva = total - total_neto

  let totals = total_neto + total_iva;

  const payload = {
    ordenes: listado,
    subtotal,
    total_neto,
    total_iva,
    totals,
    descuento_global: 0,
    recargo_global: 0,
    data_recargo: {},
    data_descuento: {},
  };

  dispatch(requestSuccess(UPDATE_ORDENES_GUIA, payload));

  return subtotal;
};

export const aplicarDescuentoRecargo =
  (monto, tipo_valor, seleccion) => (dispatch, getState) => {
    let {
      listado_totales,
      descuento_global,
      recargo_global,
      data_recargo,
      data_descuento,
    } = getState().guiaDespacho;

    let subtotal = listado_totales.ordenes.reduce(
      (acumulado, orden) => acumulado + parseFloat(orden.total),
      0
    );

    let monto_total =
      tipo_valor === "$"
        ? formatFloat(monto)
        : Math.round((subtotal * formatFloat(monto)) / 100);

    let descuento = seleccion === "descuento" ? monto_total : descuento_global;

    let recargo = seleccion === "recarga" ? monto_total : recargo_global;

    let total_neto =
      parseFloat(subtotal) - parseFloat(descuento) + parseFloat(recargo);
    let iva = Math.round(total_neto * 1.19);
    let total_iva = iva - total_neto;

    let totals = total_neto + total_iva;

    let info_recargo = data_recargo;
    if (seleccion === "recarga") {
      info_recargo = {
        tipo_movimiento: "R",
        tipo_valor,
        monto: monto,
        monto_total: recargo,
      };
    }

    let info_descuento = data_descuento;
    if (seleccion === "descuento") {
      info_descuento = {
        tipo_movimiento: "D",
        tipo_valor,
        monto: monto,
        monto_total: descuento,
      };
    }

    const payload = {
      listado_totales: {
        ordenes: listado_totales.ordenes,
        subtotal,
        total_neto,
        total_iva,
        totals,
      },
      descuento_global: descuento,
      recargo_global: recargo,
      data_recargo: info_recargo,
      data_descuento: info_descuento,
    };

    dispatch(requestSuccess(ADD_DESCUENTO_RECARGA, payload));

    return { payload };
  };

export const createdGuia = (body) => async (dispatch, getState) => {
  try {
    const { listado_totales, data_recargo, data_descuento } =
      getState().guiaDespacho;

    let info_global = [];
    if (Object.keys(data_recargo).length > 0) {
      info_global.push(data_recargo);
    }
    if (Object.keys(data_descuento).length > 0) {
      info_global.push(data_descuento);
    }

    const { listUserDrives } = getState().drivesUser;

    const drives = listUserDrives?.find(
      (item) => Number(item.id) === Number(body.rut_conductor)
    );

    const information = {
      rut: body.rut,
      razon_social: body.razon_social?.toUpperCase(),
      direccion_cliente: body.direccion_cliente,
      fecha_documento: body.fecha_documento,
      fecha_vencimiento: body.fecha_vencimiento,
      camion_id: body.camion_id,
      traslado_id: body.traslado_id,
      despacho_id: body.despacho_id,
      direccion: body.direccion_destino,
      sucursal_id: body.sucursal_id,
      comuna_id: body.comuna_id,
      tipo_operacion_descuento:
        Object.keys(data_descuento).length > 0 ? "descuento" : "",
      tipo_valor_descuento:
        Object.keys(data_descuento).length > 0 ? data_descuento.tipo_valor : "",
      valor_descuento:
        Object.keys(data_descuento).length > 0 ? data_descuento.monto : 0,
      monto_descuento_global:
        Object.keys(data_descuento).length > 0 ? data_descuento.monto_total : 0,
      tipo_operacion_recargo:
        Object.keys(data_recargo).length > 0 ? "recargo" : "",
      tipo_valor_recargo:
        Object.keys(data_recargo).length > 0 ? data_recargo.tipo_valor : "",
      valor_recargo:
        Object.keys(data_recargo).length > 0 ? data_recargo.monto : 0,
      monto_recargo_global:
        Object.keys(data_recargo).length > 0 ? data_recargo.monto_total : 0,
      subtotal: formatFloat(body.subtotal),
      total_neto: formatFloat(body.total_neto),
      total_iva: formatFloat(body.total_iva),
      totals: formatFloat(body.totals),
      ordenes: listado_totales.ordenes,
      conductor_id: body.rut_conductor,
      metodo_pago_id: body.forma_pago_id,
      rut_transportista: body.rut_transportista,
      info_global,
      rut_driver: drives?.rut ?? "",
      full_name_driver: drives?.full_name ?? "",
    };

    let response = await API.post(endPoints.guide.add, information);
    const { status, message, data } = response.data;

    return {
      status,
      message,
      data,
    };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const listadoGuia = () => async (dispatch, getState) => {
  try {
    dispatch(requestSuccess(CARGANDO_DATA, true));
    let response = await API.get(endPoints.guide.list);
    const { status, message, data } = response.data;
    dispatch(requestSuccess(LISTADO_GUIA_DESPACHO, data));
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};
