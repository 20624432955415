import { useEffect } from "react";
import { Button, Modal, Row, Col, Form, InputGroup } from "react-bootstrap";
import Progress from "components/Layouts/Progress";

import Swal from "sweetalert2";
import {
  onPaste,
  validateOnlyLetter,
  validateOnlyNumber,
} from "utils/validationInput";
import { useInvoice } from "hooks/Orders/useInvoice";
import { useTransportOrderOffice } from "hooks/Orders/useTransportOrderOffice";
import { Controller } from "react-hook-form";
import MaskedInput from "react-text-mask";
import RutTextMask from "rut-text-mask";

const ModalFactura = ({ title, nameModulo, actionsOrder, stateOrder }) => {
  const {
    companyDataBasic,
    hidden,
    infoData,
    isVisible: isVisibleFactura,
    isLoading,
    listParentCities,
    updateData,
  } = stateOrder;
  const { addOrdenAction, setLoading, setHidden } = actionsOrder;

  const { formElement, state, actions } = useInvoice();

  const { register, handleSubmit, errors, control, setValue } = formElement;

  const { bloquearInput, isActivoRut, isProcesando, isSpinner } = state;

  const {
    validatePhone,
    setIsProcesando,
    setIsSpinner,
    searchClienteFactura,
    onKeyDownFactura,
    selectCliente,
    dataCliente,
  } = actions;

  const { actions: actionsOffice, state: stateOffice } =
    useTransportOrderOffice();

  const { officeOrigin } = stateOffice;

  const { loadOfficesGlobal, setOfficeOrigin } = actionsOffice;

  const handleClose = () => {
    setHidden(false);

    if (isLoading !== null && typeof isLoading !== "undefined") {
      setLoading(false);
    }
  };

  const onSubmit = async (data, e) => {
    let mensaje = "";
    if (nameModulo === "nota") {
      mensaje =
        "La nota de crédito será procesada y generada con estos datos ¿está seguro que desea continuar?.";
    } else if (nameModulo === "anular") {
      if (infoData?.cliente_rut && infoData.numero_documento.length > 0) {
        mensaje =
          "Se va generar una nota de crédito, ¿Seguro desea anular la orden?";
      } else {
        mensaje = "¿Seguro desea anular la orden?";
      }
    } else {
      mensaje =
        "La factura será procesada y generada con estos datos ¿está seguro que desea continuar?.";
    }

    Swal.fire({
      title: mensaje,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setIsSpinner(true);
        setIsProcesando(true);

        const cityOrigin = listParentCities?.find(
          (item) => item.id === Number(data?.ciudad_factura)
        );

        const officeOrigin = cityOrigin?.offices?.find(
          (item) => item.id === Number(data?.comuna_factura)
        );

        let dataSend = {
          ...infoData,
          factura: {
            ...data,
            razon_social_factura: data?.razon_social_factura?.toUpperCase(),
            email_factura: data?.email_factura?.toLowerCase(),
            direccion_factura: data?.direccion_factura?.toUpperCase(),
            city_origin_name: cityOrigin?.name,
            office_origin_name: officeOrigin?.name,
            contacto_factura: `${companyDataBasic?.country?.code}${data?.contacto_factura}`,
          },
        };

        const response = await addOrdenAction(dataSend, e);
        setIsProcesando(false);
        setIsSpinner(false);
        if (response) {
          setHidden(false);
        }
      }
    });
  };

  useEffect(() => {
    if (
      infoData?.rut_factura &&
      (nameModulo === "anular" || nameModulo === "nota")
    ) {
      setIsSpinner(true);
      const getData = async () => {
        await dataCliente(infoData?.rut_factura);
        setValue("rut_factura", infoData?.rut_factura);
        setIsSpinner(false);
      };
      getData();
    } else {
      setIsSpinner(false);
    }

    return () => {
      setIsSpinner(false);
      //setHidden(false)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateData]);

  return (
    <Modal show={hidden} onHide={handleClose} centered size="lg">
      <Modal.Header closeButton>
        <h6 className="mb-0">{title}</h6>
      </Modal.Header>
      <Modal.Body>
        <Form id="frmFactura" onSubmit={handleSubmit(onSubmit)}>
          <div className="pb-4">
            {isVisibleFactura && (
              <>
                {nameModulo !== "anular" && nameModulo !== "nota" && (
                  <Row>
                    <Col md={6} sm={12}>
                      <Row>
                        <Col xs={12} className="mb-1">
                          <Form.Group
                            as={Col}
                            controlId="type_cliente_factura"
                            className="mb-0 pl-0 pr-0"
                          >
                            <Form.Label>Cliente</Form.Label>
                            <Form.Control
                              as="select"
                              onChange={(event) =>
                                selectCliente(event, infoData)
                              }
                              name="type_cliente_factura"
                              ref={register({
                                required: { value: true, message: "Requerido" },
                              })}
                            >
                              <option value="">Seleccione</option>
                              <option value="remitente">Remitente</option>
                              <option value="destinatario">Destinatario</option>
                              <option value="otros">Otros</option>
                            </Form.Control>
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                <Row className="mt-3 pb-3">
                  <Col sm={12}>
                    <h6>Datos básicos</h6>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <Row>
                      <Col xs={12} className="mb-1">
                        <Form.Group
                          as={Col}
                          controlId="rut_factura"
                          className="mb-0 pl-0 pr-0"
                        >
                          <Form.Label>RUT</Form.Label>
                          <Controller
                            autoComplete="off"
                            render={(props) => (
                              <MaskedInput
                                mask={RutTextMask}
                                className="form-control text-uppercase"
                                id="rut_factura"
                                name="rut_factura"
                                value={props.value}
                                maxLength={12}
                                onChange={(value) => {
                                  props.onChange(value);
                                }}
                                //onBlur={(event) => handleBlurRut(event, setError)}
                                readOnly={isActivoRut}
                                onBlur={(event) =>
                                  searchClienteFactura(event, infoData)
                                }
                                onKeyDown={(event) =>
                                  onKeyDownFactura(event, infoData)
                                }
                                // onPaste={(event) => onPaste(event)}
                              />
                            )}
                            control={control}
                            name="rut_factura"
                            rules={{
                              required: { value: true, message: "Requerido" },
                            }}
                          />
                          {errors.rut_factura && (
                            <Form.Text className="text-danger">
                              {errors?.rut_factura?.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} sm={12}>
                    <Row>
                      <Col xs={12}>
                        <Form.Group
                          as={Col}
                          controlId="razon_social_factura"
                          className="mb-0 pl-0 pr-0"
                        >
                          <Form.Label>Razón Social</Form.Label>
                          <Form.Control
                            type="text"
                            name="razon_social_factura"
                            readOnly={bloquearInput}
                            ref={register({
                              required: { value: true, message: "Requerido" },
                            })}
                            className="text-uppercase"
                            onKeyPress={(event) => validateOnlyLetter(event)}
                            maxLength={150}
                            onPaste={(event) => onPaste(event)}
                          />
                          {errors.razon_social_factura && (
                            <Form.Text className="text-danger">
                              {errors?.razon_social_factura?.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <Row>
                      <Col xs={12} className="mb-1">
                        <Form.Group
                          as={Col}
                          controlId="giro_factura"
                          className="mb-0 pl-0 pr-0"
                        >
                          <Form.Label>Giro</Form.Label>
                          <Form.Control
                            type="text"
                            name="giro_factura"
                            readOnly={bloquearInput}
                            maxLength="70"
                            className="text-uppercase"
                            ref={register({
                              required: { value: true, message: "Requerido" },
                            })}
                          />
                          {/**<Form.Control
                            as="select"
                            name="giro_factura"
                            ref={register({
                              required: { value: true, message: "Requerido" },
                            })}
                            disabled={bloquearInput}
                          >
                            <option value="">Seleccione</option>
                            {cgiListado.length > 0 &&
                              cgiListado
                                ?.filter((item) => item.is_activo)
                                ?.map((giro) => (
                                  <option key={giro.id} value={giro.id}>
                                    {giro.nombre}
                                  </option>
                                ))}
                          </Form.Control> */}
                          {errors.giro_factura && (
                            <Form.Text className="text-danger">
                              {errors?.giro_factura?.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} sm={12}>
                    <Row>
                      <Col xs={12}>
                        <Form.Group
                          as={Col}
                          controlId="contacto_factura"
                          className="mb-0 pl-0 pr-0"
                        >
                          <Form.Label>Contacto</Form.Label>
                          <InputGroup className="mb-3">
                            <InputGroup.Prepend>
                              <InputGroup.Text id="basic-addon1">
                                {companyDataBasic?.country?.code}
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              type="text"
                              name="contacto_factura"
                              readOnly={bloquearInput}
                              maxLength={15}
                              disabled={
                                companyDataBasic?.country?.code === ""
                                  ? true
                                  : false
                              }
                              ref={register({
                                minLength: {
                                  value: 9,
                                  message: "Mínimo 9 dígitos",
                                },
                                required: { value: true, message: "Requerido" },
                                //eslint-disable-next-line
                                pattern: {
                                  value: /^([0-9]+)$/,
                                  message: "Teléfono inválido",
                                },
                                validate: validatePhone,
                              })}
                              autoComplete="off"
                              onKeyPress={(event) => validateOnlyNumber(event)}
                              onPaste={(event) => onPaste(event)}
                            />
                          </InputGroup>
                          {errors.contacto_factura && (
                            <Form.Text className="text-danger">
                              {errors?.contacto_factura?.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <Row>
                      <Col xs={12} className="mb-1">
                        <Form.Group
                          as={Col}
                          controlId="email_factura"
                          className="mb-0 pl-0 pr-0"
                        >
                          <Form.Label>E-mail</Form.Label>
                          <Form.Control
                            type="text"
                            name="email_factura"
                            readOnly={bloquearInput}
                            className="text-lowercase"
                            ref={register({
                              //eslint-disable-next-line
                              pattern: {
                                value:
                                  /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, //eslint-disable-line
                                message: "Email inválido",
                              },
                              required: { value: true, message: "Requerido" },
                            })}
                            maxLength={70}
                          />
                          {errors.email_factura && (
                            <Form.Text className="text-danger">
                              {errors?.email_factura?.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} sm={12}>
                    <Row>
                      <Col xs={12}>
                        <Form.Group
                          as={Col}
                          controlId="direccion_factura"
                          className="mb-0 pl-0 pr-0"
                        >
                          <Form.Label>Dirección</Form.Label>
                          <Form.Control
                            type="text"
                            name="direccion_factura"
                            readOnly={bloquearInput}
                            className="text-uppercase"
                            ref={register({
                              required: { value: true, message: "Requerido" },
                            })}
                          />
                          {errors.direccion_factura && (
                            <Form.Text className="text-danger">
                              {errors?.direccion_factura?.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={6} sm={12}>
                    <Row>
                      <Col xs={12} className="mb-1">
                        <Form.Group
                          as={Col}
                          controlId="ciudad_factura"
                          className="mb-0 pl-0 pr-0"
                        >
                          <Form.Label>Ciudad</Form.Label>
                          <Form.Control
                            as="select"
                            disabled={bloquearInput}
                            onChange={(event) => {
                              const result = loadOfficesGlobal(
                                event,
                                "origin",
                                "",
                                listParentCities
                              );
                              setOfficeOrigin(result);
                            }}
                            name="ciudad_factura"
                            ref={register({
                              required: { value: true, message: "Requerido" },
                            })}
                          >
                            <option value="">Seleccione</option>
                            {listParentCities?.length > 0 &&
                              listParentCities?.map((sucursal) => (
                                <option key={sucursal.id} value={sucursal.id}>
                                  {sucursal.name}
                                </option>
                              ))}
                          </Form.Control>
                          {errors.ciudad_factura && (
                            <Form.Text className="text-danger">
                              {errors?.ciudad_factura?.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={6} sm={12}>
                    <Row>
                      <Col xs={12}>
                        <Form.Group
                          as={Col}
                          controlId="comuna_factura"
                          className="mb-0 pl-0 pr-0"
                        >
                          <Form.Label>Comuna</Form.Label>
                          <Form.Control
                            as="select"
                            disabled={bloquearInput}
                            name="comuna_factura"
                            ref={register({
                              required: { value: true, message: "Requerido" },
                            })}
                          >
                            <option value="">Seleccione</option>
                            {officeOrigin?.length > 0 &&
                              officeOrigin?.map((ciudad) => (
                                <option key={ciudad.id} value={ciudad.id}>
                                  {ciudad.name}
                                </option>
                              ))}
                          </Form.Control>
                          {errors.comuna_factura && (
                            <Form.Text className="text-danger">
                              {errors?.comuna_factura?.message}
                            </Form.Text>
                          )}
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {!companyDataBasic?.invoice && nameModulo !== "orden" && (
                  <Row>
                    <Col md={6} sm={12} className="mt-2">
                      <Row>
                        <Col xs={12}>
                          <Form.Group
                            as={Col}
                            controlId="numero_documento"
                            className="mb-0 pl-0 pr-0"
                          >
                            <Form.Label>Número de documento</Form.Label>
                            <Form.Control
                              type="text"
                              name="numero_documento"
                              readOnly={bloquearInput}
                              ref={register({
                                required: {
                                  value: true,
                                  message: "Requerido",
                                },
                              })}
                            />
                            {errors.numero_documento && (
                              <Form.Text className="text-danger">
                                {errors?.numero_documento?.message}
                              </Form.Text>
                            )}
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
              </>
            )}
            {nameModulo === "anular" && (
              <Row className="pt-4">
                <Col sm={12}>
                  <Form.Group controlId="motivo_anular">
                    <Form.Label>Motivo</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="motivo_anular"
                      rows={4}
                      ref={register({
                        required: { value: true, message: "Requerido" },
                      })}
                    />
                    {errors.motivo_anular && (
                      <Form.Text className="text-danger">
                        {errors?.motivo_anular?.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            )}
          </div>
          {isSpinner && (
            <div className="pb-4 text-center">
              <Progress />
              <p className="font-italic">
                Procesando la información, espere unos minutos
              </p>
            </div>
          )}
          <Modal.Footer>
            <Button
              className="pl-5 pr-5"
              variant="warning"
              onClick={() => handleClose()}
              disabled={isProcesando}
            >
              Cerrar
            </Button>
            <Button
              type="submit"
              className="pl-5 pr-5"
              variant="warning"
              disabled={isProcesando}
            >
              {isProcesando ? "Procesando..." : "Procesar"}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalFactura;
