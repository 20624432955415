import { useState } from "react";
import { useForm } from "react-hook-form";

import Swal from "sweetalert2";
import {
  saveCargas,
  saveMultipleDestination,
  deleteDestination,
  selectedDestination,
  resetDestination,
} from "actions/retiroAction";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getPhone } from "utils/globals";
import { validarRut } from "utils/rut";
import { searchCustomer } from "actions/admin/customerAction";
import { phone } from "phone";

const RUT_MESON = process.env.REACT_APP_CLIENTE_MESON;

export const useDestino = () => {
  const dispatch = useDispatch();
  const { infoDestino } = useSelector((state) => state.retiros);
  const { companyDataBasic } = useSelector((state) => state.companies);
  const { listOffices } = useSelector((state) => state.offices);
  const { tipoEnvios } = useSelector((state) => state.transportOrders);
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
    reset,
  } = useForm({ defaultValues: infoDestino });
  const [modalShow, setModalShow] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(true);
  const [isNewDestino, setIsNewDestino] = useState(false);

  const handleModal = (value) => {
    reset({
      comuna_id: "",
      telefono: "",
      rut: "",
      sucursal_id: "",
      tipo_envio_id: 1,
    });
    setModalShow(value);
  };

  const handleOpenModalDestino = (data) => {
    if (
      data?.rut_remitente === "" ||
      data?.razon_social_remitente === "" ||
      data?.telefono_remitente === ""
    ) {
      toast.error("Debe completar los datos del remitente", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    if (
      data?.sucursal_origen_id === "" ||
      data?.comuna_origen_id === "" ||
      data?.direccion_retiro === ""
    ) {
      toast.error("Debe completar los datos de la dirección del remitente", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    if (data?.forma_pago_id === "") {
      toast.error("Debe seleccionar la forma de pago", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    resetFormDestino(true);
    dispatch(resetDestination("edit"));
    setModalShow(true);
  };

  const onSubmit = (data, event) => {
    event.preventDefault();

    if (typeof data?.cargas === "undefined") {
      toast.error("Debe anexar el detalle de la carga", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    let comuna_selected = document.querySelector("#comuna_id");

    const body = {
      rut: data?.rut,
      razon_social: data?.razon_social,
      telefono: `${companyDataBasic?.country?.code}${data?.telefono}`,
      email: data?.email,
      comuna: data?.comuna_id,
      direccion: data?.direccion,
      cargas: infoDestino?.cargas,
      observacion: data?.observacion,
      visible: false,
      sucursal_id: data?.sucursal_id,
      comuna_name: comuna_selected.options[comuna_selected.selectedIndex].text,
      tipo_envio_id: data?.tipo_envio_id,
      is_new: isNewDestino,
    };

    let typeAction = infoDestino?.visible === true ? "edit" : "add";
    const response = dispatch(saveMultipleDestination(body, typeAction));
    if (response.status) {
      toast.warn("Se guardará cuando se agregue el retiro", {
        position: toast.POSITION.TOP_RIGHT,
      });
      event.target.reset();
      let modalCl = infoDestino?.visible === true ? false : true;
      setModalShow((modalShow) => modalCl);
      setValue("rut", "");
      setValue("sucursal_id", "");
      setValue("comuna_id", "");
    } else {
      toast.error("Hubo un error al guardar el destino", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const saveCarga = (event) => {
    event.preventDefault();
    const data = getValues();

    if (
      data.alto !== "" &&
      data.ancho !== "" &&
      data.largo !== "" &&
      data.cantidad !== "" &&
      data.peso !== ""
    ) {
      const infoCarga = {
        alto: data.alto,
        ancho: data.ancho,
        largo: data.largo,
        cantidad: data.cantidad,
        peso: data.peso,
        visible: false,
        metraje_cubico: data?.metraje_cubico,
      };

      dispatch(saveCargas(infoCarga, "add"));
      setValue("alto", "");
      setValue("ancho", "");
      setValue("largo", "");
      setValue("cantidad", "");
      setValue("peso", "");
      setValue("metraje_cubico", "");
    } else {
      toast.error("Debe de ingresar el detalle de la carga", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const deleteRow = (event, index) => {
    Swal.fire({
      title: `¿Seguro desea eliminar el detalle de la carga?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(saveCargas({ index }, "delete"));
      }
    });
  };

  const editDestino = (event, item, index) => {
    dispatch(selectedDestination(item, index));
    handleModal(true);
  };

  const deleteDestino = (event, index) => {
    event.preventDefault();

    Swal.fire({
      title: `¿Seguro desea eliminar ese destinatario?`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(deleteDestination({ index }, "delete"));
      }
    });
  };

  /***Cliente */
  const onKeyDownDestinatario = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      await searchClienteDestinatario();
    }
  };

  const resetFormDestino = (isReadOnly) => {
    setValue("razon_social", "");
    setValue("telefono", "");
    setValue("email", "");
    setValue("direccion", "");
    setIsReadOnly(isReadOnly);
    if (document.getElementById("razon_social")) {
      document.getElementById("razon_social").readOnly = isReadOnly;
    }
  };

  const onBlurHandleDes = async (event) => {
    if (event.target.value.length !== 0) {
      await searchClienteDestinatario();
    }
  };

  const searchClienteDestinatario = async () => {
    let search;
    const rut = document.getElementById("rut").value;
    const isValid = validarRut(rut);
    if (isValid.status) {
      search = { search_type: "rut_cliente", value: rut };
    } else {
      toast.error(isValid.message, { position: toast.POSITION.TOP_RIGHT });
      resetFormDestino(true);
      return false;
    }

    if (rut === RUT_MESON) {
      toast.error("Ese RUT es inválido", {
        position: toast.POSITION.TOP_RIGHT,
      });
      resetFormDestino(true);
      return false;
    }

    const result = await dispatch(searchCustomer(search));

    if (result.status) {
      setValue("razon_social", result.data.full_name);
      document.getElementById("razon_social").readOnly = false;
      setValue("direccion", result.data?.address);
      setValue(
        "telefono",
        getPhone(result.data?.phone_number, companyDataBasic)
      );
      setValue("email", result.data?.email);
      setValue("sucursal_id", result.data?.sucursal_id);
      setIsReadOnly(false);
      setIsNewDestino(false);
    } else {
      if (result?.message === "El cliente está desactivado") {
        document.getElementById("razon_social").readOnly = true;
        toast.error(result.message, { position: toast.POSITION.TOP_RIGHT });
        setValue("rut", "");
      } else {
        document.getElementById("razon_social").readOnly = false;
      }
      setValue("razon_social", "");
      setValue("telefono", "");
      document.getElementById("razon_social").readOnly = false;
      setValue("direccion", "");
      setValue("email", "");
      setIsReadOnly(false);
      setIsNewDestino(true);
    }
  };

  const validatePhone = (value) => {
    if (
      !phone(`${companyDataBasic?.country?.code}${value}`, {
        country: companyDataBasic?.country?.iso,
        validateMobilePrefix: false,
      }).isValid
    ) {
      return "El número ingresado no es válido";
    }
  };

  const state = {
    infoDestino,
    modalShow,
    tipoEnvios,
    listOffices,
    companyDataBasic,
    isReadOnly,
  };

  const actions = {
    saveCarga,
    deleteRow,
    handleModal,
    handleOpenModalDestino,
    onKeyDownDestinatario,
    onBlurHandleDes,
    validatePhone,
    getPhone,
    editDestino,
    deleteDestino,
    setIsReadOnly,
  };

  const formElement = {
    register,
    handleSubmit,
    errors,
    onSubmit,
    setValue,
    getValues,
    control,
    reset,
  };

  return {
    state,
    actions,
    formElement,
  };
};
