import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addOffice,
  editOffice,
  searchOfficeById,
} from "actions/admin/officeAction";
import { getListParentOfficeAll } from "actions/admin/parentOfficeAction";
import { getListParentCities } from "actions/admin/parentCityAction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { phone } from "phone";
import { getPhone } from "utils/globals";

export const useFormOffice = (cityId) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, reset, setValue } = useForm({
    defaultValues: {
      name: "",
      parent_office_id: "",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [dataOffice, setDataOffice] = useState(null);
  const [isVisibleOffice, setIsVisibleOffice] = useState("no");
  const [listParentOffice, setListParentOffice] = useState([]);

  const { officeData } = useSelector((state) => state.offices);
  // const { listParentOffice } = useSelector((state) => state.parentOffices);
  const { companyDataBasic } = useSelector((state) => state.companies);
  const { listParentCities } = useSelector((state) => state.parentCities);

  const onSubmit = async (data, e) => {
    setIsLoading(true);

    let response;

    const body = {
      ...data,
      name: data?.name?.toUpperCase(),
      city_id: cityId,
      telephone_numbers:
        data?.telephone_numbers !== ""
          ? `${companyDataBasic?.country?.code}${data?.telephone_numbers}`
          : "",
      email: data?.email?.toLowerCase(),
      visible_portal: isVisibleOffice === "no" ? false : true,
    };

    if (dataOffice !== null) {
      response = await dispatch(editOffice(body, dataOffice?.id));
    } else {
      response = await dispatch(addOffice(body));
    }

    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      window.scrollTo(0, 0);
      setIsLoading(false);

      setTimeout(() => {
        history.push(`/admin/cities/`);
      }, 1000);
    } else {
      setIsLoading(false);
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
  };

  const getOfficeById = async (officeId) => {
    let data = officeData;
    if (listParentCities?.length === 0) {
      await dispatch(
        getListParentCities(companyDataBasic?.country?.id, "active")
      );
    }

    /**if (cityId !== null) {
      const result = await dispatch(searchCityById(cityId));
      await dispatch(
        getListParentOfficeAll(result?.data?.parent_city_id, "active")
      );
    }**/

    if (officeId !== null) {
      if (officeData === null) {
        const result = await dispatch(searchOfficeById(officeId));
        data = result?.data;
      }
    }

    if (data !== null) {
      await handleListOffice(data?.parent_office?.parent_city?.id);
    }
    reset({
      parent_city_id: data?.parent_office?.parent_city?.id ?? "",
      parent_office_id: data?.parent_office?.id ?? "",
      name: data?.name ?? "",
      address: data?.address ?? "",
      email: data?.email ?? "",
      telephone_numbers: getPhone(data?.telephone_numbers) ?? "",
      description: data?.description ?? "",
      schedule: data?.schedule ?? "",
      url_map: data?.url_map ?? "",
    });
    setIsVisibleOffice(data?.visible_portal ? "si" : "no");

    setDataOffice(officeId !== null ? data ?? null : null);
  };

  const getOfficeOnly = async (officeId) => {
    let data = officeData;
    if (officeId !== null) {
      if (officeData === null) {
        const result = await dispatch(searchOfficeById(officeId));
        data = result?.data;
      }
    }
    setDataOffice(officeId !== null ? data ?? null : null);
  };

  const setResetPhone = (data, companyBasic) => {
    setValue(
      "telephone_numbers",
      getPhone(data?.telephone_numbers, companyBasic) ?? ""
    );
  };

  const validatePhone = (value) => {
    if (
      !phone(`${companyDataBasic?.country?.code}${value}`, {
        country: companyDataBasic?.country?.iso,
        validateMobilePrefix: false,
      }).isValid
    ) {
      return "El número ingresado no es válido";
    }
  };

  // Función de validación para URLs de Google Maps
  const isGoogleMapsUrl = (url) => {
    try {
      const parsedUrl = new URL(url);

      return (
        parsedUrl.hostname.includes("google") &&
        parsedUrl.pathname.startsWith("/maps")
      );
    } catch (e) {
      return false; // No es una URL válida
    }
  };

  const handleListOffice = async (value) => {
    const response = await dispatch(getListParentOfficeAll(value, "active"));
    setListParentOffice(response?.data ?? []);
  };

  const actions = {
    getOfficeById,
    validatePhone,
    setIsVisibleOffice,
    setResetPhone,
    isGoogleMapsUrl,
    handleListOffice,
    getOfficeOnly,
  };

  const formElement = {
    isLoading,
    register,
    handleSubmit,
    errors,
    onSubmit,
    setValue,
  };

  const state = {
    listParentOffice,
    dataOffice,
    companyDataBasic,
    isVisibleOffice,
    listParentCities,
    officeData,
  };

  return {
    formElement,
    actions,
    state,
  };
};
