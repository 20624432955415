import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addAccount,
  editAccount,
  searchAccountById,
} from "actions/admin/depositAccountAction";
import { getListOfficeAll } from "actions/admin/officeAction";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

export const useFormDepositAccount = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, errors, setValue, reset } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [dataAccount, setDataAccount] = useState(null);

  const { accounts, offices } = useSelector((state) => {
    return {
      accounts: state.accounts,
      offices: state.offices,
    };
  });

  const { accountData } = accounts;
  const { listOffices } = offices;

  const onSubmit = async (data, e) => {
    setIsLoading(true);

    let response;

    const body = {
      ...data,
      name_bank: data?.name_bank?.toUpperCase(),
    };

    if (dataAccount?.id) {
      response = await dispatch(editAccount(body, dataAccount?.id));
    } else {
      response = await dispatch(addAccount(body));
    }

    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      window.scrollTo(0, 0);
      setIsLoading(false);

      setTimeout(() => {
        history.push("/admin/deposit-accounts");
      }, 1000);
    } else {
      setIsLoading(false);
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
  };

  const setDataInicial = async (data) => {
    if (data !== null) {
      setValue("sucursal_id", data.sucursal_id);
    }
  };

  const getAccountById = async (accountId) => {
    let data = accountData;

    if (listOffices?.length === 0) {
      dispatch(getListOfficeAll("active"));
    }

    if (accountId !== null) {
      if (accountData === null) {
        const result = await dispatch(searchAccountById(accountId));
        data = result?.data;
      }
    }
    reset({
      office_id: data?.office_id ?? "",
      name_bank: data?.name_bank ?? "",
      account_number: data?.account_number ?? "",
    });

    setDataAccount(accountId !== null ? data ?? null : null);
  };

  const formElement = {
    isLoading,
    register,
    handleSubmit,
    errors,
    onSubmit,
    setValue,
  };

  const state = {
    listOffices,
    dataAccount,
  };

  const actions = {
    getAccountById,
    setDataInicial,
  };

  return {
    formElement,
    state,
    actions,
  };
};
