import { Fragment, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./Presentacions/Login/";
import CreatedOrder from "./Presentacions/Orders/CreatedOrder";
import EditOrdenFlete from "./Presentacions/Orders/EditOrdenFlete";
import Home from "./Presentacions/Home/";
import SearchOrden from "./Presentacions/Orders/SearchOrden";
import CreatedNomina from "./Presentacions/Nomina/CreatedNomina";
import PreviewNomina from "./Presentacions/Nomina/PreviewNomina";
import PreviewConsulta from "./Presentacions/Orders/PreviewConsulta";
import PreviewOrder from "./Presentacions/Orders/PreviewOrder";
import PanelOpciones from "./Presentacions/Entrega/PanelOpciones";
import CreatedRetiro from "./Presentacions/Retiro/Created";
import PreviewTicket from "./Presentacions/Retiro/PreviewTicket";
import AssignRetiro from "./Presentacions/Retiro/AssignRetiro";
import Nomina from "./Presentacions/Retiro/Nomina";
import Dashboard from "./Presentacions/Rendicion/Dashboard";
import Rendir from "./Presentacions/Rendicion/Rendir";
import RendirById from "./Presentacions/Rendicion/RendirById";
import PreviewRendir from "./Presentacions/Rendicion/PreviewRendir";
//ROLES
import ListRole from "./Presentacions/Admin/Roles/List";
import CreateRole from "./Presentacions/Admin/Roles/Create";
import EditRole from "./Presentacions/Admin/Roles/Edit";
//PLACAS
import ListTruck from "./Presentacions/Admin/Trucks/List";
import CreateTruck from "./Presentacions/Admin/Trucks/Create";
import EditTruck from "./Presentacions/Admin/Trucks/Edit";
//Cuenta deposito
import ListDepositAccount from "./Presentacions/Admin/DepositAccount/List";
import CreateDepositAccount from "./Presentacions/Admin/DepositAccount/Create";
import EditDepositAccount from "./Presentacions/Admin/DepositAccount/Edit";
//Paises
import ListCountry from "./Presentacions/Admin/Countries/List";
import CreateCountry from "./Presentacions/Admin/Countries/Create";
//Sucursal padre
import ListParentCity from "./Presentacions/Admin/ParentCities/List";
import CreateParentCity from "./Presentacions/Admin/ParentCities/Create";
import EditParentCity from "./Presentacions/Admin/ParentCities/Edit";
//Oficina padre
import ListParentOffice from "./Presentacions/Admin/ParentOffices/List";
import CreateParentOffice from "./Presentacions/Admin/ParentOffices/Create";
import EditParentOffice from "./Presentacions/Admin/ParentOffices/Edit";
//Comuna padre
import ListCommune from "./Presentacions/Admin/Communes/List";
import CreateCommune from "./Presentacions/Admin/Communes/Create";
import EditCommune from "./Presentacions/Admin/Communes/Edit";
//Conductores
import ListDrive from "./Presentacions/Admin/Drivers/List";
//Sucursales
import ListOffice from "./Presentacions/Admin/Offices/List";
import CreateOffice from "./Presentacions/Admin/Offices/Create";
import EditOffice from "./Presentacions/Admin/Offices/Edit";
//Compañia
import ListCompany from "./Presentacions/Admin/Companies/List";
import CreateCompany from "./Presentacions/Admin/Companies/Create";
import EditCompany from "./Presentacions/Admin/Companies/Edit";
//Usuarios
import ListUser from "./Presentacions/Admin/Users/List";
import CreateUser from "./Presentacions/Admin/Users/Create";
import EditUser from "./Presentacions/Admin/Users/Edit";
import ChangePasswordUser from "./Presentacions/Admin/Users/ChangePassword";
import AssignTrucksUser from "./Presentacions/Admin/Users/AssignTruck";
//Clientes
import ListCustomer from "./Presentacions/Admin/Customers/List";
import CreateCustomer from "./Presentacions/Admin/Customers/Create";
import EditCustomer from "./Presentacions/Admin/Customers/Edit";
//Correlativos
import ListCorrelative from "./Presentacions/Admin/Correlatives/List";
//Taxes
import ListTaxe from "./Presentacions/Admin/Taxes/List";

import Facturacion from "./Presentacions/Facturacion/Panel";
import NotFound from "./Presentacions/NotFound";
import ReceiveTicket from "./Presentacions/Orders/ReceiveTicket";
import ReceiveOrden from "./Presentacions/Orders/ReceiveOrden";
import OpcionesEditarOrdenes from "./Presentacions/Soluciones/OpcionesEditarOrdenes";
import PreviewCorrelativos from "./Presentacions/Orders/PreviewCorrelativos";
import Options from "./Presentacions/Despacho/Options";
import SearchByParameters from "./Presentacions/Retiro/SearchByParameters";
import CompletedRetiro from "./Presentacions/Retiro/CompletedRetiro";
import PreviewRetiro from "./Presentacions/Retiro/PreviewRetiro";
import SearchListInvoices from "./Presentacions/Facturacion/SearchListInvoices";
import MultiplePago from "./Presentacions/Facturacion/MultiplePago";
import DetailPaymentInvoice from "./Presentacions/Facturacion/DetailPaymentInvoice";
/**

import ConfiguracionFactura from "./Presentacions/Configuracion/Factura";

**/
import ListOrderIncomplete from "./Presentacions/Orders/ListOrderIncomplete";
import LoadingUserScreen from "./components/Global/LoadingUserScreen";
import ResetPasswordUser from "./Presentacions/Auth/ResetPasswordUser";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/menu.css";

import packageJson from "../package.json";

function App() {
  const [showPage, setShowPage] = useState(true);

  const verifyLogged = (Component) => {
    if (JSON.parse(localStorage.getItem("user"))) {
      setShowPage(false);

      return <Component />;
    } else {
      setShowPage(false);

      return <Redirect to="/" />;
    }
  };

  useEffect(() => {
    let version = localStorage.getItem("version");

    if (version !== packageJson.version) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }

      localStorage.clear();
      localStorage.setItem("version", packageJson.version);
    }
  }, []);

  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === "isLoggedOut") {
        // Verifica que el cambio sea el del cierre de sesión
        if (!localStorage.getItem("sessionData")) {
          window.location.href = "/login"; // Redirige al login
        }
      }
    };

    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  return (
    <Fragment>
      <LoadingUserScreen showPage={showPage} />
      <Router>
        <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route exact path="/home">
            <Home />
          </Route>
          <Route exact path="/auth/verify-user">
            <ResetPasswordUser />
          </Route>

          <Route
            exact
            path="/admin/roles"
            render={() => verifyLogged(ListRole)}
          />

          <Route
            exact
            path="/admin/roles/create"
            render={() => verifyLogged(CreateRole)}
          />

          <Route
            exact
            path="/admin/roles/:rolId/edit"
            render={() => verifyLogged(EditRole)}
          />

          <Route
            exact
            path="/admin/trucks"
            render={() => verifyLogged(ListTruck)}
          />

          <Route
            exact
            path="/admin/trucks/create"
            render={() => verifyLogged(CreateTruck)}
          />

          <Route
            exact
            path="/admin/trucks/:truckId/edit"
            render={() => verifyLogged(EditTruck)}
          />

          <Route
            exact
            path="/admin/deposit-accounts"
            render={() => verifyLogged(ListDepositAccount)}
          />

          <Route
            exact
            path="/admin/deposit-accounts/create"
            render={() => verifyLogged(CreateDepositAccount)}
          />

          <Route
            exact
            path="/admin/deposit-accounts/:accountId/edit"
            render={() => verifyLogged(EditDepositAccount)}
          />

          <Route
            exact
            path="/admin/countries"
            render={() => verifyLogged(ListCountry)}
          />

          <Route
            exact
            path="/admin/countries/create"
            render={() => verifyLogged(CreateCountry)}
          />

          <Route
            exact
            path="/admin/countries/:countryId/parent-cities"
            render={() => verifyLogged(ListParentCity)}
          />

          <Route
            exact
            path="/admin/countries/:countryId/parent-cities/create"
            render={() => verifyLogged(CreateParentCity)}
          />

          <Route
            exact
            path="/admin/countries/:countryId/parent-cities/:parentCityId/edit"
            render={() => verifyLogged(EditParentCity)}
          />

          <Route
            exact
            path="/admin/countries/:countryId/parent-cities/:parentCityId/parent-offices"
            render={() => verifyLogged(ListParentOffice)}
          />

          <Route
            exact
            path="/admin/countries/:countryId/parent-cities/:parentCityId/parent-offices/create"
            render={() => verifyLogged(CreateParentOffice)}
          />

          <Route
            exact
            path="/admin/countries/:countryId/parent-cities/:parentCityId/parent-offices/:parentOfficeId/edit"
            render={() => verifyLogged(EditParentOffice)}
          />

          <Route
            exact
            path="/admin/users/drives"
            render={() => verifyLogged(ListDrive)}
          />

          <Route
            exact
            path="/admin/cities"
            render={() => verifyLogged(ListOffice)}
          />

          <Route
            exact
            path="/admin/cities/create"
            render={() => verifyLogged(CreateOffice)}
          />

          <Route
            exact
            path="/admin/cities/:officeId/edit"
            render={() => verifyLogged(EditOffice)}
          />

          <Route
            exact
            path="/admin/cities/:officeId/communes"
            render={() => verifyLogged(ListCommune)}
          />

          <Route
            exact
            path="/admin/cities/:officeId/communes/create"
            render={() => verifyLogged(CreateCommune)}
          />

          <Route
            exact
            path="/admin/cities/:officeId/communes/:communeId/edit"
            render={() => verifyLogged(EditCommune)}
          />

          <Route
            exact
            path="/admin/companies"
            render={() => verifyLogged(ListCompany)}
          />

          <Route
            exact
            path="/admin/companies/create"
            render={() => verifyLogged(CreateCompany)}
          />

          <Route
            exact
            path="/admin/companies/:companyId/edit"
            render={() => verifyLogged(EditCompany)}
          />

          <Route
            exact
            path="/admin/users"
            render={() => verifyLogged(ListUser)}
          />

          <Route
            exact
            path="/admin/users/create"
            render={() => verifyLogged(CreateUser)}
          />

          <Route
            exact
            path="/admin/users/:userId/edit"
            render={() => verifyLogged(EditUser)}
          />

          <Route
            exact
            path="/admin/users/:userId/change-password"
            render={() => verifyLogged(ChangePasswordUser)}
          />

          <Route
            exact
            path="/admin/users/:userId/assign-trucks"
            render={() => verifyLogged(AssignTrucksUser)}
          />

          <Route
            exact
            path="/admin/customers"
            render={() => verifyLogged(ListCustomer)}
          />

          <Route
            exact
            path="/admin/customers/create"
            render={() => verifyLogged(CreateCustomer)}
          />

          <Route
            exact
            path="/admin/customers/:customerId/edit"
            render={() => verifyLogged(EditCustomer)}
          />

          <Route
            exact
            path="/admin/correlatives"
            render={() => verifyLogged(ListCorrelative)}
          />

          <Route
            exact
            path="/admin/taxes"
            render={() => verifyLogged(ListTaxe)}
          />

          <Route
            exact
            path="/transport-orders/create"
            render={() => verifyLogged(CreatedOrder)}
          />

          <Route
            exact
            path="/transport-orders/:order_number/edit"
            render={() => verifyLogged(EditOrdenFlete)}
          />
          <Route
            exact
            path="/transport-orders/incomplete"
            render={() => verifyLogged(ListOrderIncomplete)}
          />

          <Route
            exact
            path="/transport-orders/search"
            render={() => verifyLogged(SearchOrden)}
          />

          <Route
            exact
            path="/payrolls/create"
            render={() => verifyLogged(CreatedNomina)}
          />

          <Route
            exact
            path="/payrolls/:numero/ticket"
            render={() => verifyLogged(PreviewNomina)}
          />

          <Route
            exact
            path="/transport-orders/package"
            render={() => verifyLogged(ReceiveTicket)}
          />

          <Route
            exact
            path="/transport-orders/preview/file"
            render={() => verifyLogged(PreviewConsulta)}
          />

          <Route
            exact
            path="/transport-orders/received"
            render={() => verifyLogged(ReceiveOrden)}
          />

          <Route
            exact
            path="/transport-orders/solution"
            render={() => verifyLogged(OpcionesEditarOrdenes)}
          />

          <Route
            exact
            path="/transport-orders/:numero/ticket"
            render={() => verifyLogged(PreviewOrder)}
          />

          <Route
            exact
            path="/transport-orders/:numero/packages"
            render={() => verifyLogged(PreviewCorrelativos)}
          />

          <Route exact path="/guide" render={() => verifyLogged(Options)} />

          <Route
            exact
            path="/transport-orders/deliver"
            render={() => verifyLogged(PanelOpciones)}
          />

          <Route
            exact
            path="/withdrawals/create"
            render={() => verifyLogged(CreatedRetiro)}
          />

          <Route
            exact
            path="/withdrawals/:numero_retiro/ticket"
            render={() => verifyLogged(PreviewTicket)}
          />

          <Route
            exact
            path="/withdrawals/search"
            render={() => verifyLogged(SearchByParameters)}
          />

          <Route
            exact
            path="/withdrawals/assign-vehicle"
            render={() => verifyLogged(AssignRetiro)}
          />

          <Route
            exact
            path="/withdrawals/nominate"
            render={() => verifyLogged(Nomina)}
          />

          <Route
            exact
            path="/withdrawals/:numero_retiro"
            render={() => verifyLogged(CompletedRetiro)}
          />

          <Route
            exact
            path="/withdrawals/:numero_retiro/preordenes"
            render={() => verifyLogged(PreviewRetiro)}
          />

          <Route
            exact
            path="/invoicing"
            render={() => verifyLogged(Facturacion)}
          />

          <Route
            exact
            path="/invoicing/search"
            render={() => verifyLogged(SearchListInvoices)}
          />

          <Route
            exact
            path="/invoicing/create-payment"
            render={() => verifyLogged(MultiplePago)}
          />

          <Route
            exact
            path="/invoicing/:payment_id/payment"
            render={() => verifyLogged(DetailPaymentInvoice)}
          />

          <Route
            exact
            path="/cash-accountability"
            render={() => verifyLogged(Dashboard)}
          />

          <Route
            exact
            path="/cash-accountability/create"
            render={() => verifyLogged(Rendir)}
          />

          <Route
            exact
            path="/cash-accountability/print"
            render={() => verifyLogged(PreviewRendir)}
          />

          <Route
            exact
            path="/cash-accountability/:accountability_id"
            render={() => verifyLogged(RendirById)}
          />

          {/**
          

          
                 
          <Route
            exact
            path="/config/factura"
            render={() => verifyLogged(ConfiguracionFactura)}
          />
          
          **/}

          <Route component={NotFound} />
        </Switch>
      </Router>
    </Fragment>
  );
}

export default App;
