import { useState } from "react";
import { searchCustomer } from "actions/admin/customerAction";
import { generateDoc } from "actions/transportOrdersAction";
import { validarRut } from "utils/rut";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { phone } from "phone";
import { getPhone } from "utils/globals";

const RUT_MESON = process.env.REACT_APP_CLIENTE_MESON;

export const useDocumentFactura = (
  toast,
  data,
  loadingPago,
  setLoadingPago,
  setOpenForm,
  getDatInitial
) => {
  const dispatch = useDispatch();
  const [isSpinner, setIsSpinner] = useState(false);
  const [ciudades, setCiudades] = useState([]);
  const [isActivoRut, setIsActivoRut] = useState(true);
  const [bloquearInput, setBloquearInput] = useState(true);
  const [isProcesando, setIsProcesando] = useState(false);

  const { listParentCities } = useSelector((state) => state.parentCities);
  const { companyDataBasic } = useSelector((state) => state.companies);
  const { register, handleSubmit, errors, setValue, control } = useForm();

  const onSubmit = (fact, event) => {
    event.preventDefault();
    Swal.fire({
      title: "¿Seguro desea generar el documento de pago?",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sí",
      cancelButtonText: "No",
    }).then(async (result) => {
      if (result.isConfirmed) {
        setLoadingPago(true);
        setIsProcesando(true);
        setIsSpinner(true);

        const cityOrigin = listParentCities?.find(
          (item) => item.id === Number(fact?.ciudad_factura)
        );

        const officeOrigin = cityOrigin?.offices?.find(
          (item) => item.id === Number(fact?.comuna_factura)
        );

        const body = {
          numero_orden: data?.order_number,
          documentacion_id: parseInt(data?.documentacion_id_pago),
          rut_remitente: data?.rut_remitente,
          factura: {
            ...fact,
            contacto_factura: `${companyDataBasic?.country?.code}${fact?.contacto_factura}`,
            ciudad_factura: cityOrigin?.name,
            comuna_factura: officeOrigin?.name,
          },
        };

        let response = await dispatch(generateDoc(body));

        setLoadingPago(false);
        setIsProcesando(false);
        setIsSpinner(false);
        if (!response.status) {
          toast.error(response.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          document.querySelector("#numero_documento").value =
            response.numero_documento;
          document.querySelector("#rut_factura").value =
            body?.factura?.razon_social_factura;
          document.querySelector("#razon_social_factura").value =
            body?.factura?.cliente_rut;

          toast.success(response.message, {
            position: toast.POSITION.TOP_RIGHT,
          });
          setOpenForm(false);
          getDatInitial(data?.order_number);
        }
      }
    });
  };

  const dataCliente = async (rut_factura) => {
    if (rut_factura.length > 0) {
      const isValid = validarRut(rut_factura);
      if (!isValid.status) {
        toast.error(isValid.message, { position: toast.POSITION.TOP_RIGHT });
        return false;
      }
      if (rut_factura === RUT_MESON) {
        toast.error("No se puede procesar facturas con ese RUT", {
          position: toast.POSITION.TOP_RIGHT,
        });
        return false;
      }
      const response = await dispatch(
        searchCustomer({ search_type: "rut_cliente", value: rut_factura })
      );

      if (response.status) {
        //cargarCiudades(undefined, response.cliente.sucursal_id);
        /**if(rut_factura===RUT_MESON){
					if(getValues("type_cliente_factura")==="remitente"){
						setValue("razon_social_factura", data.razon_social_remitente)
					}else{
						setValue("razon_social_factura", data.razon_social_destinatario)
					}
				}else{
					setValue("razon_social_factura", response.cliente.nombre_completo)
				}**/
        setValue("razon_social_factura", response.data.full_name);
        setValue("giro_factura", response.data.tax_draft);
        setValue(
          "contacto_factura",
          getPhone(response.data.phone_number, companyDataBasic)
        );
        setValue("email_factura", response.data.email);
        setValue("direccion_factura", response.data.address);
        //setValue("comuna_factura", response.cliente.comuna_id);
        //setValue("ciudad_factura", response.cliente.sucursal_id);
      }
      setBloquearInput(false);
    }
  };

  const cargarCiudades = (e, valor) => {
    const id = e !== undefined ? e.target.value : valor;
    if (id !== "") {
      const detOficinas = listParentCities.find((s) => s.id === parseInt(id));
      let comunas =
        detOficinas && detOficinas.offices ? detOficinas.offices : [];

      let detComuna = comunas;
      if (comunas.length > 0) {
        detComuna = comunas.filter((c) => c.active !== 0);
      }

      setCiudades(detComuna);
    }
  };

  const selectCliente = async (event) => {
    const { value } = event.target;
    let rut_factura = "";
    resetInput();

    if (value === "otros") {
      rut_factura = "";
      setIsActivoRut(false);
      setBloquearInput(false);
      setValue("cliente_rut", "");
    } else {
      rut_factura =
        value === "destinatario" ? data.rut_destinatario : data.rut_remitente;
      setValue("cliente_rut", rut_factura);

      setIsActivoRut(true);
      await dataCliente(rut_factura);
    }
  };

  const resetInput = () => {
    setBloquearInput(true);
    setValue("razon_social_factura", "");
    setValue("giro_factura", "");
    setValue("contacto_factura", "");
    setValue("email_factura", "");
    setValue("direccion_factura", "");
    setValue("comuna_factura", "");
    setValue("ciudad_factura", "");
  };

  const searchClienteFactura = async (event) => {
    if (event.target.value.length > 0) {
      await dataCliente(event.target.value);
    }
  };

  const onKeyDownFactura = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      await searchClienteFactura(event);
    }
  };

  const validatePhone = (value) => {
    if (
      !phone(`${companyDataBasic?.country?.code}${value}`, {
        country: companyDataBasic?.country?.iso,
        validateMobilePrefix: false,
      }).isValid
    ) {
      return "El número ingresado no es válido";
    }
  };

  return {
    cargarCiudades,
    isSpinner,
    sucursales: listParentCities,
    register,
    handleSubmit,
    errors,
    onSubmit,
    bloquearInput,
    selectCliente,
    ciudades,
    searchClienteFactura,
    onKeyDownFactura,
    isActivoRut,
    isProcesando,
    control,
    companyDataBasic,
    validatePhone,
  };
};
