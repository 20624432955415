import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { createdRetiro, resetDestination } from "actions/retiroAction";
import { getDataInitialOrder } from "actions/transportOrdersAction";
import { searchCustomer } from "actions/admin/customerAction";
import { phone } from "phone";
import { validarRut } from "utils/rut";
import { usePermission } from "hooks/Permission/usePermission";
import { getPhone } from "utils/globals";
import { getListOfficeAll } from "actions/admin/officeAction";

const RUT_MESON = process.env.REACT_APP_CLIENTE_MESON;

export const useCreated = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { register, handleSubmit, errors, setValue, getValues, control } =
    useForm();

  const { dataRetiro, listDestino, isPreviewRetiro, nroRetiro } = useSelector(
    (state) => state.retiros
  );
  const { tipoEnvios, formaPagos } = useSelector(
    (state) => state.transportOrders
  );
  const { listTrucks } = useSelector((state) => state.trucks);
  const { city_id, office_id } = useSelector((state) => state.users);
  const { companyDataBasic } = useSelector((state) => state.companies);
  const { listOffices } = useSelector((state) => state.offices);

  const [isLoading, setIsLoading] = useState(false);
  const [openCloseCta, setOpenCloseCta] = useState(false);
  const [clienteSelected, setClienteSelected] = useState(false);
  const [isValidate, setIsValidate] = useState(true);
  const [readonlyRemitente, setReadonlyRemitente] = useState(true);

  const { validarPermiso } = usePermission();

  const getDataInitial = async () => {
    if (listOffices?.length === 0) {
      await dispatch(getDataInitialOrder());
      await dispatch(getListOfficeAll("active"));
    }
  };

  const handleFormRetiro = () => {
    dispatch(resetDestination("created"));

    if (document.getElementById("razon_social_remitente")) {
      document.getElementById("razon_social_remitente").readOnly = true;
    }
  };

  const onSubmit = async (data, event) => {
    event.preventDefault();
    setIsLoading(true);

    const body = {
      ...data,
      telefono_remitente: `${companyDataBasic?.country?.code}${data?.telefono_remitente}`,
    };
    if (listDestino.length > 0) {
      let response = await dispatch(createdRetiro(body));
      if (response.status) {
        toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        event.target.reset();
        history.push(`/withdrawals/${response.nroRetiro}/ticket`);
      } else {
        toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } else {
      toast.error("Debe de ingresar el detalle del destino", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    setIsLoading(false);
  };

  const onKeyDownRemitente = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      await searchClienteRemitente();
    }
  };

  const onBlurRUT = (event) => {
    const isValid = validarRut(event.target.value);
    if (!isValid.status) {
      resetFormRemitente(true);
    }
  };

  const searchClienteRemitente = async () => {
    let search;

    setReadonlyRemitente(true);

    const rut = document.getElementById("rut_remitente").value;

    const isValid = validarRut(rut);

    if (isValid.status) {
      search = { search_type: "rut_cliente", value: rut };
    } else {
      toast.error(isValid.message, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }

    if (rut === RUT_MESON) {
      toast.error("Ese RUT es inválido", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    const result = await dispatch(searchCustomer(search));

    if (result.status) {
      setIsValidate(false);
      setValue("codigo_interno", "");

      if (result?.data?.account_numbers?.length > 1) {
        setClienteSelected(result?.data);
        setOpenCloseCta(true);
        setIsValidate(true);
      } else {
        resetDataRemitente(result?.data);

        if (result?.data?.account_numbers?.length === 1) {
          setValue(
            "codigo_interno",
            result?.data?.account_numbers[0]?.internal_number
          );
          setIsValidate(true);
        }

        setReadonlyRemitente(false);
      }
    } else {
      if (result?.message === "El cliente está desactivado") {
        document.getElementById("razon_social_remitente").readOnly = true;
        toast.error(result?.message, { position: toast.POSITION.TOP_RIGHT });
        setValue("rut_remitente", "");
      } else {
        document.getElementById("razon_social_remitente").readOnly = false;
      }
      resetFormRemitente(false);
    }
  };

  const resetDataRemitente = (cliente) => {
    setValue("rut_remitente", cliente.rut);
    setValue("razon_social_remitente", cliente?.full_name);
    document.getElementById("razon_social_remitente").readOnly = true;
    setValue(
      "telefono_remitente",
      getPhone(cliente?.phone_number, companyDataBasic)
    );
    setValue("email_remitente", cliente?.email);
    setValue("direccion_retiro", cliente.address);
  };

  const resetFormRemitente = (isReadOnly) => {
    setIsValidate(false);
    setValue("razon_social_remitente", "");
    setValue("telefono_remitente", "");
    setValue("codigo_interno", "");
    setValue("email_remitente", "");
    setValue("direccion_retiro", "");
    setReadonlyRemitente(isReadOnly);
    document.getElementById("razon_social_remitente").readOnly = isReadOnly;
  };

  const cambiarFormaPago = (event) => {
    if (parseInt(event.target.value) === 3) {
      if (getValues("codigo_interno") === "") {
        toast.error("El cliente no es cuenta correntista", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setValue("forma_pago_id", "");
        return false;
      }
    }
  };

  const validatePhone = (value) => {
    if (
      !phone(`${companyDataBasic?.country?.code}${value}`, {
        country: companyDataBasic?.country?.iso,
        validateMobilePrefix: false,
      }).isValid
    ) {
      return "El número ingresado no es válido";
    }
  };

  const selectCodeClient = (codigo) => {
    resetDataRemitente(clienteSelected);
    setValue("codigo_interno", codigo);
    setOpenCloseCta(!openCloseCta);
    setReadonlyRemitente(false);
  };

  const handleOpenCloseCta = () => {
    setOpenCloseCta(!openCloseCta);
    resetFormRemitente(true);
  };

  const state = {
    dataRetiro,
    listDestino,
    isPreviewRetiro,
    nroRetiro,
    listOffices,
    listTrucks,
    isLoading,
    city_id,
    office_id,
    tipoEnvios,
    formaPagos,
    openCloseCta,
    clienteSelected,
    isValidate,
    companyDataBasic,
    readonlyRemitente,
  };

  const actions = {
    handleFormRetiro,
    getDataInitial,
    searchCliente: searchCustomer,
    validarPermiso,
    validatePhone,
    cambiarFormaPago,
    onKeyDownRemitente,
    selectCodeClient,
    handleOpenCloseCta,
    onBlurRUT,
  };

  const formElement = {
    onSubmit,
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
  };

  return {
    state,
    actions,
    formElement,
  };
};
