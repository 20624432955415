import React, { useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Container,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import Layout from "components/Layouts/Layout";
import SearchGlobal from "components/Rendicion/SearchGlobal";
import Cajas from "components/Rendicion/Cajas";
import { useDispatch, useSelector } from "react-redux";
import {
  getCuentaTipoDeposito,
  getListadoSucursal,
  cleanListadoSucursal,
} from "actions/rendirAction";
import { getListOfficeAll } from "actions/admin/officeAction";
import { getListPayments } from "actions/metodoPagoAction";
import { FaPlus } from "react-icons/fa";
import { useHistory, useLocation } from "react-router-dom";
import descuadrada from "assets/images/descuadrada.png";
import rendicion from "assets/images/rendicion_cerrada.png";
import abierta from "assets/images/caja_abierta.png";

const Dashoard = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { cajaDescuadrada, ultimasCajas, cajasAbiertas } = useSelector(
    (state) => state.rendir
  );

  useEffect(() => {
    dispatch(getListOfficeAll("active"));
    dispatch(getCuentaTipoDeposito());
    dispatch(getListPayments());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const searchCaja = async (caja) => {
    const data = {
      ...caja,
      fecha: caja.fecha,
    };

    const response = await dispatch(getListadoSucursal(data));
    if (response.status) {
      history.push(`/cash-accountability/${caja.id}`);
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
  };

  const newRendicion = (event) => {
    event.preventDefault();
    dispatch(cleanListadoSucursal());
    history.push("/cash-accountability/create");
  };

  return (
    <Layout titlePanel="Rendición de caja" modulo="cerrar_caja">
      <SearchGlobal toast={toast} location={location} />
      <Container>
        <Row className="mt-3">
          <Col className="col-12 col-md-6 col-lg-4 mx-auto">
            <Cajas
              key={0}
              detalle={cajaDescuadrada}
              title="Cajas descuadradas"
              icono={descuadrada}
              searchCaja={searchCaja}
            />
          </Col>
          <Col className="col-12 col-md-6 col-lg-4 mx-auto">
            <Cajas
              key={1}
              detalle={ultimasCajas}
              title="Últimas cajas cerradas"
              icono={rendicion}
              searchCaja={searchCaja}
            />
          </Col>
          <Col className="col-12 col-md-6 col-lg-4 mx-auto">
            <Cajas
              key={2}
              detalle={cajasAbiertas}
              title="Cajas abiertas"
              icono={abierta}
              searchCaja={searchCaja}
            />
          </Col>
        </Row>
      </Container>
      <div className="btn-nueva-rendicion">
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip">Crear Rendición</Tooltip>}
        >
          <Button
            type="button"
            className="btn btn-warning text-white"
            onClick={(event) => newRendicion(event)}
          >
            <FaPlus />
          </Button>
        </OverlayTrigger>
      </div>
      <ToastContainer />
    </Layout>
  );
};

export default Dashoard;
