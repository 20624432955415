const Company = {
  columnas: [
    {
      name: "Logo",
      selector: (row, i) => row.logo,
      sortable: true,
      cell: (row) => (
        <>
          {row.logo && row.logo !== "" && (
            <img className="img-foto-licencia mr-2" src={row.logo} alt="Logo" />
          )}
        </>
      ),
    },
    {
      name: "RUT",
      selector: (row, i) => row.rut,
      sortable: true,
    },
    {
      name: "Razón social",
      selector: (row, i) => row.legal_name,
      sortable: true,
    },
    {
      name: "Tipo de cliente",
      selector: (row, i) => row.type_customer,
      sortable: true,
    },
    {
      name: "Representante legal",
      selector: (row, i) =>
        `${row.name_legal_agent} ${row.surname_legal_agent}`,
      sortable: true,
    },
    {
      name: "País",
      selector: (row, i) => row?.country?.name,
      sortable: true,
    },
    {
      name: "Facturación",
      selector: (row, i) => (row.invoice ? "Si" : "No"),
      sortable: true,
    },
    {
      name: "Verificado",
      selector: (row, i) => row.name_verified,
      sortable: true,
    },
    {
      name: "Estatus",
      selector: (row, i) => (row.active ? "Activado" : "Desactivado"),
      sortable: true,
    },
  ],
};

export default Company;
