export const useDetailPackage = () => {
  const saveDetalleCarga = (packageCurrent, lisPackages) => {
    const lisPackagesDefaults = [...lisPackages];
    const payload = [
      ...lisPackagesDefaults,
      {
        ...packageCurrent,
        visible: false,
        total_temporal: packageCurrent.valor_neto,
      },
    ];

    return payload;
  };

  const resultTotales = (descuento, listPackages) => {
    if (listPackages && listPackages.length > 0) {
      let totalBulto = listPackages.reduce(
        (acumulado, bultos) => acumulado + parseFloat(bultos.bulto),
        0
      );
      let totalKilo = listPackages.reduce(
        (acumulado, kilos) => acumulado + parseFloat(kilos.peso),
        0
      );
      let totalM3 = listPackages.reduce(
        (acumulado, metros) => acumulado + parseFloat(metros.metraje_cubico),
        0
      );
      let subTotal = listPackages.reduce(
        (acumulado, valor) => acumulado + parseFloat(valor.valor_neto),
        0
      );

      let totals = 0;
      let applyDscto = 0;
      let totaliva = 0;

      if (descuento === "Si") {
        applyDscto = Math.round(subTotal * (10 / 100));
        totals = subTotal - applyDscto;
        let montoDiferencia = Math.round(totals / 1.19);
        totaliva = totals - montoDiferencia;
        subTotal = totals - totaliva;
      } else {
        totals = subTotal;
        let montoDiferencia = Math.round(totals / 1.19);
        totaliva = totals - montoDiferencia;
        subTotal = totals - totaliva;
      }

      let result = {
        total_bultos: totalBulto ?? 0,
        total_kilos: totalKilo ?? 0,
        total_metro_cubico: totalM3 ?? 0,
        subtotal_orden: subTotal ?? 0,
        valor_neto: subTotal ?? 0,
        valor_iva: totaliva ?? 0,
        total: totals ?? 0,
      };

      return result;
    }

    return {
      total_bultos: 0,
      total_kilos: 0,
      total_metro_cubico: 0,
      subtotal_orden: 0,
      valor_neto: 0,
      valor_iva: 0,
      total: 0,
    };
  };

  const deleteDetalleCarga = (index, listPackages) => {
    return [...listPackages].filter((carga, i) => i !== index);
  };

  const saveEditDetalleCarga = (carga, index, listPackages) => {
    const payload = [...listPackages].map((item, i) => {
      if (i === index) {
        return { ...carga, visible: false };
      }
      return item;
    });

    return payload;
  };

  const habEditCarga = (index, listPackages) => {
    const payload = [...listPackages].map((carga, i) => {
      carga.visible = i === index ? true : false;
      return carga;
    });
    return payload;
  };

  const editDetalleCargaWithPrice = (withPrice, listPackages) => {
    let detCargaItem = [...listPackages].map((item) => {
      return {
        ...item,
        valor_neto: withPrice !== "no_price" ? item?.total_temporal : 0,
      };
    });
    return detCargaItem;
  };

  return {
    saveDetalleCarga,
    resultTotales,
    deleteDetalleCarga,
    saveEditDetalleCarga,
    habEditCarga,
    editDetalleCargaWithPrice,
  };
};
