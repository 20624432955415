import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import Formulario from "components/Admin/Offices/Formulario";
import { useParams } from "react-router-dom";

const CreateOffice = () => {
  const { cityId } = useParams();
  return (
    <Layout titlePanel="Administrar" modulo="administrar_cities">
      <div className="pl-3 pr-3">
        <CardRetiro title="Crear sucursal">
          <Formulario officeId={null} cityId={cityId} />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default CreateOffice;
