import Layout from "components/Layouts/Layout";
import ModalSpinner from "components/Custom/Spinner/ModalSpinner";
import FormRemitente from "components/Retiros/CompletedPreOrden/FormRemitente";
import TableDestination from "components/Retiros/CompletedPreOrden/TableDestination";
import ModalPreOrdenAdmin from "components/Retiros/CompletedPreOrden/Admin/ModalPreOrdenAdmin";
import AnularRetiro from "components/Retiros/AnularRetiro";

import { ToastContainer, toast } from "react-toastify";

import { useParams, Link } from "react-router-dom";
import { validarRut } from "utils/rut";
import { useCancelRetiro } from "hooks/Retiro/useCancelRetiro";
import { useDetailRetiro } from "hooks/Retiro/useDetailRetiro";
import { usePermission } from "hooks/Permission/usePermission";

const CompletedRetiro = () => {
  const { numero_retiro } = useParams();
  const { validarPermiso } = usePermission();

  const {
    detRetiro,
    preordenSelected,
    isLoadingDetRetiro,
    documentacionsCarga,
    formaPagos,
    openCloseModal,
    show,
    handleSelectedPreOrden,
    handleCompletedPreOrden,
    setShow,
    titleDetailRetiro,
    searchCliente,
    listOffices,
    tipoEnvios,
  } = useDetailRetiro(numero_retiro, toast);

  const {
    anularModal,
    handleAnular,
    onSubmit,
    register,
    handleSubmit,
    errors,
    loading,
    numeroSelect,
  } = useCancelRetiro();

  const disabledBtn = () => {
    if (detRetiro?.ultimo_estado === "Anulado") {
      return false;
    } else if (detRetiro?.ultimo_estado !== "Finalizado") {
      return true;
    }
    return validarPermiso("editar_retiro");
  };

  return (
    <Layout titlePanel="Detalle Retiros" modulo="consulta-retiros">
      <div className="card mb-5 mt-3 card-retiro shadow-sm">
        <div className="card-header card-header-white card-retiro-completed">
          <h6 className="mb-0">{titleDetailRetiro}</h6>
          <div>
            <Link to="/withdrawals/search">
              <button className="btn btn-warning">Regresar</button>
            </Link>
            {!isLoadingDetRetiro &&
            detRetiro?.ultimo_estado !== "Anulado" &&
            validarPermiso("anular_retiro") ? (
              <button
                className="btn btn-outline-warning pl-4 pr-4 ml-3"
                onClick={(event) =>
                  handleAnular(event, { type: "retiro", numero: numero_retiro })
                }
              >
                Anular
              </button>
            ) : null}
            <Link to={`/withdrawals/${numero_retiro}/preordenes`}>
              <button className="btn btn-outline-warning pl-4 pr-4 ml-3">
                Imprimir
              </button>
            </Link>
          </div>
        </div>
        <div className="card-body">
          <FormRemitente
            detRetiro={detRetiro}
            isAdmin={disabledBtn()}
            searchCliente={searchCliente}
            validarRut={validarRut}
            toast={toast}
            formaPagos={formaPagos}
            listOffices={listOffices}
          />
          <div className="row">
            <div className="col-12">
              <p className="bg-warn-blue mb-0 fw-600 text-center p-0 mb-4">
                Destinatarios
              </p>
              <TableDestination
                openCloseModal={openCloseModal}
                handleSelectedPreOrden={handleSelectedPreOrden}
                handleCompletedPreOrden={handleCompletedPreOrden}
                detRetiro={detRetiro}
                isAdmin={validarPermiso("editar_retiro")}
                handleAnular={handleAnular}
              />
            </div>
          </div>
        </div>
      </div>
      {show && (
        <ModalPreOrdenAdmin
          completedModal={show}
          openCloseModalCompleted={openCloseModal}
          preordenSelected={preordenSelected}
          toast={toast}
          setCompletedModal={setShow}
          documentacionsCarga={documentacionsCarga}
          formaPagos={formaPagos}
          isAdmin={validarPermiso("editar_retiro")}
          searchCliente={searchCliente}
          validarRut={validarRut}
          detRetiro={detRetiro}
          listOffices={listOffices}
          tipoEnvios={tipoEnvios}
        />
      )}

      {anularModal && (
        <AnularRetiro
          show={anularModal}
          numero_documento={numero_retiro}
          handleAnular={handleAnular}
          onSubmit={onSubmit}
          register={register}
          handleSubmit={handleSubmit}
          errors={errors}
          loading={loading}
          numeroSelect={numeroSelect}
        />
      )}
      <ToastContainer />

      {isLoadingDetRetiro && (
        <ModalSpinner
          spinnerShow={isLoadingDetRetiro}
          description="Buscando el detalle del retiro..."
        />
      )}
    </Layout>
  );
};

export default CompletedRetiro;
