import { Fragment, useEffect, useState } from "react";
import { Form, InputGroup } from "react-bootstrap";
import { useEditRetiro } from "hooks/Retiro/useEditRetiro";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import ModalSelectNroCuenta from "../../Orders/Clientes/ModalSelectNroCuenta";
import MaskedInput from "react-text-mask";
import RutTextMask from "rut-text-mask";
import {
  validateOnlyRUT,
  validateOnlyLetter,
  onPaste,
  validateOnlyNumber,
} from "utils/validationInput";
import { phone } from "phone";
import { getPhone } from "utils/globals";
import { useTransportOrderOffice } from "hooks/Orders/useTransportOrderOffice";
import { usePermission } from "hooks/Permission/usePermission";
const RUT_MESON = process.env.REACT_APP_CLIENTE_MESON;

const FormRemitente = ({
  detRetiro,
  isAdmin,
  searchCliente,
  validarRut,
  toast,
  formaPagos,
  listOffices,
}) => {
  const dispatch = useDispatch();
  const {
    onSubmit,
    register,
    handleSubmit,
    control,
    setValue,
    loading,
    errors,
    getValues,
  } = useEditRetiro(detRetiro);

  const { actions, state } = useTransportOrderOffice();
  const { validarPermiso } = usePermission();

  const { loadOffices, setOfficeOrigin } = actions;

  const { officeOrigin } = state;

  const [openCloseCta, setOpenCloseCta] = useState(false);
  const [clienteSelected, setClienteSelected] = useState(false);
  const { companyDataBasic } = useSelector((state) => state.companies);

  useEffect(() => {
    if (listOffices?.length > 0 && detRetiro?.sucursal_id) {
      const resultOrigin = loadOffices(
        undefined,
        "origen",
        detRetiro?.sucursal_id,
        listOffices
      );
      setOfficeOrigin(resultOrigin);
    }

    setValue("sucursal_origen_id", detRetiro?.sucursal_id);
    setValue("comuna_origen_id", detRetiro?.comuna_id);
    setValue("forma_pago_id", detRetiro?.forma_pago_id);
    setValue("rut_remitente", detRetiro?.rut_remitente);
    setValue(
      "telefono_remitente",
      getPhone(detRetiro?.telefono_remitente, companyDataBasic)
    );
    setValue("email_remitente", detRetiro?.email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOffices, detRetiro?.sucursal_id]);

  const onKeyDownRemitente = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      await searchClienteRemitente();
    }
  };

  const onBlurHandle = async (event) => {
    if (event.target.value.length !== 0) {
      await searchClienteRemitente();
    }
  };

  const searchClienteRemitente = async () => {
    let search;
    const rut = document.getElementById("rut_remitente").value;
    const isValid = validarRut(rut);
    if (isValid.status) {
      search = { search_type: "rut_cliente", value: rut };
    } else {
      toast.error(isValid.message, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }

    if (rut === RUT_MESON) {
      toast.error("Ese RUT es inválido", {
        position: toast.POSITION.TOP_RIGHT,
      });

      setValue("rut_remitente", detRetiro?.rut_remitente);
      setValue("razon_social_remitente", detRetiro?.razon_social_remitente);
      setValue("codigo_interno", detRetiro?.codigo_interno);
      setValue("email", detRetiro?.email);
      setValue(
        "telefono_remitente",
        getPhone(detRetiro?.telefono_remitente, companyDataBasic)
      );
      return false;
    }

    const result = await dispatch(searchCliente(search));
    const { status, data } = result;
    if (status) {
      if (data?.numero_cuentas?.length === 1) {
        setValue("rut_remitente", data.rut);
        setValue("razon_social_remitente", data.full_name);
        setValue(
          "telefono_remitente",
          getPhone(data?.phone_number, companyDataBasic)
        );

        setValue("email_remitente", data?.email);
        setValue("codigo_interno", data?.account_numbers[0]?.internal_number);
        setValue("direccion_retiro", data.address);
      } else if (data?.account_numbers?.length > 0) {
        setClienteSelected(data);
        setOpenCloseCta(true);
        //setValue("razon_social_remitente", data.cliente.nombre_completo)
        //document.getElementById("razon_social_remitente").readOnly=true
      } else {
        setValue("razon_social_remitente", data.full_name);
        setValue("codigo_interno", "");
        setValue(
          "telefono_remitente",
          getPhone(data?.phone_number, companyDataBasic)
        );

        setValue("email_remitente", data?.email);
        setValue("direccion_retiro", data?.address);
      }
    } else {
      setValue("razon_social_remitente", "");
      setValue("telefono_remitente", "");
      setValue("codigo_interno", "");
      setValue("email_remitente", "");
      setValue("direccion_retiro", "");
      document.getElementById("razon_social_remitente").readOnly = false;
    }
  };

  const handleOpenCloseCta = () => {
    setOpenCloseCta(!openCloseCta);
    setValue("razon_social_remitente", "");
    document.getElementById("razon_social_remitente").readOnly = true;
    setValue("telefono_remitente", "");
    setValue("email_remitente", "");
    setValue("codigo_interno", "");
  };

  const selectCodeClient = (codigo) => {
    setValue("rut_remitente", clienteSelected.rut);
    setValue("razon_social_remitente", clienteSelected.full_name);
    document.getElementById("razon_social_remitente").readOnly = true;
    setValue(
      "telefono_remitente",
      getPhone(clienteSelected?.phone_number, companyDataBasic)
    );

    setValue("email_remitente", clienteSelected?.email);
    setValue("direccion_retiro", clienteSelected.address);
    //setHabCliente(true)

    //setValue("codigo_interno", "si")
    //setCodigo(true)
    setValue("codigo_interno", codigo);
    setOpenCloseCta(!openCloseCta);
  };

  const cambiarFormaPago = (event) => {
    if (parseInt(event.target.value) === 3) {
      if (getValues("codigo_interno") === "") {
        toast.error("El cliente no es cuenta correntista", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setValue("forma_pago_id", "");
        return false;
      }
    }
  };

  const validatePhone = (value) => {
    if (
      !phone(`${companyDataBasic?.country?.code}${value}`, {
        country: companyDataBasic?.country?.iso,
        validateMobilePrefix: false,
      }).isValid
    ) {
      return "El número ingresado no es válido";
    }
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-6">
          <p className="bg-warn-blue mb-0 fw-600 text-center p-0 mb-4">
            Remitente
          </p>
          <form className="row mt-4 mb-4">
            <div className="col-sm-12">
              <h6 className="mb-4">Datos Personales</h6>
              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <Form.Group controlId="rut_remitente">
                    <Form.Label>RUT</Form.Label>
                    <Controller
                      render={(props) => (
                        <MaskedInput
                          mask={RutTextMask}
                          className="form-control text-uppercase"
                          id="rut_remitente"
                          name="rut_remitente"
                          value={props.value}
                          maxLength={12}
                          readOnly={!isAdmin}
                          defaultValue={detRetiro?.rut_remitente || ""}
                          onChange={(value) => {
                            props.onChange(value);
                          }}
                          onKeyDown={(event) => onKeyDownRemitente(event)}
                          onBlur={(event) => onBlurHandle(event)}
                          onKeyPress={(event) => validateOnlyRUT(event)}
                        />
                      )}
                      control={control}
                      name="rut_remitente"
                      rules={{
                        required: { value: true, message: "Requerido" },
                      }}
                    />
                    {/**<Form.Control 
											type="text" 
											name="rut_remitente"
											readOnly={!isAdmin}
											maxLength={14}
											defaultValue={detRetiro?.rut_remitente || ""}
											ref={register({required: {value: true, message: 'Requerido'}})}
											onKeyDown={(event) => onKeyDownRemitente(event)}
											onBlur={(event) => onBlurHandle(event)}
										/> */}
                    {errors.rut_remitente && (
                      <Form.Text className="text-danger">
                        {errors?.rut_remitente?.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
                <div className="col-sm-12 col-md-8 col-lg-8">
                  <Form.Group controlId="razon_social_remitente">
                    <Form.Label>Remitente</Form.Label>
                    <Form.Control
                      type="text"
                      name="razon_social_remitente"
                      readOnly={!isAdmin}
                      maxLength={160}
                      defaultValue={detRetiro?.razon_social_remitente || ""}
                      ref={register({
                        required: { value: true, message: "Requerido" },
                      })}
                      className="text-uppercase"
                      onKeyPress={(event) => validateOnlyLetter(event)}
                      onPaste={(event) => onPaste(event)}
                    />
                    {errors.razon_social_remitente && (
                      <Form.Text className="text-danger">
                        {errors?.razon_social_remitente?.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <Form.Group controlId="codigo_interno">
                    <Form.Label>Cod. interno</Form.Label>
                    <Form.Control
                      type="text"
                      name="codigo_interno"
                      maxLength={4}
                      readOnly={true}
                      defaultValue={detRetiro?.codigo_interno || ""}
                      ref={register({
                        maxLength: { value: 4, message: "Mínimo 4 dígitos" },
                      })}
                      autoComplete="off"
                      onKeyPress={(event) => validateOnlyNumber(event)}
                      onPaste={(event) => onPaste(event)}
                    />
                    {errors.codigo_interno && (
                      <Form.Text className="text-danger">
                        {errors?.codigo_interno?.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
                <div className="col-sm-12 col-md-8 col-lg-8">
                  <Form.Group controlId="email_remitente">
                    <Form.Label>Teléfono</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">
                          {companyDataBasic?.country?.code}
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        type="text"
                        name="telefono_remitente"
                        defaultValue={detRetiro?.telefono_remitente || ""}
                        maxLength={15}
                        disabled={
                          companyDataBasic?.country?.code === "" ? true : false
                        }
                        ref={register({
                          minLength: { value: 9, message: "Mínimo 9 dígitos" },
                          required: { value: true, message: "Requerido" },
                          //eslint-disable-next-line
                          pattern: {
                            value: /^([0-9]+)$/,
                            message: "Teléfono inválido",
                          },
                          validate: validatePhone,
                        })}
                        autoComplete="off"
                        onKeyPress={(event) => validateOnlyNumber(event)}
                        onPaste={(event) => onPaste(event)}
                        readOnly={!isAdmin}
                      />
                    </InputGroup>
                    {errors.telefono_remitente && (
                      <Form.Text className="text-danger">
                        {errors?.telefono_remitente?.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-md-4 col-lg-4">
                  <Form.Group controlId="telefono_remitente">
                    <Form.Label>Forma de pago</Form.Label>
                    <Controller
                      control={control}
                      name="forma_pago_id"
                      rules={{
                        required: { value: true, message: "Requerido" },
                      }}
                      render={(props) => (
                        <select
                          value={props.value}
                          className="form-control"
                          id="forma_pago_id"
                          name="forma_pago_id"
                          onChange={(value) => {
                            props.onChange(value);
                            setValue("forma_pago_id", value.target.value);
                            cambiarFormaPago(value);
                          }}
                          disabled={!isAdmin}
                        >
                          <option value="">Seleccione</option>
                          {formaPagos.length > 0 &&
                            formaPagos
                              ?.filter((item) => item.description !== "Contado")
                              .map((pago) => (
                                <option key={pago.id} value={pago.id}>
                                  {pago.description}
                                </option>
                              ))}
                        </select>
                      )}
                      defaultValue={detRetiro?.forma_pago_id}
                    />
                    {errors.forma_pago_id && (
                      <Form.Text className="text-danger">
                        {errors?.forma_pago_id?.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
                <div className="col-sm-12 col-md-8 col-lg-8">
                  <Form.Group controlId="email_remitente">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      type="text"
                      name="email_remitente"
                      readOnly={!isAdmin}
                      maxLength={255}
                      defaultValue={detRetiro?.email || ""}
                      ref={register({
                        //eslint-disable-next-line
                        pattern: {
                          value:
                            /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, //eslint-disable-line
                          message: "Email inválido",
                        },
                        required: { value: true, message: "Requerido" },
                      })}
                      className="text-lowercase"
                      onPaste={(event) => onPaste(event)}
                    />
                    {errors.email_remitente && (
                      <Form.Text className="text-danger">
                        {errors?.email_remitente?.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </div>
              </div>
            </div>
            <div className="col-sm-12">
              <h6 className="mb-4 mt-3">Dirección de retiro</h6>
              <div className="row">
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <Form.Group controlId="sucursal_origen_id">
                    <Form.Label>Sucursal</Form.Label>
                    {!isAdmin ? (
                      <Form.Control
                        type="text"
                        name="sucursal_origen_name"
                        readOnly={!isAdmin}
                        defaultValue={detRetiro?.sucursal_name || ""}
                        ref={register({
                          required: { value: true, message: "Requerido" },
                        })}
                      />
                    ) : (
                      <Controller
                        control={control}
                        name="sucursal_origen_id"
                        rules={{
                          required: { value: true, message: "Requerido" },
                        }}
                        render={(props) => {
                          return (
                            <select
                              value={props.value}
                              className="form-control"
                              id="sucursal_origen_id"
                              onChange={(event) => {
                                props.onChange(event.target.value);
                                const result = loadOffices(
                                  event,
                                  "origin",
                                  "",
                                  listOffices
                                );
                                setOfficeOrigin(result);
                              }}
                              disabled={!validarPermiso("cambiar_sucursal")}
                            >
                              <option value="">Seleccione</option>
                              {listOffices.length > 0 &&
                                listOffices.map((city) => (
                                  <option key={city.id} value={city.id}>
                                    {city.name}
                                  </option>
                                ))}
                            </select>
                          );
                        }}
                        defaultValue={detRetiro?.sucursal_id}
                      />
                    )}
                  </Form.Group>
                </div>
                <div className="col-sm-12 col-md-6 col-lg-6">
                  <Form.Group controlId="comuna_origen_id">
                    <Form.Label>Comuna</Form.Label>
                    {!isAdmin ? (
                      <Form.Control
                        type="text"
                        name="comuna_origen_name"
                        readOnly={!isAdmin}
                        defaultValue={detRetiro?.comuna_name || ""}
                        ref={register({
                          required: { value: true, message: "Requerido" },
                        })}
                      />
                    ) : (
                      <Controller
                        control={control}
                        name="comuna_origen_id"
                        rules={{
                          required: { value: true, message: "Requerido" },
                        }}
                        render={(props) => (
                          <select
                            value={props.value}
                            className="form-control"
                            id="comuna_origen_id"
                            name="comuna_origen_id"
                            onChange={(value) => {
                              props.onChange(value);
                              setValue("comuna_origen_id", value.target.value);
                            }}
                          >
                            <option value="">Seleccione</option>
                            {officeOrigin.length > 0 &&
                              officeOrigin.map((comuna) => (
                                <option key={comuna.id} value={comuna.id}>
                                  {comuna.name}
                                </option>
                              ))}
                          </select>
                        )}
                        defaultValue={detRetiro?.comuna_id}
                      />
                    )}
                  </Form.Group>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <Form.Group controlId="direccion_retiro">
                    <Form.Label>Dirección</Form.Label>
                    <textarea
                      type="text"
                      name="direccion_retiro"
                      className="form-control text-uppercase"
                      readOnly={!isAdmin}
                      rows={3}
                      cols={3}
                      maxLength={255}
                      defaultValue={detRetiro?.direccion_retiro || ""}
                      ref={register({
                        required: { value: true, message: "Requerido" },
                      })}
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
            {isAdmin && (
              <div className="col-sm-12 text-right">
                <button
                  type="button"
                  className="btn btn-warning"
                  onClick={handleSubmit((data) => onSubmit(data))}
                  disabled={loading}
                >
                  {loading ? "Editando..." : "Guardar cambios"}
                </button>
              </div>
            )}
          </form>
        </div>
        <div className="col-6">
          <p className="bg-warn-blue mb-0 fw-600 text-center pr-0 mb-4">
            Estados
          </p>
          <div className="table-responsive">
            <table className="table table-bordered table-sm bg-white table-retiro">
              <thead className="bg-warn-blue">
                <tr>
                  <th className="text-center">Fecha/Hora</th>
                  <th className="text-center">Estado</th>
                  <th className="text-center">Usuario</th>
                  <th className="text-center" style={{ width: "150px" }}>
                    Observación
                  </th>
                </tr>
              </thead>
              <tbody>
                {detRetiro?.estados?.length > 0 &&
                  detRetiro?.estados?.map((item, key) => (
                    <tr key={key}>
                      <td>{item.fecha_hora}</td>
                      <td>{item.estado}</td>
                      <td>{item.usuario}</td>
                      <td>{item.observacion}</td>
                    </tr>
                  ))}
                {detRetiro?.estados?.length === 0 && (
                  <tr>
                    <td colSpan={4}>No tiene estados asignado</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {openCloseCta && (
        <ModalSelectNroCuenta
          handleOpenCloseCta={handleOpenCloseCta}
          openCloseCta={openCloseCta}
          cliente={clienteSelected}
          selectCodeClient={selectCodeClient}
        />
      )}
    </Fragment>
  );
};

export default FormRemitente;
