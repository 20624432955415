import React, { useState, useEffect, forwardRef } from "react";
import { Form, Button, Col, InputGroup, FormControl } from "react-bootstrap";
import DatePicker, { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";

import { useDispatch, useSelector } from "react-redux";
import {
  filteredOrdenesRange,
  clearTitleEstado,
  cleanListadoOrden,
  calcularTotalSelected,
} from "actions/transportOrdersAction";
import { updateTitleBtn, isClearNomina } from "actions/payrollAction";
import { usePermission } from "hooks/Permission/usePermission";
import { isAfter, getDateYMD, isBefore, isSame } from "utils/formatFecha";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { BiCalendar } from "react-icons/bi";

registerLocale("es", es);
const GlobalSearch = () => {
  const dispatch = useDispatch();
  const { validarPermiso } = usePermission();
  const { register, handleSubmit, errors, getValues, setValue } = useForm();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [titleSucursal, setTitleSucursal] = useState({
    sucursal_origen: "Sucursal origen",
    sucursal_destino: "Sucursal destino",
  });
  const [isValideDestion, setIsValideDestion] = useState(true);

  const { estados } = useSelector((state) => state.transportOrders);

  const { listOffices } = useSelector((state) => state.offices);
  const { city_id } = useSelector((state) => state.users);

  const verificarEstadoSucursal = (id) => {
    if (
      id === "en_transito" ||
      id === "pendiente_de_entrega" ||
      id === "pendiente_de_despacho_sucursal"
    ) {
      setTitleSucursal({
        sucursal_origen: "Sucursal destino",
        sucursal_destino: "Sucursal origen",
      });
      return {
        sucursal_origen: document.getElementById("sucursal_destino_id").value,
        sucursal_destino: document.getElementById("sucursal_origen_id").value,
      };
    } else if (id === "pendiente_de_despacho" || id === "entregada") {
      setTitleSucursal({
        sucursal_origen: "Sucursal origen",
        sucursal_destino: "Sucursal destino",
      });

      return {
        sucursal_origen: document.getElementById("sucursal_origen_id").value,
        sucursal_destino: document.getElementById("sucursal_destino_id").value,
      };
    }
  };

  const onSubmit = async (data, e) => {
    dispatch(cleanListadoOrden(true));
    dispatch(calcularTotalSelected());

    const id = getValues("estado_id");

    if (id === "en_transito") {
      dispatch(updateTitleBtn("RECIBIDO SUCURSAL", false));
      dispatch(clearTitleEstado(""));
    } else if (id === "pendiente_de_despacho_sucursal") {
      dispatch(updateTitleBtn("GENERAR NOMINA", true));
      dispatch(clearTitleEstado("CLIENTE"));
    } else if (id === "pendiente_de_despacho") {
      dispatch(updateTitleBtn("GENERAR NOMINA", true));
      dispatch(clearTitleEstado(""));
    } else if (id === "pendiente_de_entrega") {
      dispatch(updateTitleBtn("", false));
      dispatch(clearTitleEstado(""));
    }

    const sucursales_info = verificarEstadoSucursal(id);

    let estadoID = estados.find((item) => item.slug === id);

    const information = {
      ...data,
      estado_id: estadoID.id,
      fecha_desde: document.getElementById("fecha_desde").value,
      fecha_hasta: document.getElementById("fecha_hasta").value,
      sucursal_origen_id: sucursales_info.sucursal_origen,
      sucursal_destino_id: sucursales_info.sucursal_destino,
      check: false,
      search_multiple: true,
      generarPDF: false,
    };
    dispatch(isClearNomina());
    const response = await dispatch(
      filteredOrdenesRange(information, "nomina")
    );

    if (!response?.status) {
      toast.error("No hay registros disponibles", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  useEffect(() => {
    if (city_id !== "") {
      setValue("sucursal_origen_id", city_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOffices]);

  const handleEstado = (event) => {
    if (event.target.value === "en_transito") {
      setIsValideDestion((isValideDestion) => false);
    } else {
      setIsValideDestion((isValideDestion) => true);
    }
    verificarEstadoSucursal(event.target.value);
  };

  const validateStartDateBlur = (event) => {
    const dateStart = getDateYMD(dayjs(startDate).format("DD/MM/YYYY"));

    if (dateStart.error) {
      toast.error(dateStart.message, { position: toast.POSITION.TOP_RIGHT });
      setStartDate(new Date());
      return false;
    }

    if (
      !isSame(dateStart.dateSelected, endDate) &&
      isAfter(dateStart.dateSelected, endDate)
    ) {
      toast.error("La fecha inicial no puede ser superior a la fecha final", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setStartDate(new Date());
      setEndDate(new Date());
      return false;
    }
  };

  const validateEndDateBlur = (event) => {
    const dataEnd = getDateYMD(dayjs(endDate).format("DD/MM/YYYY"));

    if (dataEnd.error) {
      toast.error(dataEnd.message, { position: toast.POSITION.TOP_RIGHT });
      setEndDate(new Date());
      return false;
    }

    if (
      !isSame(dataEnd.dateSelected, dayjs(startDate).format("YYYY-MM-DD")) &&
      isBefore(dataEnd.dateSelected, startDate)
    ) {
      toast.error("La fecha final no puede ser inferior a la fecha inicial", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setEndDate(new Date());
      return false;
    }
  };

  const ExampleCustomInputFrom = forwardRef(({ value, onClick }, ref) => (
    <InputGroup className="mb-3">
      <FormControl name="fecha_orden_c" value={value} readOnly />
      <InputGroup.Append>
        <Button variant="warning" size="sm" onClick={() => onClick()} ref={ref}>
          <BiCalendar />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  ));

  const ExampleCustomInputTo = forwardRef(({ value, onClick }, ref) => (
    <InputGroup className="mt-4">
      <FormControl name="fecha_orden_c" value={value} readOnly />
      <InputGroup.Append>
        <Button variant="warning" size="sm" onClick={() => onClick()} ref={ref}>
          <BiCalendar />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  ));
  return (
    <Col xs={12}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        id="frmBusquedaGlobal"
        role="form"
      >
        <Form.Row>
          <Col lg md={4} sm={6} xs={4}>
            <Form.Group as={Col} controlId="fecha_desde" className="pr-0">
              <Form.Label>Periodo Fecha</Form.Label>
              <DatePicker
                type="text"
                dateFormat="dd/MM/yyyy"
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                className="form-control"
                name="fecha_desde"
                id="fecha_desde"
                ref={register}
                locale="es"
                maxDate={new Date()}
                onCalendarClose={() => validateStartDateBlur()}
                autoComplete="off"
                customInput={<ExampleCustomInputFrom />}
              />
            </Form.Group>
          </Col>
          <Col lg md={4} sm={6} xs={4}>
            <Form.Group as={Col} controlId="fecha_hasta" className="pr-1 pl-0">
              <DatePicker
                type="text"
                dateFormat="dd/MM/yyyy"
                selected={endDate}
                onChange={(date) => setEndDate(date)}
                className="form-control mt-4"
                name="fecha_hasta"
                id="fecha_hasta"
                ref={register}
                locale="es"
                maxDate={new Date()}
                onCalendarClose={() => validateEndDateBlur()}
                autoComplete="off"
                customInput={<ExampleCustomInputTo />}
              />
            </Form.Group>
          </Col>
          <Col lg md={4} sm={6} xs={4}>
            <Form.Group as={Col} controlId="estado_id" className="pr-1 pl-0">
              <Form.Label>Estado</Form.Label>
              <Form.Control
                as="select"
                name="estado_id"
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
                onChange={(event) => handleEstado(event)}
              >
                {estados.length > 0 &&
                  estados.map((estado) => (
                    <option key={estado.id} value={estado.slug}>
                      {estado.description}
                    </option>
                  ))}
              </Form.Control>
              {errors.estado_id && (
                <Form.Text className="text-danger">
                  {errors?.estado_id?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col lg md={4} sm={6} xs={4}>
            <Form.Group
              as={Col}
              controlId="sucursal_origen_id"
              className="pr-1 pl-0"
            >
              <Form.Label>{titleSucursal.sucursal_origen}</Form.Label>
              <Form.Control
                as="select"
                name="sucursal_origen_id"
                ref={register({
                  required: {
                    value: true,
                    message: "Requerido",
                  },
                })}
                disabled={!validarPermiso("cambiar_sucursal")}
              >
                <option value="">Seleccione</option>
                {listOffices.length > 0 &&
                  listOffices.map((sucursal) => (
                    <option key={sucursal.id} value={sucursal.id}>
                      {sucursal.name}
                    </option>
                  ))}
              </Form.Control>
              {errors.sucursal_origen_id && (
                <Form.Text className="text-danger">
                  {errors?.sucursal_origen_id?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col lg md={4} sm={6} xs={4}>
            <Form.Group
              as={Col}
              controlId="sucursal_destino_id"
              className="pr-1 pl-0"
            >
              <Form.Label>{titleSucursal.sucursal_destino}</Form.Label>
              <Form.Control
                as="select"
                name="sucursal_destino_id"
                className="sucursal_des"
                ref={register({
                  required: {
                    value: isValideDestion,
                    message: "Requerido",
                  },
                })}
              >
                <option value="">Seleccione</option>
                {listOffices.length > 0 &&
                  listOffices.map((sucursal) => (
                    <option key={sucursal.id} value={sucursal.id}>
                      {sucursal.name}
                    </option>
                  ))}
              </Form.Control>
              {errors.sucursal_destino_id && (
                <Form.Text className="text-danger">
                  {errors?.sucursal_destino_id?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>

          <Col>
            <Form.Group as={Col} className="mt-3 pl-0">
              <Button variant="warning" type="submit" block>
                MOSTRAR
              </Button>
            </Form.Group>
          </Col>
        </Form.Row>
      </Form>
    </Col>
  );
};

export default GlobalSearch;
