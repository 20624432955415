import { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import {
  addCompany,
  editCompany,
  searchCompanyById,
} from "actions/admin/companyAction";
import { validarRut } from "utils/rut";
import { phone } from "phone";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { getListCountry } from "actions/admin/countryAction";
import { useHistory } from "react-router-dom";
import { getPhone } from "utils/globals";

export const useFormCompany = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [checkFact, setCheckFact] = useState("si");
  const [file, setFile] = useState([]);
  const [codeCountry, setCodeCountry] = useState("");
  const [isValidLogo, setIsValidLogo] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [dataCompany, setDataCompany] = useState(null);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections?.length > 0) {
      fileRejections?.forEach((element) => {
        element?.errors?.forEach((err) => {
          if (err?.code === "file-too-large") {
            toast.error("El tamaño máximo del logo es de 120kb", {
              position: toast.POSITION.TOP_RIGHT,
            });
            return false;
          } else if (err?.code === "file-invalid-type") {
            toast.error(
              "Solo se acepta archivos con formato .png",
              ".jpg",
              ".webp",
              { position: toast.POSITION.TOP_RIGHT }
            );
            return false;
          }
        });
      });
    }
    if (acceptedFiles.length > 0) {
      setIsDisabled(true);
      setFile(acceptedFiles);
    }
    // Do something with the files
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "image/png": [".png", ".jpg", ".webp"],
    },
    maxFiles: 1,
    disabled: isDisabled,
    maxSize: 120000,
    onDrop,
  });

  const { register, handleSubmit, errors, reset, control, setValue } = useForm({
    defaultValues: {
      rut: "",
      legal_name: "",
      type_customer: "",
      name_legal_agent: "",
      surname_legal_agent: "",
      email_legal_agent: "",
      address: "",
      country_id: "",
      activity_id: "",
      invoice: false,
      logo: "",
      rut_legal_agent: "",
      phone_legal_agent: "",
    },
  });

  const { companyData } = useSelector((state) => state.companies);
  const { listCountries } = useSelector((state) => state.countries);
  const { companyDataBasic } = useSelector((state) => state.companies);

  const onSubmit = async (data, e) => {
    setIsLoading(true);
    let response;

    const isValid = validarRut(data.rut);
    if (!isValid.status) {
      toast.error(`${isValid.message} de la casa matriz`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
      return false;
    }

    const isValidRep = validarRut(data.rut_legal_agent);
    if (!isValidRep.status) {
      toast.error(`${isValidRep.message} del representante`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setIsLoading(false);
      return false;
    }

    /**let text_pais = "";
    const paisSelected = document.getElementById("country_id");
    if (paisSelected && paisSelected.options[paisSelected.selectedIndex]) {
      text_pais = paisSelected.options[paisSelected.selectedIndex].text;
    }

    let text_giro = "";
    const giroSelected = document.getElementById("activity_id");

    if (giroSelected && giroSelected.options[giroSelected.selectedIndex]) {
      text_giro = giroSelected.options[giroSelected.selectedIndex].text;
    }
**/
    data.email_legal_agent = data.email_legal_agent?.toLowerCase();

    let formData = new FormData();
    formData.append("rut", data.rut);
    formData.append("legal_name", data.legal_name);
    formData.append("type_customer", data.type_customer);
    formData.append("name_legal_agent", data.name_legal_agent);
    formData.append("surname_legal_agent", data.surname_legal_agent);
    formData.append("email_legal_agent", data.email_legal_agent);
    formData.append("address", data.address);
    formData.append("country_id", document.getElementById("country_id").value);
    formData.append("activity", data.activity);
    formData.append("invoice", checkFact === "si" ? true : false);
    formData.append("is_activo", true);

    if (file.length > 0 && !Object.keys(file[0]).includes("updated")) {
      formData.append("logo", file[0]);
      formData.append("change_photo", true);
    } else {
      formData.append("change_photo", false);
    }
    formData.append("rut_legal_agent", data.rut_legal_agent);
    formData.append(
      "phone_legal_agent",
      `${codeCountry}${data.phone_legal_agent}`
    );

    /**const otherData = {
      ...data,
      country_id: document.getElementById("country_id").value,
      activity_id: document.getElementById("activity_id").value,
      pais: {
        id: document.getElementById("country_id").value,
        nombre: text_pais,
      },
      giro: {
        id: document.getElementById("activity_id").value,
        nombre: text_giro,
      },
      logo: companyData?.logo,
    };**/

    if (dataCompany !== null) {
      response = await dispatch(editCompany(formData, dataCompany?.id));
    } else {
      response = await dispatch(addCompany(formData));
    }

    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      window.scrollTo(0, 0);
      setIsLoading(false);

      setTimeout(() => {
        history.push("/admin/companies");
      }, 1000);
    } else {
      setIsLoading(false);
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
  };

  const onFileAdded = (event) => {
    setIsDisabled(true);
  };

  const cargarActivities = async (e, valor) => {
    const id = e !== undefined ? e.target.value : valor;
    const countrySelected = listCountries.find(
      (item) => item.id === Number(id)
    );

    setCodeCountry("");
    if (id !== "" && countrySelected) {
      setCodeCountry(countrySelected.phone_code);
      setValue("phone_legal_agent", "");
      return true;
    }

    return false;
  };

  const validatePhone = (value) => {
    const country = listCountries.find(
      (item) => item?.phone_code === codeCountry
    );
    if (
      !phone(`${codeCountry}${value}`, {
        country: country?.iso_code,
        validateMobilePrefix: false,
      }).isValid
    ) {
      return "El número ingresado no es válido";
    }
  };

  const setDataInitialCompany = async (companyId) => {
    await dispatch(getListCountry("active"));
    let data = companyData;

    if (companyId !== null) {
      if (companyData === null) {
        const response = await dispatch(searchCompanyById(companyId));
        data = response?.data;
      }
    }

    if (data !== null) {
      //
      setIsValidLogo(false);
      setFile([{ path: data?.logo, updated: true }]);
      reset({
        rut: data?.rut ?? "",
        legal_name: data?.legal_name ?? "",
        type_customer: data?.type_customer ?? "",
        name_legal_agent: data?.name_legal_agent ?? "",
        surname_legal_agent: data?.surname_legal_agent ?? "",
        email_legal_agent: data?.email_legal_agent ?? "",
        address: data?.address ?? "",
        country_id: data?.country_id ?? "",
        activity: data?.activity ?? "",
        invoice: data?.invoice ?? false,
        rut_legal_agent: data?.rut_legal_agent ?? "",
        phone_legal_agent:
          getPhone(data?.phone_legal_agent, companyDataBasic) ?? "",
      });

      setCheckFact(data?.invoice ? "si" : "no");
      setCodeCountry(data?.country?.phone_code ?? "");
      setValue("phone_legal_agent", data?.phone_legal_agent);
      //setCodePais("");
      //setGiros((giros) => result.data ?? []);
      /*const findGiro = result?.data?.find(
        (item) => item?.id === data?.activity_id
      );

      setValue("activity_id", findGiro?.id ?? "");**/
      setValue(
        "phone_legal_agent",
        getPhone(data?.phone_legal_agent, companyDataBasic) ?? ""
      );
    }

    setDataCompany(companyId !== null ? data ?? null : null);

    /**if (data !== null) {
      setFile([{ path: data?.logo, updated: true }]);
      setIsValidLogo(false);
      reset({
        rut: data?.rut ?? "",
        legal_name: data?.legal_name ?? "",
        type_customer: data?.type_customer ?? "",
        name_legal_agent: data?.name_legal_agent ?? "",
        surname_legal_agent: data?.surname_legal_agent ?? "",
        email_legal_agent: data?.email_legal_agent ?? "",
        address: data?.address ?? "",
        country_id: data?.country_id ?? "",
        activity_id: data?.activity_id ?? "",
        invoice: data?.invoice ?? false,
        rut_legal_agent: data?.rut_legal_agent ?? "",
        phone_legal_agent: data?.phone_legal_agent ?? "",
      });
      

      setGiros((giros) => []);
      if (result?.status) {
        setGiros((giros) => result.data ?? []);
      }
      //setGiros((giros) => result.data ?? []);
      /**const findGiro = result?.data?.find((item) => item?.id === data?.activity_id);

      setValue("activity_id", findGiro?.id ?? ""); */
    //}
  };

  const setResetPhone = (data, companyBasic) => {
    setValue(
      "phone_legal_agent",
      getPhone(data?.phone_legal_agent, companyBasic) ?? ""
    );
  };

  const onDeleteFile = (fileToRemove) => {
    if (fileToRemove !== null) {
      setFile((prevFiles) =>
        prevFiles.filter((file) => file.name !== fileToRemove.name)
      );
    } else {
      setFile([]);
      setIsValidLogo(true);
    }

    setIsDisabled(false);
  };

  const validateRUT = (value) => {
    const isValid = validarRut(value);
    if (!isValid.status) {
      return isValid.message;
    }
  };

  const validateMail = (value) => {
    const partEmail = value.split("@");

    if (partEmail?.length === 2) {
      const regex = /^.{3,}$/;

      if (!regex.test(partEmail[1])) {
        return "El e-mail es inválido";
      }

      if (!regex.test(partEmail[0])) {
        return "El e-mail es inválido";
      }

      const partDomain = partEmail[1]?.split(".");
      if (partDomain?.length === 2) {
        if (!regex.test(partDomain[0])) {
          return "El e-mail es inválido";
        }
      }
    }
  };

  const formElement = {
    isLoading,
    register,
    handleSubmit,
    errors,
    control,
    setValue,
    onSubmit,
  };

  const actions = {
    setCheckFact,
    onFileAdded,
    cargarActivities,
    validatePhone,
    setDataInitialCompany,
    getRootProps,
    getInputProps,
    onDeleteFile,
    validateRUT,
    validateMail,
    setResetPhone,
  };

  const state = {
    checkFact,
    file,
    codeCountry,
    isValidLogo,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
    listCountries,
    dataCompany,
    companyDataBasic,
  };

  return {
    formElement,
    actions,
    state,
  };
};
