import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { updateOrdenPorPagar } from "actions/transportOrdersAction";
import { useDispatch } from "react-redux";
import { formatNumberCurrency } from "utils/validationInput";

const TbodyOrden = ({
  index,
  numero_orden,
  orden_flete_id,
  total,
  forma_pago,
  register,
  rut_remitente,
  rut_destinatario,
  documentacion_id_pago,
  documentacions,
  documentacion_id_pago_id,
  documentacion_id,
  toast,
  setValue,
}) => {
  const dispatch = useDispatch();

  const handleChange = (event, orden_flete_id) => {
    const param = event.target.name.split(".")[1];
    const valor = event.target.value;

    dispatch(updateOrdenPorPagar(orden_flete_id, param, valor));

    /**if (!response) {
      event.target.value = "";
      toast.error("Seleccione solo un tipo de documento", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }**/
  };

  useEffect(() => {
    setValue(`ordenes[${index}].documentacion_id`, documentacion_id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentacion_id]);

  return (
    <tr key={index}>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].orden_flete_id`}
          value={orden_flete_id}
          ref={register}
        />
        {numero_orden}
      </td>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].total`}
          value={total}
          ref={register}
        />
        <input
          type="hidden"
          name={`ordenes[${index}].rut_remitente`}
          value={rut_remitente}
          ref={register}
        />
        <input
          type="hidden"
          name={`ordenes[${index}].rut_destinatario`}
          value={rut_destinatario}
          ref={register}
        />
        {forma_pago}
      </td>
      <td>
        {typeof documentacion_id_pago === "string" &&
        documentacion_id_pago !== "" ? (
          <>
            <input
              type="hidden"
              name={`ordenes[${index}].documentacion_id`}
              value={documentacion_id_pago_id}
              ref={register}
            />
            {documentacion_id_pago}
          </>
        ) : (
          <Form.Control
            as="select"
            name={`ordenes[${index}].documentacion_id`}
            onChange={(event) => handleChange(event, orden_flete_id)}
            ref={register}
          >
            <option value="">Seleccione</option>
            {documentacions.length > 0 &&
              documentacions.map((documents) => (
                <option key={documents.id} value={documents.id}>
                  {documents.description}
                </option>
              ))}
          </Form.Control>
        )}
      </td>
      <td>$ {formatNumberCurrency(total)}</td>
    </tr>
  );
};

export default TbodyOrden;
