import { useEffect } from "react";
import {
  Form,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
  InputGroup,
} from "react-bootstrap";

import MaskedInput from "react-text-mask";
import RutTextMask from "rut-text-mask";
import { Controller } from "react-hook-form";
import {
  validateOnlyRUT,
  validateOnlyLetter,
  onPaste,
  validateOnlyNumber,
} from "utils/validationInput";
import { useTransportOrderOffice } from "hooks/Orders/useTransportOrderOffice";

const ClienteDestinatario = ({ state, actions, formElement }) => {
  const { infoDestino, tipoEnvios, listOffices, companyDataBasic, isReadOnly } =
    state;

  const {
    onKeyDownDestinatario,
    onBlurHandleDes,
    validatePhone,
    getPhone,
    setIsReadOnly,
  } = actions;

  const { register, errors, setValue, control, reset, getValues } = formElement;

  const { actions: actionsOffice, state: stateOffice } =
    useTransportOrderOffice();

  const { loadOffices, setOfficeOrigin } = actionsOffice;

  const { officeOrigin } = stateOffice;

  useEffect(() => {
    setValue("city_origin_id", "");
    setValue("office_origin_id", "");
    if (infoDestino?.sucursal_id !== null && infoDestino?.sucursal_id !== "") {
      const resultOrigin = loadOffices(
        undefined,
        "origen",
        infoDestino?.sucursal_id,
        listOffices
      );

      setOfficeOrigin(resultOrigin);
      if (getValues("sucursal_id") === "") {
        setValue("city_origin_id", infoDestino?.sucursal_id);
      }
      if (getValues("comuna_id") === "") {
        setValue("office_origin_id", infoDestino?.comuna_id);
      }
    }
    document.getElementById("razon_social").readOnly = true;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOffices, infoDestino?.sucursal_id]);

  useEffect(() => {
    if (Object.keys(infoDestino).length > 0 && infoDestino?.rut !== "") {
      setIsReadOnly(infoDestino?.is_new);
      document.getElementById("razon_social").readOnly = infoDestino?.is_new;

      document.getElementById("rut").value = infoDestino?.rut;
      reset({
        comuna_id: infoDestino.comuna,
        telefono: getPhone(infoDestino?.telefono, companyDataBasic),
        rut: infoDestino?.rut,
        sucursal_id: infoDestino.sucursal_id,
        tipo_envio_id: infoDestino.tipo_envio_id ?? 1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Col lg={6} md={6} sm={12}>
        <h6 className="mb-4">Datos del destinatario</h6>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Form.Group controlId="rut">
              <Form.Label>RUT</Form.Label>
              <OverlayTrigger
                overlay={
                  <Tooltip id="tooltip-largo">
                    Presione <strong>enter</strong> para realizar la búsqueda
                  </Tooltip>
                }
              >
                <Controller
                  render={(props) => (
                    <MaskedInput
                      mask={RutTextMask}
                      className="form-control text-uppercase"
                      id="rut"
                      name="rut"
                      value={props.value}
                      maxLength={12}
                      defaultValue={infoDestino?.rut || ""}
                      onChange={(value) => {
                        props.onChange(value);
                        setValue(value.target.value ?? "");
                      }}
                      onBlur={(event) => onBlurHandleDes(event)}
                      onKeyDown={(event) => onKeyDownDestinatario(event)}
                      onKeyPress={(event) => validateOnlyRUT(event)}
                    />
                  )}
                  control={control}
                  name="rut"
                  rules={{ required: { value: true, message: "Requerido" } }}
                />
                {/**<Form.Control 
						    	type="text" 
						    	name="rut"
								maxLength={14} 
						    	onBlur={(event) => onBlurHandleDes(event)}
						    	onKeyDown={(event) => onKeyDownDestinatario(event)}
						    	defaultValue={infoDestino?.rut || ""}
				                ref={register({required: {value: true, message: 'Requerido'}})}
						    /> */}
              </OverlayTrigger>
              {errors.rut && (
                <Form.Text className="text-danger">
                  {errors?.rut?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Form.Group controlId="razon_social">
              <Form.Label>Destinatario</Form.Label>
              <Form.Control
                type="text"
                name="razon_social"
                maxLength={160}
                defaultValue={infoDestino?.razon_social || ""}
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
                className="text-uppercase"
                onKeyPress={(event) => validateOnlyLetter(event)}
                onPaste={(event) => onPaste(event)}
              />
              {errors.razon_social && (
                <Form.Text className="text-danger">
                  {errors?.razon_social?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Form.Group controlId="telefono">
              <Form.Label>Contacto (Teléfono)</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">
                    {companyDataBasic?.country?.code}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  name="telefono"
                  defaultValue={infoDestino?.telefono || ""}
                  maxLength={15}
                  disabled={
                    companyDataBasic?.country?.code === "" ? true : false
                  }
                  ref={register({
                    minLength: { value: 9, message: "Mínimo 9 dígitos" },
                    required: { value: true, message: "Requerido" },
                    //eslint-disable-next-line
                    pattern: {
                      value: /^([0-9]+)$/,
                      message: "Teléfono inválido",
                    },
                    validate: validatePhone,
                  })}
                  autoComplete="off"
                  onKeyPress={(event) => validateOnlyNumber(event)}
                  onPaste={(event) => onPaste(event)}
                  readOnly={isReadOnly}
                />
              </InputGroup>
              {errors.telefono && (
                <Form.Text className="text-danger">
                  {errors?.telefono?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Form.Group controlId="email">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="text"
                name="email"
                className="text-lowercase"
                defaultValue={infoDestino?.email || ""}
                ref={register({
                  //eslint-disable-next-line
                  pattern: {
                    value:
                      /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, //eslint-disable-line
                    message: "Email inválido",
                  },
                  required: { value: true, message: "Requerido" },
                })}
                onPaste={(event) => onPaste(event)}
                readOnly={isReadOnly}
              />
              {errors.email && (
                <Form.Text className="text-danger">
                  {errors?.email?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Col>
      <Col lg={6} md={6} sm={12}>
        <h6 className="mb-4">Dirección del destinatario</h6>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Form.Group controlId="sucursal_id">
              <Form.Label>Sucursal destino</Form.Label>
              <Controller
                control={control}
                name="sucursal_id"
                rules={{ required: { value: true, message: "Requerido" } }}
                render={(props) => {
                  return (
                    <select
                      value={props.value}
                      className="form-control"
                      id="sucursal_id"
                      onChange={(event) => {
                        props.onChange(event.target.value);
                        const result = loadOffices(
                          event,
                          "origin",
                          "",
                          listOffices
                        );
                        setOfficeOrigin(result);
                      }}
                    >
                      <option value="">Seleccione</option>
                      {listOffices.length > 0 &&
                        listOffices.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                    </select>
                  );
                }}
                defaultValue={""}
              />
              {errors.sucursal_id && (
                <Form.Text className="text-danger">
                  {errors?.sucursal_id?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Form.Group controlId="comuna_id">
              <Form.Label>Comuna destino</Form.Label>
              <Controller
                control={control}
                name="comuna_id"
                rules={{ required: { value: true, message: "Requerido" } }}
                render={(props) => {
                  return (
                    <select
                      value={props.value}
                      className="form-control"
                      id="comuna_id"
                      onChange={(e) => {
                        props.onChange(e.target.value);
                      }}
                    >
                      <option value="">Seleccione</option>
                      {officeOrigin.length > 0 &&
                        officeOrigin.map((city) => (
                          <option key={city.id} value={city.id}>
                            {city.name}
                          </option>
                        ))}
                    </select>
                  );
                }}
                defaultValue={""}
              />
              {errors.comuna_id && (
                <Form.Text className="text-danger">
                  {errors?.comuna_id?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col lg={12} md={12} sm={12}>
            <Form.Group controlId="direccion">
              <Form.Label>Dirección destino</Form.Label>
              <Form.Control
                type="text"
                name="direccion"
                maxLength={255}
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
                className="text-uppercase"
                defaultValue={infoDestino?.direccion || ""}
              />
              {errors.direccion && (
                <Form.Text className="text-danger">
                  {errors?.direccion?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Col>
      <Col xs={3} className="mb-4">
        <Form.Group controlId="tipo_envio_id">
          <Form.Label>Entrega en</Form.Label>
          <Form.Control
            as="select"
            name="tipo_envio_id"
            ref={register({ required: { value: true, message: "Requerido" } })}
          >
            {tipoEnvios.length > 0 &&
              tipoEnvios.map((entrega) => (
                <option key={entrega.id} value={entrega.id}>
                  {entrega.description}
                </option>
              ))}
          </Form.Control>
          {errors.tipo_envio_id && (
            <Form.Text className="text-danger">
              {errors?.tipo_envio_id?.message}
            </Form.Text>
          )}
        </Form.Group>
      </Col>
      <Col xs={9} className="mb-4">
        <Form.Group controlId="observacion">
          <Form.Label>Observación</Form.Label>
          <Form.Control
            type="text"
            name="observacion"
            maxLength={255}
            defaultValue={infoDestino?.observacion || ""}
            ref={register}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default ClienteDestinatario;
