import { useEffect } from "react";
import Layout from "components/Layouts/Layout";
import SearchGlobal from "components/Orders/Recibidas/SearchGlobal";
import TableOrdenRecibidas from "components/Orders/Recibidas/TableOrdenRecibidas";
import { useReceiveOrder } from "hooks/Orders/useReceiveOrder";
import ReactPaginate from "react-paginate";

const ReceiveOrden = () => {
  const {
    onSubmit,
    handleSubmit,
    control,
    isBuscar,
    listOrdensReceive,
    infoReceive,
    isLoadingReceive,
    listOffices,
    register,
    setValue,
    handlePageChange,
    pageCount,
    pageOffset,
    getDataInitial,
  } = useReceiveOrder();

  useEffect(() => {
    getDataInitial();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      titlePanel="Ordenes Recibidas"
      isIcon={false}
      modulo="orden_receive"
    >
      <SearchGlobal
        onSubmit={onSubmit}
        handleSubmit={handleSubmit}
        control={control}
        isBuscar={isBuscar}
        listOffices={listOffices}
        register={register}
        setValue={setValue}
      />
      <div className="row">
        <div className="col-11 mx-auto mt-3 mb-3">
          <h5 className="text-celeste">Estatus de las Órdenes</h5>
          <section className="receive-container">
            <div className="receive-item">
              <p className="receive-title text-dark">Total Recibidas</p>
              <p className="receive-subtitle text-muted">
                {infoReceive?.receive}
              </p>
            </div>
            <div className="receive-item">
              <p className="receive-title text-success">Completas</p>
              <p className="receive-subtitle text-muted">
                {infoReceive?.completed}
              </p>
            </div>
            <div className="receive-item">
              <p className="receive-title text-danger">Incompletas</p>
              <p className="receive-subtitle text-muted">
                {infoReceive?.incomplete}
              </p>
            </div>
            <div className="receive-item">
              <p className="receive-title text-warning">Otra oficina</p>
              <p className="receive-subtitle text-muted">
                {infoReceive?.otherOffice}
              </p>
            </div>
            <div className="receive-item">
              <p className="receive-title text-muted">Por llegar</p>
              <p className="receive-subtitle text-muted">
                {infoReceive?.orderToCome}
              </p>
            </div>
          </section>
        </div>
      </div>
      <TableOrdenRecibidas
        listOrdensReceive={listOrdensReceive}
        isLoadingReceive={isLoadingReceive}
      />
      <ReactPaginate
        previousLabel="Anterior"
        nextLabel="Siguiente"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
        forcePage={pageOffset}
      />
    </Layout>
  );
};

export default ReceiveOrden;
