import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import Formulario from "components/Admin/Communes/Formulario";
import { useParams } from "react-router-dom";

const EditActivity = () => {
  const { officeId, communeId } = useParams();

  return (
    <Layout titlePanel="Administrar" modulo="administrar_countries">
      <div className="pl-3 pr-3">
        <CardRetiro title="Editar comuna">
          <Formulario officeId={officeId} communeId={communeId} />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default EditActivity;
