import React, { Fragment, useState } from "react";
import { Form, Col, Table } from "react-bootstrap";
import TbodyOrden from "./TbodyOrden";
import { useSelector } from "react-redux";

const TablaDataOficina = ({ register, toast }) => {
  const [oficinaOrden, setOficinaOrden] = useState([]);

  const { listOrdenesOffice } = useSelector((state) => state.solutions);

  const { listOffices } = useSelector((state) => state.offices);

  const cargarOficinas = (e, valor) => {
    const id = e !== undefined ? e.target.value : valor;
    if (id !== "") {
      const detOficinas = listOffices.find((s) => s.id === parseInt(id));
      let comunas =
        detOficinas && detOficinas.communes ? detOficinas.communes : [];

      let detComuna = comunas;
      if (comunas.length > 0) {
        detComuna = comunas.filter((c) => c.is_activo !== 0);
      }

      setOficinaOrden(detComuna);
    }
  };

  return (
    <Fragment>
      <Form.Row>
        <Col xs={12}>
          <Form.Group as={Col}>
            <Table bordered size="sm" responsive className="table-global mb-0">
              <thead className="bg-warn-blue">
                <tr>
                  <th>N° de Orden</th>
                  <th>Fecha Orden</th>
                  <th>Sucursal Origen</th>
                  <th>Oficina Origen</th>
                  <th>Sucursal destino</th>
                  <th>Oficina destino</th>
                  <th>Tipo de oficina</th>
                  <th>Editar Sucursal</th>
                  <th>Editar Oficina</th>
                </tr>
              </thead>
              <tbody>
                {listOrdenesOffice?.length > 0 ? (
                  listOrdenesOffice?.map((orden, index) => {
                    return (
                      <TbodyOrden
                        key={index}
                        index={index}
                        {...orden}
                        register={register}
                        toast={toast}
                        cargarOficinas={cargarOficinas}
                        listOffices={listOffices}
                        oficinaOrden={oficinaOrden}
                      />
                    );
                  })
                ) : (
                  <tr className="tr-vacia">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                )}
              </tbody>
            </Table>
          </Form.Group>
        </Col>
      </Form.Row>
    </Fragment>
  );
};

export default TablaDataOficina;
