import { useEffect } from "react";
import Layout from "components/Layouts/Layout";
import CardRetiro from "components/Layouts/CardRetiro";
import DataTableCustomer from "components/Admin/Columnas/DataTableCustomer";
import Filter from "components/Admin/Offices/Filter";
import office from "components/Admin/Columnas/Office";
import { useOffice } from "hooks/Admin/Offices/useOffice";
import { useParams } from "react-router-dom";

const ListOffice = () => {
  const { cityId } = useParams();

  const { state, actions } = useOffice();
  const { officeIsLoading, companyDataBasic } = state;
  const { getAll, filteredItems, handleRowClicked, getAllParentCity } = actions;

  useEffect(() => {
    getAll(cityId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cityId]);

  useEffect(() => {
    if (companyDataBasic !== null) {
      getAllParentCity(cityId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyDataBasic]);

  return (
    <Layout titlePanel="Administrar" modulo="administrar_cities">
      <div className="pl-3 pr-3">
        <CardRetiro title="Listado de sucursales">
          <DataTableCustomer
            columns={office.columnas}
            filteredItems={filteredItems}
            handleRowClicked={handleRowClicked}
            isCargando={officeIsLoading}
            FilterComponent={Filter}
          />
        </CardRetiro>
      </div>
    </Layout>
  );
};

export default ListOffice;
