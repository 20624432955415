const User = {
  columnas: [
    {
      name: "Nombre y Apellido",
      selector: (row, i) => row.full_name,
      sortable: true,
    },
    {
      name: "Comuna",
      selector: (row, i) => row?.commune?.name,
      sortable: true,
    },
    {
      name: "Sucursal",
      selector: (row, i) => row.commune?.office?.name,
      sortable: true,
    },
    {
      name: "Rol",
      selector: (row, i) => row.name_rol,
      sortable: true,
    },
    {
      name: "E-mail",
      selector: (row, i) => row.email,
      sortable: true,
    },
    {
      name: "Estatus",
      selector: (row, i) => (row.active ? "Activado" : "Desactivado"),
      sortable: false,
    },
  ],
};

export default User;
