import API from "config/config-api";
import {
  LISTADO_DATA_COMMUNE,
  SELECTED_ROWS_COMMUNE,
  LOADING_COMMUNE,
  ACTIVE_COMMUNE,
} from "types/admin/communeType";
import endPoints from "routes/";

export const requestSuccess = (type, payload) => {
  return { type, payload };
};

export const getLoading = (value) => async (dispatch, getState) => {
  const payload = {
    data: null,
    loading: value,
  };
  dispatch(requestSuccess(LOADING_COMMUNE, payload));
};

export const getListCommuneAll =
  (officeId, typeFilter) => async (dispatch, getState) => {
    try {
      let response = await API.get(
        endPoints.commune.list(officeId, typeFilter)
      );
      const { status, message, data } = response.data;
      dispatch(requestSuccess(LISTADO_DATA_COMMUNE, data));
      return { status, message, data };
    } catch (error) {
      return { status: false, message: error };
    }
  };

export const getSelectedRows = (rows) => (dispatch, getState) => {
  const payload = {
    data: rows !== null ? rows : null,
  };
  dispatch(requestSuccess(SELECTED_ROWS_COMMUNE, payload));
};

export const addCommune = (body) => async (dispatch, getState) => {
  try {
    let response = await API.post(endPoints.commune.add, body);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const editCommune = (body, communeId) => async (dispatch, getState) => {
  try {
    let response = await API.put(endPoints.commune.edit(communeId), body);
    const { status, message } = response.data;

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const changeStatus = (id) => async (dispatch, getState) => {
  try {
    const { listCommune, communeData } = getState().communes;

    const body = {
      type: "status",
      option: communeData?.active ? "desactivado" : "activado",
    };

    let response = await API.put(endPoints.commune.edit(id), body);
    const { status, message } = response.data;
    if (status) {
      const listAll = [...listCommune].map((communeItem) => {
        if (communeItem.id === id) {
          return { ...communeItem, active: !communeData?.active };
        }
        return communeItem;
      });

      const payload = {
        data: {
          ...communeData,
          active: !communeData.active,
        },
        listAll,
      };

      dispatch(requestSuccess(ACTIVE_COMMUNE, payload));
    }

    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const deleteCommune = (id) => async (dispatch, getState) => {
  try {
    const { listCommune } = getState().communes;

    let response = await API.delete(endPoints.commune.delete(id));
    const { status, message } = response.data;
    if (status) {
      const payload = {
        listAll: listCommune.filter((commune) => commune.id !== id),
        data: null,
      };

      dispatch(requestSuccess(ACTIVE_COMMUNE, payload));
    }
    return { status, message };
  } catch (error) {
    return { status: false, message: error };
  }
};

export const searchParentOfficeById =
  (communeId) => async (dispatch, getState) => {
    try {
      let response = await API.get(endPoints.commune.getOne(communeId));

      const { status, message, data } = response.data;

      return { status, message, data };
    } catch (error) {
      return { status: false, message: error };
    }
  };
