import {
  LISTADO_DATA_COMMUNE,
  SELECTED_ROWS_COMMUNE,
  LOADING_COMMUNE,
  ACTIVE_COMMUNE,
} from "types/admin/communeType";

const INITIAL_STATE = {
  listCommune: [],
  communeIsLoading: true,
  communeData: null,
};

const communeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_COMMUNE:
      return {
        ...state,
        communeIsLoading: action.payload.loading,
        communeData: action.payload.data,
      };
    case LISTADO_DATA_COMMUNE:
      return {
        ...state,
        listCommune: action.payload,
        communeIsLoading: false,
      };
    case SELECTED_ROWS_COMMUNE:
      return {
        ...state,
        communeData: action.payload.data,
      };
    case ACTIVE_COMMUNE:
      return {
        ...state,
        communeData: action.payload.data,
        listCommune: action.payload.listAll,
      };
    default:
      return state;
  }
};

export default communeReducer;
