import React from "react";
import { formatNumberCurrency } from "utils/validationInput";

const TbodyOrden = ({
  index,
  numero_orden,
  orden_flete_id,
  total,
  nombre_remitente,
  nombre_destinatario,
  estado,
  register,
  fecha_orden,
  rut_remitente,
  rut_destinatario,
}) => {
  return (
    <tr key={index}>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].orden_flete_id`}
          value={orden_flete_id}
          ref={register}
        />
        {numero_orden}
      </td>
      <td>{fecha_orden}</td>
      <td>
        <input
          type="hidden"
          name={`ordenes[${index}].total`}
          value={total}
          ref={register}
        />
        <input
          type="hidden"
          name={`ordenes[${index}].rut_remitente`}
          value={rut_remitente}
          ref={register}
        />
        <input
          type="hidden"
          name={`ordenes[${index}].rut_destinatario`}
          value={rut_destinatario}
          ref={register}
        />
        $ {formatNumberCurrency(total)}
      </td>
      <td>{estado}</td>
      <td>{nombre_remitente}</td>
      <td>{nombre_destinatario}</td>
    </tr>
  );
};

export default TbodyOrden;
