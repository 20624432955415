import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { entregarOrden, assignOrdenCaja } from "actions/transportOrdersAction";
import { useForm } from "react-hook-form";
import { formatFloat } from "config/formatInput";
import { getListParentCities } from "actions/admin/parentCityAction";

export const UseDeliveryOrder = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, getValues, setValue, control } =
    useForm();
  const { totalMonto, disabledBtnPago } = useSelector(
    (state) => state.transportOrders
  );
  const { companyDataBasic } = useSelector((state) => state.companies);
  const { metodo_pago } = useSelector((state) => state.metodoPagos);
  const { listParentCities } = useSelector((state) => state.parentCities);
  const [hidden, setHidden] = useState(false);
  const [infoData, setInfoData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const onSubmit = async (data, event) => {
    event.preventDefault();

    setLoading(true);
    if (data.no_aplica === false) {
      if (
        data.rut_destinatario === "" ||
        data.razon_social_destinatario === ""
      ) {
        toast.error("Debe ingresar los datos del cliente", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return false;
      }
    }

    let listOrdenes;
    if (!data.hasOwnProperty("ordenes")) {
      toast.error("No tiene ordenes por retirar", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return false;
    } else {
      listOrdenes = data.ordenes.filter((orden) => orden.seleccion === "true");
      if (listOrdenes.length === 0) {
        toast.error("No hay orden seleccionadas", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return false;
      }
    }

    const listadoDocumentacion = listOrdenes.filter(
      (orden) => orden.documentacion_id === ""
    );
    const listadoNro = listOrdenes.filter(
      (orden) => orden.documentacion_id === ""
    );

    if (listadoDocumentacion.length > 0 || listadoNro.length > 0) {
      toast.error("Debe de completar el detalle de las ordenes", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      return false;
    }
    let dataPago = typeof data.pagos !== "undefined" ? data.pagos : [];
    const formaPago = listOrdenes.filter(
      (orden) => orden.forma_pago === "Por pagar"
    );

    if (formaPago.length > 0) {
      dataPago = data.pagos.map((pago) => {
        const name_pago = metodo_pago.find(
          (item) => item?.id === Number(pago.metodo_pago_id)
        );

        return {
          ...pago,
          monto: formatFloat(pago.monto),
          nro_operacion: pago.nro_operacion,
          metodo_pago: name_pago?.description ?? "",
        };
      });

      const cantPago = dataPago.filter(
        (pago) =>
          pago.metodo_pago_id.length === 0 ||
          pago.monto.length === 0 ||
          pago.nro_operacion.length === 0
      );
      if (cantPago.length === 2) {
        toast.error("Debe ingresar un método de pago", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        return false;
      } else {
        let totals = dataPago.reduce(
          (acumulado, totalPagar) =>
            acumulado + parseFloat(totalPagar.monto || 0),
          0
        );

        if (totals !== totalMonto) {
          toast.error("Los montos no coinciden", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setLoading(false);
          return false;
        }
      }
    }

    const information = {
      ...data,
      ordenes: listOrdenes,
      pagos: dataPago,
    };

    const porPagar = information.ordenes.filter(
      (orden) =>
        orden.forma_pago === "Por pagar" &&
        parseInt(orden.documentacion_id) === 1
    );

    if (companyDataBasic?.invoice) {
      if (porPagar.length > 0) {
        const datosFactura = {
          ...information,
          rut_remitente: porPagar[0].rut_remitente,
          rut_destinatario: porPagar[0].rut_destinatario,
        };

        setHidden(true);
        setIsVisible(true);
        setInfoData(datosFactura);
      } else {
        const porPagar = information.ordenes.filter(
          (orden) =>
            orden.forma_pago === "Por pagar" &&
            parseInt(orden.documentacion_id) === 2
        );
        const datosFactura = {
          ...information,
          factura: {
            rut_factura: porPagar?.length > 0 ? porPagar[0].rut_remitente : "",
          },
        };
        await entregarEncom(datosFactura);
      }
    } else {
      const porPagar = information.ordenes.filter(
        (orden) =>
          orden.forma_pago === "Por pagar" &&
          parseInt(orden.documentacion_id) === 2
      );
      const datosFactura = {
        ...information,
        factura: {
          rut_factura: porPagar?.length > 0 ? porPagar[0].rut_remitente : "",
        },
      };
      await entregarEncom(datosFactura);
    }
    setLoading(false);
  };

  const entregarEncom = async (information) => {
    setLoading(true);

    const ordenes = information.ordenes.map((item) => item.orden_flete_id);

    const response = await dispatch(
      entregarOrden(information, "entrega", ordenes)
    );

    if (response.status) {
      const body = {
        fecha_entrega: response.fecha_entrega,
        ordens: ordenes,
      };
      await dispatch(assignOrdenCaja(body));
      setLoading(false);
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      document.getElementById("frmEntrega").reset();
      setValue("rut_destinatario", "");
      return true;
    } else {
      setLoading(false);
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }
  };

  const getDatInitial = async () => {
    await dispatch(
      getListParentCities(companyDataBasic?.country?.id, "active")
    );
  };

  const state = {
    hidden,
    infoData,
    loading,
    disabledBtnPago,
    companyDataBasic,
    totalMonto,
    metodo_pago,
    isVisible,
    listParentCities,
  };

  const actions = {
    entregarEncom,
    setHidden,
    setInfoData,
    setLoading,
    getDatInitial,
    addOrdenAction: entregarEncom,
  };

  const formElement = {
    onSubmit,
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    control,
  };

  return {
    actions,
    formElement,
    state,
  };
};
