import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { facturaACredito } from "actions/transportOrdersAction";
import { useForm } from "react-hook-form";
import { getListParentCities } from "actions/admin/parentCityAction";

export const useOrderInvoiceCredit = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, errors, getValues, setValue, control } =
    useForm();
  const { totalMonto, disabledBtnPago } = useSelector(
    (state) => state.transportOrders
  );
  const { companyDataBasic } = useSelector((state) => state.companies);
  const { metodo_pago } = useSelector((state) => state.metodoPagos);
  const { listParentCities } = useSelector((state) => state.parentCities);
  const { listEntregada } = useSelector((state) => state.transportOrders);
  const [hidden, setHidden] = useState(false);
  const [infoData, setInfoData] = useState({});
  const [loading, setLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const onSubmit = (data, event) => {
    event.preventDefault();
    setIsVisible(true);
    if (!data.hasOwnProperty("ordenes")) {
      toast.error("No tiene ordenes por devolver", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }

    const information = {
      ordenes: data.ordenes,
      rut_remitente: data.ordenes[0].rut_remitente,
      rut_destinatario: data.ordenes[0].rut_destinatario,
    };
    setHidden(true);
    setInfoData(information);
  };

  const funcFacturar = async (information) => {
    const data = {
      ordenes: information.ordenes.map((orden) => orden.orden_flete_id),
      factura: {
        rut: information?.factura?.rut_factura,
        razon_social: information?.factura?.razon_social_factura,
        direccion: information?.factura?.direccion_factura,
        telefono: information?.factura?.contacto_factura,
        email: information?.factura?.email_factura,
        sucursal_id: information?.factura?.ciudad_factura,
        comuna_id: information?.factura?.comuna_factura,
        rubro: information?.factura?.giro_factura,
        city: information?.factura?.city_origin_name,
        office: information?.factura?.office_origin_name,
      },
      nro_documento: information?.factura?.numero_documento ?? "",
    };

    const response = await dispatch(facturaACredito(data));
    if (response.status) {
      toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
      document.getElementById("frmFacturaCredito").reset();
      return true;
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }
  };

  const getDatInitial = async () => {
    await dispatch(
      getListParentCities(companyDataBasic?.country?.id, "active")
    );
  };

  const state = {
    hidden,
    infoData,
    loading,
    disabledBtnPago,
    companyDataBasic,
    totalMonto,
    metodo_pago,
    isVisible,
    listParentCities,
    listEntregada,
  };

  const actions = {
    setHidden,
    setInfoData,
    setLoading,
    addOrdenAction: funcFacturar,
    getDatInitial,
  };

  const formElement = {
    onSubmit,
    register,
    handleSubmit,
    errors,
    getValues,
    setValue,
    control,
  };

  return {
    actions,
    formElement,
    state,
  };
};
