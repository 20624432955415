import { useEffect } from "react";
import { Form, Col, Row, InputGroup } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";

import MaskedInput from "react-text-mask";
import RutTextMask from "rut-text-mask";
import {
  validateOnlyRUT,
  validateOnlyLetter,
  onPaste,
  validateOnlyNumber,
} from "utils/validationInput";
import { phone } from "phone";
import { getPhone } from "utils/globals";
import { useTransportOrderOffice } from "hooks/Orders/useTransportOrderOffice";

const RUT_MESON = process.env.REACT_APP_CLIENTE_MESON;

const FormDestinatario = ({
  preordenSelected,
  register,
  control,
  isAdmin,
  setValue,
  searchCliente,
  validarRut,
  toast,
  errors,
  listOffices,
  tipoEnvios,
}) => {
  const dispatch = useDispatch();

  const { actions, state } = useTransportOrderOffice();
  const { loadOffices, setOfficeOrigin } = actions;

  const { officeOrigin } = state;

  const { companyDataBasic } = useSelector((state) => state.companies);

  useEffect(() => {
    if (listOffices?.length > 0 && preordenSelected?.ciudad_destino_id) {
      const resultOrigin = loadOffices(
        undefined,
        "origen",
        preordenSelected?.ciudad_destino_id,
        listOffices
      );

      setOfficeOrigin(resultOrigin);
    }
    setValue("tipo_envio_id", preordenSelected?.tipo_envio_id ?? 1);
    setValue("rut_destinatario", preordenSelected?.rut_destinatario);
    setValue(
      "telefono_destinatario",
      getPhone(preordenSelected?.telefono_avisas, companyDataBasic)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOffices, preordenSelected?.ciudad_destino_id]);

  const onKeyDownRemitente = async (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      await searchClienteRemitente();
    }
  };

  const onBlurHandle = async (event) => {
    if (event.target.value.length !== 0) {
      await searchClienteRemitente();
    }
  };

  const searchClienteRemitente = async () => {
    let search;
    const rut = document.getElementById("rut_destinatario").value;
    const isValid = validarRut(rut);
    if (isValid.status) {
      search = { search_type: "rut_cliente", value: rut };
    } else {
      toast.error(isValid.message, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }

    if (rut === RUT_MESON) {
      toast.error("Ese RUT es inválido", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValue(
        "razon_social_destinatario",
        preordenSelected.razon_social_destinatario
      );
      setValue(
        "telefono_destinatario",
        getPhone(preordenSelected.telefono_avisas, companyDataBasic)
      );
      setValue("email_destinatario", preordenSelected?.email);
      return false;
    }

    const result = await dispatch(searchCliente(search));
    const { status, data } = result;
    if (status) {
      setValue("razon_social_destinatario", data.full_name);
      setValue(
        "telefono_destinatario",
        getPhone(data.phone_number, companyDataBasic)
      );
      setValue("email_destinatario", data?.email);
    } else {
      setValue("razon_social_destinatario", "");
      setValue("telefono_destinatario", "");
      setValue("email_destinatario", "");
    }
  };

  const validatePhone = (value) => {
    if (
      !phone(`${companyDataBasic?.country?.code}${value}`, {
        country: companyDataBasic?.country?.iso,
        validateMobilePrefix: false,
      }).isValid
    ) {
      return "El número ingresado no es válido";
    }
  };

  return (
    <Row>
      <Col lg={6} md={6} sm={12}>
        <h6 className="mb-4">Datos del destinatario</h6>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Form.Group controlId="rut_destinatario">
              <Form.Label>RUT</Form.Label>
              <Controller
                render={(props) => (
                  <MaskedInput
                    mask={RutTextMask}
                    className="form-control text-uppercase"
                    id="rut_destinatario"
                    name="rut_destinatario"
                    value={props.value}
                    maxLength={12}
                    readOnly={!isAdmin}
                    defaultValue={preordenSelected?.rut_destinatario || ""}
                    onChange={(value) => {
                      props.onChange(value);
                    }}
                    //onBlur={(event) => handleBlurRut(event, setError)}
                    onKeyDown={(event) => onKeyDownRemitente(event)}
                    onBlur={(event) => onBlurHandle(event)}
                    onKeyPress={(event) => validateOnlyRUT(event)}
                  />
                )}
                control={control}
                name="rut_destinatario"
                rules={{ required: { value: true, message: "Requerido" } }}
              />
              {/**<Form.Control 
						    	type="text" 
						    	name="rut_destinatario"
						    	readOnly={!isAdmin}
								maxLength={14}
						    	defaultValue={preordenSelected?.rut_destinatario || ""}
						    	ref={register({required: {value: true, message: 'Requerido'}})}
						    	onKeyDown={(event) => onKeyDownRemitente(event)}
						        onBlur={(event) => onBlurHandle(event)}

						    /> */}
              {errors.rut_destinatario && (
                <Form.Text className="text-danger">
                  {errors?.rut_destinatario?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Form.Group controlId="razon_social_destinatario">
              <Form.Label>Nombre Completo</Form.Label>
              <Form.Control
                type="text"
                name="razon_social_destinatario"
                readOnly={!isAdmin}
                maxLength={160}
                defaultValue={preordenSelected?.razon_social_destinatario || ""}
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
                className="text-uppercase"
                onKeyPress={(event) => validateOnlyLetter(event)}
                onPaste={(event) => onPaste(event)}
              />
              {errors.razon_social_destinatario && (
                <Form.Text className="text-danger">
                  {errors?.razon_social_destinatario?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Form.Group controlId="telefono_destinatario">
              <Form.Label>Contacto (Teléfono)</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon1">
                    {companyDataBasic?.country?.code}
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  type="text"
                  name="telefono_destinatario"
                  readOnly={!isAdmin}
                  maxLength={15}
                  defaultValue={preordenSelected?.telefono_avisas || ""}
                  disabled={
                    companyDataBasic?.country?.code === "" ? true : false
                  }
                  ref={register({
                    minLength: { value: 9, message: "Mínimo 9 dígitos" },
                    required: { value: true, message: "Requerido" },
                    //eslint-disable-next-line
                    pattern: {
                      value: /^([0-9]+)$/,
                      message: "Teléfono inválido",
                    },
                    validate: validatePhone,
                  })}
                  autoComplete="off"
                  onKeyPress={(event) => validateOnlyNumber(event)}
                  onPaste={(event) => onPaste(event)}
                />
              </InputGroup>
              {errors.telefono_destinatario && (
                <Form.Text className="text-danger">
                  {errors?.telefono_destinatario?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Form.Group controlId="email_destinatario">
              <Form.Label>E-mail</Form.Label>
              <Form.Control
                type="text"
                name="email_destinatario"
                readOnly={!isAdmin}
                maxLength={255}
                defaultValue={preordenSelected?.email || ""}
                ref={register({
                  //eslint-disable-next-line
                  pattern: {
                    value:
                      /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/, //eslint-disable-line
                    message: "Email inválido",
                  },
                  required: { value: true, message: "Requerido" },
                })}
                className="text-lowercase"
                onPaste={(event) => onPaste(event)}
              />
              {errors.email_destinatario && (
                <Form.Text className="text-danger">
                  {errors?.email_destinatario?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Col>
      <Col lg={6} md={6} sm={12}>
        <h6 className="mb-4">Dirección del destinatario</h6>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Form.Group controlId="sucursal_destino_name">
              <Form.Label>Sucursal retiro</Form.Label>
              {!isAdmin ? (
                <Form.Control
                  type="text"
                  name="sucursal_destino_name"
                  readOnly={!isAdmin}
                  defaultValue={preordenSelected?.ciudad_destino_nombre || ""}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                />
              ) : (
                <Controller
                  control={control}
                  name="sucursal_destino_id"
                  rules={{
                    required: { value: true, message: "Requerido" },
                  }}
                  render={(props) => {
                    return (
                      <select
                        value={props.value}
                        className="form-control"
                        id="sucursal_destino_id"
                        onChange={(event) => {
                          props.onChange(event.target.value);
                          const result = loadOffices(
                            event,
                            "origin",
                            "",
                            listOffices
                          );
                          setOfficeOrigin(result);
                        }}
                      >
                        <option value="">Seleccione</option>
                        {listOffices.length > 0 &&
                          listOffices.map((city) => (
                            <option key={city.id} value={city.id}>
                              {city.name}
                            </option>
                          ))}
                      </select>
                    );
                  }}
                  defaultValue={preordenSelected?.ciudad_destino_id || ""}
                />
              )}
            </Form.Group>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Form.Group controlId="comuna_destino_id">
              <Form.Label>Comuna retiro</Form.Label>
              {!isAdmin ? (
                <Form.Control
                  type="text"
                  name="comuna_destino_name"
                  readOnly={!isAdmin}
                  defaultValue={preordenSelected?.oficina_destino_nombre || ""}
                  ref={register({
                    required: { value: true, message: "Requerido" },
                  })}
                />
              ) : (
                <Controller
                  control={control}
                  name="comuna_destino_id"
                  rules={{ required: { value: true, message: "Requerido" } }}
                  render={(props) => (
                    <select
                      value={props.value}
                      className="form-control"
                      id="comuna_destino_id"
                      name="comuna_destino_id"
                      onChange={(value) => {
                        props.onChange(value);
                        setValue("comuna_destino_id", value.target.value);
                      }}
                    >
                      <option value="">Seleccione</option>
                      {officeOrigin.length > 0 &&
                        officeOrigin.map((comuna) => (
                          <option key={comuna.id} value={comuna.id}>
                            {comuna.name}
                          </option>
                        ))}
                    </select>
                  )}
                  defaultValue={preordenSelected?.oficina_destino_id}
                />
              )}
            </Form.Group>
          </Col>
          <Col lg={12} md={12} sm={12}>
            <Form.Group controlId="direccion_destinatario">
              <Form.Label>Dirección retiro</Form.Label>
              <Form.Control
                type="text"
                name="direccion_destinatario"
                readOnly={!isAdmin}
                maxLength={255}
                defaultValue={preordenSelected?.direccion_destinatario || ""}
                ref={register({
                  required: { value: true, message: "Requerido" },
                })}
                className="text-uppercase"
              />
            </Form.Group>
          </Col>
        </Row>
      </Col>
      <Col xs={3}>
        <Form.Group controlId="tipo_envio_id">
          <Form.Label>Entrega en</Form.Label>
          <Form.Control
            as="select"
            name="tipo_envio_id"
            ref={register({ required: { value: true, message: "Requerido" } })}
            disabled={!isAdmin}
          >
            {tipoEnvios.length > 0 &&
              tipoEnvios.map((entrega) => (
                <option key={entrega.id} value={entrega.id}>
                  {entrega.description}
                </option>
              ))}
          </Form.Control>
          {errors.tipo_envio_id && (
            <Form.Text className="text-danger">
              {errors?.tipo_envio_id?.message}
            </Form.Text>
          )}
        </Form.Group>
      </Col>
      <Col xs={9}>
        <Form.Group controlId="observacion">
          <Form.Label>Observación</Form.Label>
          <Form.Control
            type="text"
            name="observacion"
            defaultValue={preordenSelected?.observaciones || ""}
            readOnly={!isAdmin}
            maxLength={255}
            ref={register}
          />
        </Form.Group>
      </Col>
    </Row>
  );
};

export default FormDestinatario;
