import { useDispatch, useSelector } from "react-redux";
import {
  getListParentOfficeAll,
  changeStatus,
  deleteParentCity,
  getSelectedRows,
  getLoading,
} from "actions/admin/parentOfficeAction";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

export const useParentOffice = () => {
  const dispatch = useDispatch();
  const { listParentOffice, parentOfficeIsLoading, parentOfficeData } =
    useSelector((state) => state.parentOffices);

  const handleActivarDes = () => {
    if (parentOfficeData !== null) {
      const statusName = parentOfficeData.active ? "desactivar" : "activar";
      Swal.fire({
        title: `¿Desea ${statusName} la comuna?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(changeStatus(parentOfficeData.id));

          if (response.status) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const handleDelete = () => {
    if (parentOfficeData !== null) {
      Swal.fire({
        title: "¿Desea eliminar la comuna?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí",
        cancelButtonText: "No",
      }).then(async (result) => {
        if (result.isConfirmed) {
          const response = await dispatch(
            deleteParentCity(parentOfficeData.id)
          );
          if (response.status) {
            toast.success(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            toast.error(response.message, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        }
      });
    } else {
      toast.error("Debe seleccionar una fila", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
  };

  const getAll = async (parentOffice) => {
    dispatch(getLoading(true));
    dispatch(getListParentOfficeAll(parentOffice));
    dispatch(getSelectedRows(null));
  };

  const filteredItems = (filterText) => {
    return listParentOffice?.filter(
      (item) =>
        item.name &&
        item.name
          .toString()
          ?.toLowerCase()
          .includes(filterText.toString()?.toLowerCase())
    );
  };

  const handleRowClicked = (state) => {
    const rows =
      state?.selectedRows?.length > 0 ? state?.selectedRows[0] : null;
    dispatch(getSelectedRows(rows));
  };

  const state = {
    listParentOffice,
    parentOfficeIsLoading,
    parentOfficeData,
  };

  const actions = {
    getAll,
    handleDelete,
    handleActivarDes,
    filteredItems,
    handleRowClicked,
  };

  return {
    state,
    actions,
  };
};
