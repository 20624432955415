import { useState, useCallback } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { addUser, editUser, searchUserById } from "actions/admin/userAction";
import { getListRole } from "actions/admin/roleAction";
import { getListOfficeAll } from "actions/admin/officeAction";
import { validarRut } from "utils/rut";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { useHistory } from "react-router-dom";

export const useFormUser = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    errors,
    reset,
    getValues,
    control,
    setValue,
  } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [offices, setOffices] = useState([]);
  const [typeRol, setTypeRol] = useState("");
  const [dateStart, setDateStart] = useState(new Date());
  //const [file, setFile] = useState("");
  const [typeInput, setTypeInput] = useState(false);
  const [typeInputRep, setTypeInputRep] = useState(false);
  const [isChangePhoto, setIsChangePhoto] = useState(false);
  const { users, roles } = useSelector((state) => {
    return {
      users: state.users,
      roles: state.roles,
    };
  });

  const { userDatos } = users;
  const { listRole } = roles;

  const [file, setFile] = useState([]);
  const [isValidLogo, setIsValidLogo] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [dataUser, setDataUser] = useState(null);
  const [listCities, setListCities] = useState([]);

  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections?.length > 0) {
      fileRejections?.forEach((element) => {
        element?.errors?.forEach((err) => {
          if (err?.code === "file-too-large") {
            toast.error("El tamaño máximo del logo es de 120kb", {
              position: toast.POSITION.TOP_RIGHT,
            });
            return false;
          } else if (err?.code === "file-invalid-type") {
            toast.error(
              "Solo se acepta archivos con formato .png",
              ".jpg",
              ".webp",
              { position: toast.POSITION.TOP_RIGHT }
            );
            return false;
          }
        });
      });
    }
    if (acceptedFiles.length > 0) {
      setIsDisabled(true);
      setFile(acceptedFiles);
    }
    // Do something with the files
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    accept: {
      "image/png": [".png", ".jpg", ".webp"],
    },
    maxFiles: 1,
    disabled: isDisabled,
    maxSize: 120000,
    onDrop,
  });

  const getLoadOffice = (e, valor) => {
    const id = e !== undefined ? e.target.value : valor;

    if (id !== "") {
      const detOficinas = listCities.find((s) => s.id === parseInt(id));
      const offices =
        detOficinas && detOficinas.communes ? detOficinas.communes : [];

      setOffices(offices);
    }
  };

  const changeRol = (event) => {
    //setIsValidLogo(true);

    if (dataUser?.name_rol === "Conductor" && dataUser?.trucks?.length > 0) {
      setTypeRol("Conductor");
      toast.error("El usuario tiene camiones relacionados", {
        position: toast.POSITION.TOP_RIGHT,
      });
      setValue("name_rol", "Conductor");
      return;
    }
    if (dataUser?.name_rol === event.target.value) {
      setIsValidLogo(false);
      if (
        dataUser.license_expiration_date != null &&
        dataUser.license_expiration_date !== ""
      ) {
        //let fecha = Date.parse(dataUser.license_expiration_date);
        setDateStart(new Date(dataUser.license_expiration_date));
        setFile([{ path: dataUser?.documento_path, updated: true }]);
      } else {
        setFile([]);
      }
    } else {
      setIsValidLogo(true);
    }
    setTypeRol(event.target.value);
  };

  const onSubmit = async (data, e) => {
    try {
      setIsLoading(true);

      const sucursalSelected = document.getElementById("office_id");
      const text_sucursal =
        sucursalSelected.options[sucursalSelected.selectedIndex].text;
      const comunaSelected = document.getElementById("commune_id");
      const text_comuna =
        comunaSelected.options[comunaSelected.selectedIndex].text;

      let response;
      let body = {
        ...data,
        comuna: text_comuna,
        sucursal: text_sucursal,
        email: data?.email?.toLowerCase(),
      };

      let fecha = "";

      if (data.name_rol === "Conductor") {
        if (file.length === 0) {
          toast.error("Debe seleccionar la foto del documento", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
          return false;
        }
        if (file[0].size > 2000000) {
          toast.error("El tamaño máximo del documento es 2MB", {
            position: toast.POSITION.TOP_RIGHT,
          });
          setIsLoading(false);
          return false;
        }

        const rut = data.rut;
        const isValid = validarRut(rut);
        if (!isValid.status) {
          toast.error(isValid.message, { position: toast.POSITION.TOP_RIGHT });
          setIsLoading(false);
          return false;
        }

        let fechaD = document.querySelector(".fecha_venc").value?.split("/");
        fecha = fechaD[2] + "-" + fechaD[1] + "-" + fechaD[0];

        body = {
          ...body,
          license_expiration_date: document.querySelector(".fecha_venc").value,
        };
      }

      let formData = new FormData();
      formData.append("license_expiration_date", fecha);
      formData.append(
        "driver_license",
        data.driver_license ? data.driver_license : ""
      );
      formData.append("full_name", `${body.name} ${body.surname}`);
      formData.append("rut", data.rut ? data.rut : "");

      if (
        file.length > 0 &&
        data.name_rol === "Conductor" &&
        !Object.keys(file[0]).includes("updated")
      ) {
        formData.append("documento", file[0]);
        formData.append("change_photo", true);
      } else {
        formData.append("change_photo", false);
      }

      formData.append("office_id", body.office_id);
      formData.append("commune_id", body.commune_id);
      formData.append("name", body.name?.toUpperCase());
      formData.append("surname", body.surname?.toUpperCase());
      formData.append("name_rol", body.name_rol);
      formData.append("email", body.email?.toLowerCase());
      formData.append("password", body.password);

      if (dataUser !== null) {
        response = await dispatch(editUser(formData, dataUser?.id));
      } else {
        response = await dispatch(addUser(formData));
      }

      if (response.status) {
        toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        window.scrollTo(0, 0);
        setIsLoading(false);
        setTimeout(() => {
          history.push(`/admin/users`);
        }, 1000);
      } else {
        setIsLoading(false);
        toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      toast.error("Hubo un error con los datos del usuario", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const isVisiblePanel = () => {
    setIsChangePhoto(!isChangePhoto);
  };

  const handleFile = (event) => {
    if (!event.target.files || event.target.files.length === 0) {
      setFile(undefined);
      return false;
    }
    setFile(URL.createObjectURL(event.target.files[0]));
  };

  const validatePassword = (value) => {
    if (value !== getValues("password")) {
      return "Las contraseña deben ser iguales";
    }
  };

  const onFileAdded = (event) => {
    setIsDisabled(true);
  };

  const onDeleteFile = (fileToRemove) => {
    if (fileToRemove !== null) {
      setFile((prevFiles) =>
        prevFiles.filter((file) => file.name !== fileToRemove.name)
      );
    } else {
      setFile([]);
      setIsValidLogo(true);
    }

    setIsDisabled(false);
  };

  const setDataInicial = async (userId) => {
    let data = userDatos;

    if (userId !== null) {
      if (userDatos === null) {
        const result = await dispatch(searchUserById(userId));
        data = result?.data;
      }
    }
    if (data !== null) {
      reset({
        ...data,
        commune_id: data?.commune_id,
        office_id: data.commune?.office?.id,
        name_rol: data.name_rol,
      });
      setTypeRol(data?.name_rol);

      setIsValidLogo(false);
      if (
        data.license_expiration_date != null &&
        data.license_expiration_date !== ""
      ) {
        //let fecha = Date.parse(data.license_expiration_date);
        setDateStart(new Date(data.license_expiration_date));
        setFile([{ path: data?.url_photo, updated: true }]);
      } else {
        setFile([]);
      }
    }
    setDataUser(userId !== null ? data ?? null : null);

    if (listRole.length === 0) {
      await dispatch(getListRole("active"));
    }
    const cities = await dispatch(getListOfficeAll("active"));

    setListCities((listCities) => cities?.data ?? []);
    if (data !== null) {
      const detOficinas = cities?.data.find(
        (s) => s.id === parseInt(data?.commune?.office?.id)
      );

      const offices =
        detOficinas && detOficinas.communes ? detOficinas.communes : [];
      setOffices(offices);
      setValue("commune_id", data?.commune_id);
      setValue("office_id", data?.commune?.office?.id);
      setValue("name_rol", data?.name_rol);
    }
  };

  const validPicture = {
    getRootProps,
    getInputProps,
    isDragActive,
    isFocused,
    isDragAccept,
    isDragReject,
    onFileAdded,
    onDeleteFile,
    isValidLogo,
    dateStart,
    setDateStart,
  };

  const formElement = {
    register,
    handleSubmit,
    errors,
    getValues,
    control,
    setValue,
  };

  const state = {
    isLoading,
    offices,
    typeRol,
    file,
    typeInput,
    typeInputRep,
    isChangePhoto,
    listRole,
    listCities,
    dataUser,
  };
  const actions = {
    getLoadOffice,
    handleFile,
    isVisiblePanel,
    onSubmit,
    changeRol,
    setTypeInput,
    setTypeInputRep,
    setFile,
    validatePassword,
    setDataInicial,
  };

  return {
    state,
    formElement,
    actions,
    validPicture,
  };
};
